import React, { useState } from 'react';
import { BASEURL } from '../../../../config';
import Sharethis from '../../../Helper/Sharethis';
// const Sharethis = React.lazy(() => import('../../../Helper/Sharethis'));

function InviteFriendsPage() {
    const [Message, setMessage] = useState('');
    // for share this URL
    const [RefererLink, setRefererLink] = useState('');
    const [showShare, setshowShare] = useState(false);
    function closeshowShare() {
        setshowShare(false);
    }
    // for share this URL

    function createRefererLink() {
        setRefererLink(`${BASEURL}?couponCode=${localStorage.getItem('loggedToken')}`);
    }

    function copyToClipboard(text) {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    setMessage('Copied');
                })
                .catch((error) => {
                    alert(error);
                    setMessage(' Error copying text to clipboard.');
                });
        } else {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setMessage(' Text copied to clipboard');
        }
        setTimeout(() => {
            setMessage('');
        }, 3000);
    }

    return (
        <>
            {showShare && RefererLink && (
                <div>
                    <div className="mfp-bg mfp-ready"></div>
                    <div
                        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                        tabIndex="-1"
                        style={{ overflow: ' hidden auto' }}
                    >
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content" style={{ maxWidth: 'max-content' }}>
                                <div className="mfp-iframe-scaler-old">
                                    <button
                                        onClick={closeshowShare}
                                        title="Close (Esc)"
                                        type="button"
                                        className="mfp-close"
                                    >
                                        ×
                                    </button>
                                </div>
                                <div
                                    className="mfp-iframe p-4 text-center"
                                    style={{ background: '#fff' }}
                                >
                                    <Sharethis ShareThisURL={RefererLink} />
                                    <div className="mt-3" style={{ clear: 'both' }}></div>
                                    <div className="text-center" style={{ clear: 'both' }}>
                                        <p>
                                            Your Referral Link is below:
                                            <br />
                                            <small>
                                                <a
                                                    href={RefererLink}
                                                    target="_BLANK"
                                                    rel="noopener noreferrer"
                                                >
                                                    {RefererLink}
                                                </a>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </div>
            )}
            <div className="ry_instr_shadow p-4">
                <h4>Invites Friends & Earn</h4>
                {RefererLink !== '' ? (
                    <>
                        <br />
                        <span
                            target="_BLANK"
                            rel="noopener noreferrer"
                            className="btn btn-sm btn-success mr-3"
                            onClick={(e) => {
                                e.preventDefault();
                                setshowShare(true);
                            }}
                        >
                            Share this Link
                        </span>
                        <anew
                            href="#"
                            className="btn btn-sm btn-warning"
                            onClick={() => {
                                copyToClipboard(RefererLink);
                            }}
                        >
                            Copy Link
                        </anew>
                        &nbsp;&nbsp;
                        {Message}
                    </>
                ) : (
                    ''
                )}
                <div
                    style={{
                        background: '#f2f5ff',
                        margin: '20px auto',
                        padding: '35px',
                    }}
                >
                    <div className="row">
                        <div className="col-md-8">
                            <h4 style={{ color: '#5c5a5a' }}>Refer to Learn More!</h4>
                            <h6 style={{ color: '#5c5a5a' }}>
                                <br />
                                Every time you refer a friend to use Docsta Leanr, You and Your
                                Friend are rewarded with a 20% for our courses and webinars!
                            </h6>
                        </div>
                        <div className="col-md-4 text-center">
                            <h4 style={{ color: '#5c5a5a' }}>&nbsp;</h4>
                            <button
                                className="btn btn-sm btn-primary"
                                type="button"
                                onClick={() => {
                                    createRefererLink();
                                }}
                            >
                                Refer Now!
                            </button>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </div>
                <h4>So how Exactly it Works</h4>
                <img src={`${BASEURL}images/students/referStudents.jpg`} alt="" />
                <div className="row d-none">
                    <div className="col-md-12">
                        <div className="text-info text-left">
                            <br />
                            <h3 style={{ color: '#72c7f4' }}>
                                1&nbsp;
                                <small style={{ color: '#72c7f4' }}>
                                    Click the refer now they get a unique referral Link.
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="text-info text-left">
                            <br />
                            <h3 style={{ color: '#f580a0' }}>
                                2&nbsp;
                                <small style={{ color: '#f580a0' }}>
                                    Share Link with your friends
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="text-info text-left">
                            <br />
                            <h3 style={{ color: '#21b24b' }}>
                                3&nbsp;
                                <small style={{ color: '#21b24b' }}>
                                    Your friend will get 20% off from their purchase and you will be
                                    credited 20% discount coupon code that can be redeemed in the
                                    next purchase.
                                </small>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InviteFriendsPage;
