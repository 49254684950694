import React, { Suspense } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { BASEURL } from '../../config';

const TestimonialsBox = React.lazy(() => import('../Institute/TestimonialsBox'));
const SubscribeBox = React.lazy(() => import('../Institute/SubscribeBox'));
const HomeSlider = React.lazy(() => import('../Institute/HomeSlider'));
const HighlyPopular = React.lazy(() => import('./TrendingCars'));
const BulkBookingRequest = React.lazy(() => import('./RequestTeamLearning'));
const FAQs = React.lazy(() => import('../Institute/Faq/Faq'));

function DocLearn() {
    return (
        <>
            {/* <Section01 /> */}
            <Suspense fallback={<div></div>}>
                <HomeSlider />
            </Suspense>

            {/* <Section02 /> */}
            <section className="appie-hero-area homeAbout">
                <div className="container">
                    <div className="hero-bg">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="appie-hero-content">
                                    <span>Welcome To!</span>
                                    <h1 className="appie-title">
                                        Book Pay Drive
                                    </h1>
                                    <p style={{ textAlign: 'justify' }}>
                                        Welcome to Book Pay Drive, where your journey begins. At BookPayDrive, we understand that every trip is a unique adventure, and we are here to provide you with the perfect vehicle to make your experience unforgettable Established with a passion for seamless travel, BookPayDrive takes pride in offering a diverse fleet of well-maintained, top-quality vehicles. 
                                        <br /><br />
                                        Whether you're a business traveler in need of a reliable sedan, a family planning a road trip, or an adventurer seeking an off-road experience, we have the right car for every occasion. Our commitment goes beyond providing vehicles; we aim to redefine your travel experience. With convenient locations, transparent pricing, and a team of dedicated professionals, we strive to make your rental process as smooth as the ride itself.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="appie-hero-thumb">
                                    <div className="img-box">
                                        <img src={`${BASEURL}images/home-img1.jpg`} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Section03 /> */}
            <section className="appie-counter-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Features & Benefits</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-car" />
                                </div>
                                <h3>Unlimited Kilometers <span>Unlimited Thrills</span></h3>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-map-marker-alt" />
                                </div>
                                <h3>Unlimited Locations <span>Unlimited Smiles</span></h3>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-road" />
                                </div>
                                <h3>Anywhere Delivery <span>Endless Discoveries</span></h3>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-tachometer-alt-fast" />
                                </div>
                                <h3>Unlimited Freedom <span>Unrestricted Miles</span></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Section04 /> */}
            <section className="appie-traffic-area">
                <div className="">
                    <Suspense fallback={<div></div>}>
                        <HighlyPopular />
                    </Suspense>
                </div>
            </section>

            {/* <Section05 /> */}
            <section className="appie-counter-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Why Ride with Us?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-car" />
                                </div>
                                <h3>Multiple Payment Options</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-map-marker-alt" />
                                </div>
                                <h3>Easy Cancellation</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-road" />
                                </div>
                                <h3>Best Price Guarantee</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Section05 /> */}
            {/* <Suspense fallback={<div></div>}>
                <NewlyAddedCars />
            </Suspense> */}
            {/* <Section06 /> */}

            <Suspense fallback={<div></div>}>
                <BulkBookingRequest />
            </Suspense>


            
            {/* <Section09 /> */}

            {/* <Section10 /> */}
            {/* <section
                className="appie-features-area examOriented"
                style={{ padding: '10px 0 0px' }}
            >
                <div className="container">
                    <Suspense fallback={<div></div>}>
                        <HighRatedCars MainCatID='skill' />
                    </Suspense>
                </div>
            </section> */}
            {/* <Section11 /> */}
            <Suspense fallback={<div></div>}>
                <div className='mt-5 mb-5'>
                    <SubscribeBox />
                </div>
            </Suspense>
            <Suspense fallback={<div></div>}>
                <TestimonialsBox />
            </Suspense>

            <Suspense fallback={<div></div>}>
                <FAQs />
            </Suspense>
        </>
    );
}

export default DocLearn;
