import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { fetchAvailableCarsURL, BASEURL } from '../../config';

const HomeCourseSlider = React.lazy(() => import('./HomeCourseSlider'));

function HomeSliderSkillsCourse() {
    const [IsLoaded, setIsLoaded] = useState(false);
    // const [setShowCatiData] = useState([]);
    const [Sliders, setSliders] = useState([]);

    const FetchCourseData = async () => {
        const TodayDate = new Date().toISOString().split('T')[0]; // Get today's date in yyyy-mm-dd format
        const maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + 1); // Set max date to 90 days from today
        const maxDateString = maxDate.toISOString().split('T')[0];
        try {
            const res = await fetch(fetchAvailableCarsURL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    orderBy: 'auto',
                    branch: 'any',
                    pickupDate: TodayDate,
                    pickupTime: "23:59",
                    dropDate: maxDateString,
                    dropTime: "09:59",
                }),
            });
            const resJson = await res.json();
            setIsLoaded(true);
            if (res.status === 200) {
                if (resJson.data.length > 0) {
                    setSliders([...resJson.data]);
                } else {
                    setSliders([]);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchCourseData();
    }, []);

    return (
        <>
            <section className="appie-hero-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Trending Cars</h3>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: IsLoaded ? '' : 'none' }}>
                        <HomeCourseSlider
                            Sliders={Sliders}
                            key={Sliders.length}
                            ViewAllUrl={`${BASEURL}cars`}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeSliderSkillsCourse;
