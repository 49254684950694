import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { } from 'react-router-dom';
import { UserTransactionURL } from '../../../../config';
import ReactPaginate from 'react-paginate';

function MyPurchasePage() {

    const [myTransaction, setMyTransaction] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const bookingsPerPage = 3;

    const pageCount = Math.ceil(myTransaction && myTransaction.length / bookingsPerPage);

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
    };
    // const displayedBookings = myTransaction && myTransaction.slice(pageNumber * bookingsPerPage, (pageNumber + 1) * bookingsPerPage);



    const fetchUserTransaction = async () => {
        try {
            const token = localStorage.getItem('loggedToken');
            const url = UserTransactionURL;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
            };
            axios.post(url, { 'loggedToken': token }, config).then((response) => {
                if (response) {
                    console.log('transaction', response.data.transaction)
                    setMyTransaction(response.data.transaction);
                }
            });
        } catch (error) {
            console.error('Error fetching user data:', error.message);
        }
    };

    useEffect(() => {
        fetchUserTransaction();
    }, []);
    return (
        <>

            <div className="ry_instr_shadow p-4">
                <h4 className="std_header_on_page">My Transaction</h4>
                <input type='search' className='form-control search_btn' />
                <div className='mt-5 table-responsive'>
                    <table class="table " id="myTable">
                        <thead>
                            <tr>
                                <th scope="col">Paymentid</th>
                                <th scope="col">Orderid</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Method</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date</th>

                            </tr>
                        </thead>
                        <tbody>
                            {myTransaction && myTransaction !== '' ?
                                myTransaction && myTransaction.map((s) => (
                                    <tr>
                                        <td>{s.payment_id}</td>
                                        <td>{s.order_id}</td>
                                        <td>{s.amount}</td>
                                        <td>Online</td>
                                        <td>{s.order_status}</td>
                                        <td>{`${new Date(s.trans_date).getDate().toString()}/${new Date(s.trans_date).getMonth().toString() + 1}/${new Date(s.trans_date).getFullYear().toString()}`}</td>
                                    </tr>
                                )) : (
                                    <tr>
                                        Data not found
                                    </tr>
                                )}
                        </tbody>
                        <tfoot className='mt-5'>

                            {myTransaction && myTransaction !== '' ? (
                                <tr>
                                    <ReactPaginate
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}
                                        breakLabel={'...'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active-page'}
                                        previousClassName={'custom-previous-class'}
                                        nextClassName={'custom-next-class'}
                                        pageClassName={'custom-page-class'}
                                    />
                                </tr>
                            ) : (
                                <tr></tr>
                            )}

                        </tfoot>
                    </table>
                </div>

            </div>
        </>
    );
}

export default MyPurchasePage;
