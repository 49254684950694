import React, { useEffect, useState, useRef } from 'react';
import { Gallery } from "react-grid-gallery";

import Slider from 'react-slick';
import { BASEAPIURL, UPLOADNODEURLCDN, IMGBASEAPIURL, BASEURL } from '../../../config';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './gallery.css';

const recordsPerPage = 18;

function GalleryMain() {
    const sliderRef = useRef();
    const settings = {
        autoplay: false,
        arrows: true,
        dots: false,
        slidesToShow: 13,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                },
            },
        ],
    };
    const [Show, setShow] = useState(false);
    const [Index, setIndex] = useState(0);
    let i = 1;
    const [ShowImage] = useState([
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
        { src: `https://picsum.photos/1600/800?v=${i}`, caption: `Hello ${i++}` },
    ]);
    useEffect(() => {
        const FetchGallery = async () => {
            try {
                const images = [];
                const res = await fetch(`${BASEAPIURL}/admin/gallery/getAll`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        token: 'hello',
                        recordsPerPage,
                        SortStatus: 1,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200 && resJson.count > 0) {
                    Object.values(resJson.data).map((temp) => {
                        const LetArray = {
                            src: UPLOADNODEURLCDN + temp.image,
                            caption: temp.title,
                        };
                        images.push(LetArray);
                        return 0;
                    });
                    console.log(images);
                    // setShowImage(images);
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchGallery();
    }, []);

    // run the function here
    function handleClick(index) {
        setIndex(index);
        setShow(true);
    }
    // run the function here

    return (
        <>
            <section
                className="corporate-learning p-0"
                data-overlay="6"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/contactus/gallery.jpg)`,
                    backgroundPosition: 'center',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="headBlock">
                                <h3 className="mt-3 mb-1">Gallery</h3>
                                <p className="mb-3">Book, Pay and Drive</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* for the big view */}
            {ShowImage && ShowImage.length > 0  ? (
                <div className="imagePopUPBox" style={{ display: Show ? '' : 'none' }}>
                    <h3
                        className="imagePopUPClose"
                        onClick={() =>
                            setShow(false)
                        }
                    >X</h3>
                    <div className="imagePopUPIndex">
                        <b>{ShowImage[Index].caption}</b>
                        <br />
                        {Index + 1}/{ShowImage.length}
                    </div>
                    <img className="imagePopUP" src={ShowImage[Index].src} alt={ShowImage[Index].caption} />
                    <div className="imagePopUPSlider">
                        <Slider ref={sliderRef} {...settings}>
                            {ShowImage.map((cti, i) => (
                                <div
                                    key={cti.src} title={cti.caption}
                                    onClick={() =>
                                        setIndex(i)
                                    }
                                >
                                    <div className="appie-features-content">
                                        <img src={cti.src} alt={cti.caption} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            ) : (
                ''
            )}
            
            {/* for the big view */}
            <section className="galleryBlock p-0">
                <div className="container-fluid bg-dark">
                    {ShowImage && ShowImage.length > 0 ? (
                        <Gallery images={ShowImage}  onClick={handleClick} />
                    ) : (
                        <center>
                            <img style={{}} className="m-5" width="50px" height="50px" src={`${BASEURL}/loader.gif`}  alt="" />
                        </center>
                    )}
                </div>
            </section>
        </>
    );
}

export default GalleryMain;
