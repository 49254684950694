import commonData from './reduxFunction/commonSlice'
import CourseCategory from './reduxFunction/CourseCategorySlice'
import Courses from './reduxFunction/CoursesSlice'
import CarMaster from './reduxFunction/CarMasterSlice'
import CarDetailSlice from './reduxFunction/CarDetailSlice'

import { configureStore } from '@reduxjs/toolkit'

export default configureStore({
    reducer: {
        commonData,
        CourseCategory,
        Courses,
        CarMaster,
        CarDetailSlice,
    },
})
