import React, { Suspense, useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';

const AboutUs = React.lazy(() => import('./AboutUs'));
const OurClientsBox = React.lazy(() => import('../OurClientsBox'));

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        // document.title = 'About Us';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <AboutUs />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <OurClientsBox />
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
