import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import {useNavigate } from 'react-router-dom';
import {
    MakeFavoriteStd,
    CheckFavoriteStd,
} from '../Students/functions';
import './CourseListBlock.css';
import { LoginRedirect, UPLOADNODEURLCDN } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { getCarDetail } from '../../reduxFunction/CarDetailSlice';



const CourseListBlock = ({singi, BASEURL, }) => {
    const PrimaryIndex = 'car_id';
    const dispatch = useDispatch();
    const [isFavoriteStd, setisFavoriteStd] = useState(false);
    const Master = useSelector((state) => state.CarMaster);
    // const navigate = useNavigate();
    // const [CourseURL, setCourseURL] = useState('');
    useEffect(() => {
        const CheckFavorite = async () => {
            const temp = await CheckFavoriteStd(localStorage.getItem('loggedToken'), singi[PrimaryIndex], 'car', localStorage.getItem('usertype'));
            if (temp === 1) {
                setisFavoriteStd(true);
            } else {
                setisFavoriteStd(false);
            }
        };
        CheckFavorite(singi);
    }, [singi]);

    const handleClickCarDetail = (CarData, Master)=>{ 

        const newMaster = new FormData();
        newMaster.append('pickupDate', Master.pickupDate); 
        newMaster.append('pickupTime', Master.pickupTime);
        newMaster.append('dropDate', Master.dropDate);
        newMaster.append('dropTime', Master.dropTime);

        const pickupDateTime = new Date(`${Master.pickupDate}T${Master.pickupTime}`);
        const dropDateTime = new Date(`${Master.dropDate}T${Master.dropTime}`);
        const timeDifference = dropDateTime - pickupDateTime;
        const hours = (timeDifference / (1000 * 60 * 60));
        
        newMaster.append('hours', hours);
        const extendDataObject = {};
        for (const [key, value] of newMaster.entries()) {
            extendDataObject[key] = value;
        }
         dispatch(getCarDetail({ CarData,CarMaster:extendDataObject }));
         localStorage.setItem('RedirectAfterLogin', `${BASEURL}car-details/${CarData.brand_name}/${CarData.model_name}`);
         LoginRedirect();
        }
        
    return (
        <>
            <div className="appie-features-content">
                <div className="imgBox">
                    {/* <Link to={`/car-details/${encodeURIComponent(singi.brand_name)}/${encodeURIComponent(singi.model_name)}`} className="d-block"> */}
                        <img
                            src={singi.img_url === '' ? `${BASEURL}images/logo.png` : `${UPLOADNODEURLCDN}${singi.img_url}`}
                            alt={singi.title}
                            // style={{ maxHeight: '145px', height: 'auto' }}
                            className="courseimgsj"
                            onClick={() => handleClickCarDetail(singi, Master)}
                        />
                    {/* </Link> */}
                </div>
                <div className="contentBox text-center">
                    <div
                        style={{ float: 'right' }}
                        className="icon"
                        onClick={(e) => {
                            e.preventDefault();
                            setisFavoriteStd(!isFavoriteStd);
                            MakeFavoriteStd(localStorage.getItem('loggedToken'), singi[PrimaryIndex], 'car', localStorage.getItem('usertype'));
                        }}
                    >
                        {isFavoriteStd ? (
                            <i className="fas fa-heart fa-1x text-danger" title="Remove From My Favourite" />
                        ) : (
                            <i className="far fa-1x fa-heart" title="Add to Favourite" />
                        )}
                    </div>
                    <small className="tagsBox curpoint">
                        <span title="Level">{Number(singi.car_transmission_type) === 1 ? 'Automatic' : 'Manual'}</span>
                        <span title="Level">{singi.car_fuel_type}</span>
                        <span title="Level">{singi.category_name}</span> 
                    </small>
                    <h6 className='text-center' onClick={() => handleClickCarDetail(singi, Master)}>
                       
                            {singi.brand_name} {singi.model_name}
                        
                    </h6>
                    {singi.rates ? (
                        <p>Extra kms will be charged at {singi.rates[0].access_rate}/km</p>
                    ) : (
                        ''
                    )}
                    <div className="shopBox">
                        {/* <Link to={`/car-details/${encodeURIComponent(singi.brand_name)}/${encodeURIComponent(singi.model_name)}`} className="book-btn">
                            View Details
                        </Link> */}

                        <button onClick={() => handleClickCarDetail(singi, Master)}  className="book-btn">
                            View Detail
                        </button>

                    </div>
                </div>
            </div>
        </>
    );
};
CourseListBlock.propTypes = {
    UPLOADNODEURL: PropTypes.string.isRequired,
    // IMGBASEAPIURL: PropTypes.string.isRequired,
    setshowShare: PropTypes.func.isRequired,
    setShareThisURL: PropTypes.func.isRequired,
    
};
export default CourseListBlock;
