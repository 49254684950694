import React from 'react';
import { Link } from 'react-router-dom';
import { IMGBASEAPIURL } from '../../config';

function CalendarBox({ className }) {
    return (
        <>
            <section className={`calendar-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="calendarBox">
                                <div className="calendarText">
                                    <h3>Want to change your Pickup or Drop Date?</h3>
                                    <Link to="/search-cars" className="main-btn">
                                        Click Here
                                    </Link>
                                </div>
                                <div className="calendarImg">
                                    <img
                                        src={`${IMGBASEAPIURL}institute/calendar/calendar-img1.png`}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CalendarBox;
