import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import AllCourses from './components/Learn/AllCourses';
import SearchFromPage from './components/cars/SearchFromPage';
import CourseDetailNew from './components/Learn/CourseDetail';
import AboutUs from './components/Institute/AboutUs';

import MakePayment from './components/Payment/make';

import Blog from './components/Institute/Blog';
import BlogSingle from './components/Institute/Blog/BlogSingle';
import Gallery from './components/Institute/Galleries';
import ContactUs from './components/Institute/ContactUs';
import MyRide from './components/Institute/MyRide';
import CarDetails from './components/Institute/CarDetails';
import Information from './components/Institute/Information';
import Faq from './components/Institute/Faq';
import ResetPassword from './components/Students/pages/ResetPassword';
import LearnHome from './components/Learn';
import Thankyou from './components/Institute/Thankyou/index';

// Student Panel
import UserRegister from './components/Students/pages/Register';
// import UserLogin from './components/Students/pages/Login';
import StudentDashboard from './components/Students/pages/Dashboard';

// const AboutUs = React.lazy(() => './components/Institute/AboutUs');

// CheckOut

import Ordersummary from './components/Checktout/OrderSummary';
import PrivateRoute from './components/PrivateRoute';

function RoutesHere() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 400);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                {/* <Router basename="/docsta/learn"> */}
                <Router>
                    <ScrollToTop>
                        <Routes>
                            {/* <Navigate from="/courses" to="/cars" />   */}
                            <Route exact path="/" element={<LearnHome />} />
                            <Route exact path="/cars" element={<AllCourses />} />
                            <Route exact path="/thankyou" element={<Thankyou />} />
                            <Route exact path="/search-cars" element={<SearchFromPage />} />
                            <Route exact path="/courses/:MainCatID" element={<AllCourses />} />
                            <Route exact path="/course/:CourseSlug" element={<CourseDetailNew />} />
                            <Route exact path="/about-us" element={<AboutUs />} />
                            <Route exact path="/make-payment" element={<MakePayment/>} />
                            <Route exact path="/blogs" element={<Blog />} />
                            <Route exact path="/blogs/:CatID" element={<Blog />} />
                            <Route exact path="/blog/:BlogID" element={<BlogSingle />} />
                            <Route exact path="/blog/:BlogID/:BlogTitle" element={<BlogSingle />} />
                            <Route exact path="/gallery" element={<Gallery />} />
                            <Route exact path="/contact-us" element={<ContactUs />} />
                            <Route exact path="/car-details/:brandName/:modelName" element={<CarDetails />} />
                            <Route exact path="/car-details/:carID/:brandName/:modelName" element={<CarDetails />} />
                            <Route exact path="/information/:InfoTitle" element={<Information />} />
                            <Route exact path="/faq" element={<Faq />} />
                            <Route exact path="/user/register" element={<UserRegister />} />
                            <Route exact path="/user/register/:regToken" element={<UserRegister />} />
                            <Route exact path="/user/login" element={<StudentDashboard />} />
                            <Route exact path="user/change-password/:token" element={<ResetPassword />} />
                            <Route exact path="/error" element={<Error />} />
                                <Route exact path="/car-details" element={<CarDetails />} />
                            <Route path="*" element={<Error />} />
                            <Route path='/user' element={<PrivateRoute />}>
                                <Route exact path="dashboard" element={<StudentDashboard />} />
                                <Route exact path="page/:PageName" element={<StudentDashboard />} />
                                <Route exact path="bookmyride" element={<MyRide />} />
                                <Route exact path="checkout/cart" element={<Ordersummary />} />
                            </Route>
                        </Routes>
                        {/* <Route path="*" element={<Navigate to="/cars" />} /> */}
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default RoutesHere;
