import React, { useState, useEffect } from 'react';
import { BASEAPIURL, BASEURL } from '../../../../config';

import CourseListBlock from '../../../Helper/CourseListBlock';
import ReactPaginate from 'react-paginate';
function MyFavouritePage() {
    const [isLoded, SetisLoded] = useState(false);
    const [FavCars, SetFavCars] = useState([]);

    const [pageNumber, setPageNumber] = useState(0);
    const PerPage = 3;

    const pageCount = Math.ceil(FavCars && FavCars.length / PerPage);

    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
    };
    // const displayFavCars = FavCars && FavCars.slice(pageNumber * PerPage, (pageNumber + 1) * PerPage);

    const GerFavorites = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/testimonials/FetchFavorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    device: localStorage.getItem('DeviceDetails'),
                    loggedToken: localStorage.getItem('loggedToken'),
                }),
            });
            const resJson = await res.json();
            SetisLoded(true);
            if (resJson.data && resJson.data.length > 0) {
                SetFavCars(resJson.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        GerFavorites('MainCourse');
    }, []);
    return (
        <>
            {/* {ShowType} */}
            <div className="ry_instr_shadow p-4" style={{ display: isLoded ? '' : 'none' }}>
                <h4 className="std_header_on_page">My Favourites</h4>
                <div className="row">
                    {FavCars && FavCars.length > 0 && FavCars.map((s) => (
                        <div key={s.car_id} className="col-md-6">
                            <CourseListBlock singi={s} BASEURL={BASEURL} />
                        </div>
                    ))}

                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active-page'}
                        previousClassName={'custom-previous-class'}
                        nextClassName={'custom-next-class'}
                        pageClassName={'custom-page-class'}
                    />
                </div>
            </div>
        </>
    );
}

export default MyFavouritePage;
