import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASEURL, IMGBASEAPIURL, LoginRedirect, PasswaordResetLinkExpireCheckURL, passwordResetURL } from '../../../../config';
import '../Login/login.css';
import axios from 'axios';

function ResetPassword() {

    const navigate = useNavigate();
    const perams = useParams();
    const token = perams.token
    const [msgClass, setmsgClass] = useState('alert-danger');
    const [message, setMessage] = useState('');
    const [emailExpiryStatus, setEmailExpiryStatus] = useState('');
    const [passwordReset, setPasswordReset] = useState({
        'email': localStorage.getItem('resetpassmail'),
        'newpassword': '',
        'cnewpassword': ''
    });

    useEffect(() => {
        handleCheckLinkExpire();
    }, [])

    const handleRedirect = () => {

        setTimeout(() => {
            localStorage.setItem('RedirectAfterLogin', `${BASEURL}user/login`);
            LoginRedirect();

        }, 300);
    }
    const handleCheckLinkExpire = async () => {

        const config = {
            headers: { 'Content-Type': 'application/json' },
        }

        const formData = new FormData();
        formData.append('token', token);

        await axios.post(PasswaordResetLinkExpireCheckURL, formData, config).then((response) => {

            try {
                setEmailExpiryStatus(response.data.status)
                if (response.data.status === 1) {
                    setmsgClass('alert-primary');
                    setMessage(response.data.message);
                } else {
                    setmsgClass('alert-danger');
                    setMessage(response.data.message);
                }
            } catch (error) {
                console.log(error);
            }
        })

    }

    const handleChangeResetPassword = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPasswordReset(values => ({ ...values, [name]: value }))
    }

    const handleSubmitFoggot = (e) => {
        e.preventDefault();

        const resetPassData = new FormData();
        resetPassData.append('token', token);
        resetPassData.append('newpassword', passwordReset.newpassword);

        if (passwordReset.newpassword !== passwordReset.cnewpassword) {
            setmsgClass('alert-danger');
            setMessage('Not match your new passwaord and confirm password');
        }
        else {

            const config = {
                headers: { 'Content-Type': 'application/json' },
            }

            axios.post(passwordResetURL, resetPassData, config).then((response) => {

                if (response.data.status === 1) {
                    setmsgClass('alert-success');
                    setMessage(response.data.message);
                    setTimeout(() => {
                        navigate('/user/login')
                    }, 1000);
                }
                else {
                    setmsgClass('alert-danger');
                    setMessage(response.data.message);
                }
            });
        }
    }
    return (
        <>
            <>

                <div className="container pt-4 pb-4 ">
                    {/* <h4 className="text-center hide_empty_div text-dark">{MsgBeforeLogin}</h4> */}
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="row">
                                    <div className="col-md-6 pt-4 d-none d-sm-block text-center">
                                        <img
                                            src={`${IMGBASEAPIURL}students/pages/registration.jpg`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ maxWidth: '250px' }}
                                            className="mt-4 mb-4"
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <form className="m-4 p-4 fpform" onSubmit={handleSubmitFoggot}>
                                            <div className={`${emailExpiryStatus === 0 ? 'd-none' : 'd-block'} form-group`}>
                                                <input
                                                    type="password"
                                                    name="newpassword"
                                                    value={passwordReset.newpassword}
                                                    required
                                                    onChange={handleChangeResetPassword}
                                                    placeholder="New Password"
                                                    className="form-control form-control-lg"
                                                />
                                            </div>

                                            <div className={`${emailExpiryStatus === 0 ? 'd-none' : 'd-block'} form-group`}>
                                                <input
                                                    type="password"
                                                    name="cnewpassword"
                                                    value={passwordReset.cnewpassword}
                                                    required
                                                    onChange={handleChangeResetPassword}
                                                    placeholder="Confirm New Password"
                                                    className="form-control form-control-lg"
                                                />
                                            </div>

                                            <div className={`${msgClass} alert message hide_empty_div mb-3`}>
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <div className={`${emailExpiryStatus === 0 ? 'd-none' : 'd-block'} form-group`}>
                                                <button
                                                    type="submit"
                                                    className="btn btn btn-docsta-instructor"
                                                    style={{ width: '100%' }}
                                                >
                                                    Reset Password
                                                </button>
                                            </div>
                                            <div className="text-right mt-2">
                                                <anew
                                                    href="#"
                                                    className="text-dark"
                                                    onClick={() => handleRedirect()}

                                                >
                                                    <b>Back to Login</b>
                                                </anew>
                                            </div>
                                        </form>

                                        <small
                                            className="mt-4 mb-4 d-block text-center"
                                            style={{ color: '#7b7b7b' }}
                                        >
                                            Don't have an Account?&nbsp;
                                            <Link
                                                to="/user/register"
                                                style={{ color: '#7f13ab' }}
                                            >
                                                Sign Up Here
                                            </Link>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
        </>
    );
}

export default ResetPassword;
