import React from 'react';
// import { IMGBASEAPIURL } from '../../../../config';

function ShareApps() {
    return (
        <>
            <div className="ry_instr_shadow p-4">
                <h4 className="std_header_on_page mb-3">Share Our App</h4>
                <section className="appie-dow1nload-area">
                    <div className="">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="appie-download-content">
                                    <h3>It's all about the best Online Learning App</h3>
                                    <p>Redefining Learning Experience</p>
                                    <div className="textBox">
                                        <ul>
                                            <li>
                                                <i className="fal fa-university" />
                                                <span>Handpicked Courses</span>
                                            </li>
                                            <li>
                                                <i className="fal fa-fingerprint" />
                                                <span>Skill Oriented Learning</span>
                                            </li>
                                            <li>
                                                <i className="fal fa-graduation-cap" />
                                                <span>Engineering & Medical Courses</span>
                                            </li>
                                            <li>
                                                <i className="fal fa-shield-check" />
                                                <span>Transparent & Secure</span>
                                            </li>
                                            <li>
                                                <i className="fal fa-tags" />
                                                <span>Free Life Tips</span>
                                            </li>
                                            <li>
                                                <i className="fal fa-chalkboard-teacher" />
                                                <span>Webinar Sessions</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        className="btnBox text-center pb-3"
                                        style={{ justifyContent: 'center' }}
                                    >
                                        <anew href="#">
                                            <i className="fab fa-apple" />
                                            <span>
                                                Get Link of the <span>App Store</span>
                                            </span>
                                        </anew>
                                        <anew href="#">
                                            <i className="fab fa-google-play" />
                                            <span>
                                                Get Link of <span>Google Play</span>
                                            </span>
                                        </anew>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default ShareApps;
