import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { IMGBASEAPIURL, getDefaultCountry, enqueryURL } from '../../../config';
import axios from 'axios';

function ContactUs() {
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [Message, setMessage] = useState(
        `<img src="${IMGBASEAPIURL}loader.gif" style="max-width: 70px; margin: 0px auto; display: block;" />`
    );
    const [ContactForm, setContactForm] = useState({
        contact_name: '',
        contact_email: '',
        contact_no: '',
        message: '',
    });

    const setPhoneNumber = (value) => {
        setContactForm({ ...ContactForm, contact_no: value });
    };

    const HandleForm = (name, value) => {
        setContactForm({ ...ContactForm, [name]: value });
    };

    const SubmitContactForm = async (e) => {
        e.preventDefault();
        setstyleMSG({ display: 'block' });
        try {
            const config = {
                headers: { 'Content-Type': 'application/json' },
            }
            axios.post(enqueryURL,ContactForm ,config).then((response) =>{
                 
                if (response.data.status === 1) {
                    setContactForm({
                        contact_name: '',
                        contact_email: '',
                        contact_no: '',
                        message: '',
                    });
                    setMessage(`<div class="alert alert-success">${response.data.message}</div>`);
                }
                else{
                    setMessage(`<div class="alert alert-danger">${response.data.message}</div>`);
                }
            });
        } catch (err) {
            setMessage(`<div class="alert alert-danger">${err.message}</div>`);
        }
    };

    return (
        <>
            <section
                className="corporate-learning"
                data-overlay="6"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/contactus/contact-us.jpg)`,
                    padding: '30px 10px',
                    backgroundPosition: 'center',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="headBlock">
                                <h3 className="mt-5 mb-0">Contact Us</h3>
                                <p className="mb-5">We're always eager to hear from you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>We're always eager to hear from you!</p>
                                <div className="single-info">
                                    <h5>Address</h5>
                                    <p>
                                        <i className="fal fa-map-marker-alt" />
                                        <b>Book Pay Drive</b>
                                        <br />
                                        Bharat Mata chowk, near rajdeep public school, Raipur, Chattisgarh
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone" />
                                        <b>Mobile:</b>
                                        <a href="tel:918714702941">(+91) 96176 88882</a>
                                        {/* <br />
                                        <b>Landline:</b>
                                        <a href="tel:914713577355">(323) 938-5798</a> */}
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Email</h5>
                                    <p>
                                        <i className="fal fa-envelope" />
                                        <a href="mailto:bookpaydrive01@gmail.com">bookpaydrive01@gmail.com</a>
                                    </p>
                                </div>
                                
                                {/* <div className="ab-social">
                                    <h5>Social Media</h5>
                                    <a
                                        className="you"
                                        href="https://www.youtube.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-youtube" />
                                    </a>
                                    <a
                                        className="fac"
                                        href="https://www.facebook.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                    <a
                                        className="lin"
                                        href="https://www.linkedin.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-linkedin-in" />
                                    </a>
                                    <a
                                        className="inst"
                                        href="https://www.instagram.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Let's Connect</h4>
                                <p>
                                    Fill the form below so we can get to know you and your needs
                                    better.
                                </p>
                                <form className="row"  onSubmit={SubmitContactForm}
                                >
                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="contact_name"
                                            value={ContactForm.contact_name}
                                            required
                                            placeholder="Enter Your Name"
                                            onChange={(e) => {
                                                HandleForm(e.target.name, e.target.value);
                                            }}
                                        />
                                    </div>
                                   
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            required
                                            name="contact_email"
                                            value={ContactForm.contact_email}
                                            placeholder="Enter Email ID"
                                            onChange={(e) => {
                                                HandleForm(e.target.name, e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <PhoneInput
                                            required
                                            international
                                            defaultCountry={getDefaultCountry()}
                                            className="form-control"
                                            name="contact_no"
                                            value={ContactForm.contact_no}
                                            onChange={setPhoneNumber}
                                            style={{ position: 'relative' }}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            value={ContactForm.message}
                                            placeholder="How can we help?"
                                            onChange={(e) => {
                                                HandleForm(e.target.name, e.target.value);
                                            }}
                                            style={{
                                                marginTop: '20px',
                                            }}
                                        >  
                                        </textarea>
                                    </div>
                                    <div className="col-md-12" style={{ clear: 'both' }}>
                                        <div style={styleMSG} dangerouslySetInnerHTML={{__html: Message}}></div>
                                    </div>
                                    <div className="col-md-6 d-none">
                                        <div className="condition-check">
                                            <input
                                                name="isaccept"
                                                id="terms-conditions"
                                                type="checkbox"
                                                style={{
                                                    display: 'inline-block',
                                                    opacity: 0,
                                                }}
                                            />
                                            <label htmlFor="terms-conditions">
                                                I agree to the &nbsp;
                                                <Link
                                                    target="_BLANK"
                                                    to="/information/termandconditions"
                                                >
                                                    <b>Terms & Conditions</b>
                                                </Link>
                                            </label>
                                        </div>
                                    </div>
                                    <div  className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Send Message" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
                {/* <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d29753.90819060796!2d81.6537534!3d21.2223881!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3a28dce612d51a43%3A0xa7fab9adab6001cc!2sSjain%20Ventures%20C-246%2F6%20near%20St.%20Gyaneshawar%20School%2C%20Vallabh%20Nagar%20Raipur%2C%20Chhattisgarh%20492001!3m2!1d21.2223881!2d81.6537534!5e0!3m2!1sen!2sin!4v1704376053166!5m2!1sen!2sin"
                
                ></iframe> */}


<iframe 
title="map"
 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29736.287857644427!2d81.58237734069478!3d21.30959449618851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28e7178d079afd%3A0x91fac7a6e35caa5c!2sBirgoan%2C%20Raipur%2C%20Chhattisgarh%20492003!5e0!3m2!1sen!2sin!4v1709288169685!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                
            </div>
        </>
    );
}

export default ContactUs;
