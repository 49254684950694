import { BASEAPIURL, BASEURL } from '../../config';
import Cookies from 'js-cookie';

export async function MakeFavoriteStd(studentToken, targetID, targetType, usertype) {
    let returnValue = 0;
    // alert(usertype);
    if (usertype === 'student') {
        try {
            const res = await fetch(`${BASEAPIURL}/testimonials/MakeFavorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    studentToken,
                    targetID,
                    targetType,
                    device: localStorage.getItem('DeviceDetails'),
                    loggedToken: localStorage.getItem('loggedToken'),
                }),
            });
            const resJson = await res.json();
            console.log(resJson);
            returnValue = 1;
        } catch (err) {
            returnValue = 0;
            console.log(err);
        }
    } else {
        localStorage.setItem(
            'MsgBeforeLogin',
            'Please Login to Add on your Favourite List.'
        );
        localStorage.setItem('RedirectAfterLogin', window.location.href);
        window.location.href = `${BASEURL}user/login`;
    }
    return returnValue;
}

export async function CheckFavoriteStd(studentToken, targetID, targetType, userType) {
    let returnValue = 0;
    try {
        const res = await fetch(`${BASEAPIURL}/testimonials/CheckFavorite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
            },
            body: JSON.stringify({
                studentToken,
                targetID,
                targetType,
                userType,
                loggedToken: localStorage.getItem('loggedToken'),
                device: localStorage.getItem('DeviceDetails')
            }),
        });
        const resJson = await res.json();
        returnValue = resJson.status ? resJson.status : 0;
    } catch (err) {
        returnValue = 0;
    }
    return returnValue;
}

export function FetchCoursetoBuy() {
    let BuyCourseData = [];
    const serializedArray = Cookies.get('BuyCourseData');
    if (serializedArray && serializedArray !== '') {
        BuyCourseData = JSON.parse(serializedArray);
    }
    // console.log('BuyCourseData');
    // console.log(BuyCourseData);
    // console.log('BuyCourseData');
    // console.log(BuyCourseData.length);
    return BuyCourseData;
}

function getUniqueElementsByProperties(arr, prop1, prop2) {
    const uniqueElements = [];
    const seen = new Set();

    for (const item of arr) {
        const combination = item[prop1] + item[prop2];

        if (!seen.has(combination)) {
        seen.add(combination);
        uniqueElements.push(item);
        }
    }

    return uniqueElements;
}
  
export async function RemoveCourseFromCart() {
    Cookies.set('BuyCourseData', JSON.stringify([]), { expires: 3 });
}

export async function RemoveCoursetoBuy(targetID, targetType) {
    let BuyCourseData = [];
    const serializedArray = Cookies.get('BuyCourseData');
    if (serializedArray && serializedArray !== '') {
        BuyCourseData = JSON.parse(serializedArray);
    }
    BuyCourseData = BuyCourseData.filter(
        (item) => !(item.targetID === targetID && item.targetType === targetType)
    );
    BuyCourseData = getUniqueElementsByProperties(BuyCourseData, 'targetID', 'targetType');
    Cookies.set('BuyCourseData', JSON.stringify(BuyCourseData), { expires: 3 });
}

export function AddCoursetoPayment() {
    let PaymentCourseData = [];
    const serializedArray = Cookies.get('PaymentCourseData');
    if (serializedArray && serializedArray !== '') {
        PaymentCourseData = JSON.parse(serializedArray);
    }
    PaymentCourseData = FetchCoursetoBuy();
    PaymentCourseData = getUniqueElementsByProperties(PaymentCourseData, 'targetID', 'targetType');
    Cookies.set('PaymentCourseData', JSON.stringify(PaymentCourseData), { expires: 1 });
}

function AddCoursetoBuy(targetID, targetType) {
    let BuyCourseData = [];
    const serializedArray = Cookies.get('BuyCourseData');
    if (serializedArray && serializedArray !== '') {
        BuyCourseData = JSON.parse(serializedArray);
    }
    BuyCourseData.push({targetID, targetType});
    BuyCourseData = getUniqueElementsByProperties(BuyCourseData, 'targetID', 'targetType');
    Cookies.set('BuyCourseData', JSON.stringify(BuyCourseData), { expires: 3 });
}

export async function AddtoCart(studentToken, targetID, targetType, usertype) {
    AddCoursetoBuy(targetID, targetType);
    localStorage.setItem('targetID', targetID);
    localStorage.setItem('targetType', targetType);
    setTimeout(() => {
        document.getElementById('Showadded2CartBtn').click();
    }, 1000);
    return 1;
}

export async function AddtoCartNoPopup(studentToken, targetID, targetType, usertype) {
    AddCoursetoBuy(targetID, targetType);
}

export async function BuyThisCourseStd(studentToken, targetID, targetType, usertype) {
    AddCoursetoBuy(targetID, targetType);
    let returnValue = 0;
    let theURL = `${BASEURL}checkout/cart`;
    const res = await fetch(`${BASEAPIURL}/student/GetCourseDetail2Buy`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Basic ${localStorage.getItem('loggedToken')}` },
        body: JSON.stringify({ targetID, targetType, country: localStorage.getItem('country_name') }),
    });
    const CheckresJson = await res.json();
    if (CheckresJson.data && Number(CheckresJson.data.costing === 0) && usertype !== 'student') {
        RemoveCoursetoBuy(targetID, targetType);
        localStorage.setItem('Enroll_targetID', targetID);
        localStorage.setItem('Enroll_targetType', targetType);
        if (targetType === 'MainCourse') {
            theURL = `${BASEURL}courseStudent/${CheckresJson.data.slug}`;
        } else if (targetType === 'questionBank') {
            theURL = `${BASEURL}courseQBStudent/${CheckresJson.data.slug}`;
        }
        localStorage.setItem('RedirectAfterLogin', theURL);
    } else {
        localStorage.setItem('RedirectAfterLogin', theURL);
    }

    if (usertype === 'student') {
        try {
            const res = await fetch(`${BASEAPIURL}/student/BuyCourse`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    studentToken,
                    targetID,
                    targetType,
                    usertype,
                    country: localStorage.getItem('country_name'),
                }),
            });
            const resJson = await res.json();
            if (resJson.status === 1) {
                localStorage.setItem('OnCart', resJson.OnCart);
                if (Number(resJson.FinalCost) === 0) {
                    RemoveCoursetoBuy(targetID, targetType);
                    if (targetType === 'MainCourse') {
                        theURL = `${BASEURL}courseStudent/${resJson.slug}`;
                    } else if (targetType === 'questionBank') {
                        theURL = `${BASEURL}courseQBStudent/${resJson.slug}`;
                    }
                    localStorage.setItem('RedirectAfterLogin', theURL);
                    localStorage.setItem('MsgBeforeLogin', 'Please Login to continue.');
                }
                window.location.href = theURL;
            } else {
                window.location.href = `${BASEURL}user/login`;
                alert(resJson.message);
            }
            returnValue = 1;
        } catch (err) {
            returnValue = 0;
            alert('Please Try after Sometime, There is some problem.');
            console.log(err);
        }
    } else {
        localStorage.setItem('MsgBeforeLogin', 'Please Login to continue.');
        window.location.href = `${BASEURL}user/login`;
    }
    return returnValue;
}

// export async function BuyThisCourseStd(studentToken, targetID, targetType, usertype) {
//     AddCoursetoBuy(targetID, targetType);
//     let returnValue = 0;
//     let theURL = `${BASEURL}checkout/cart`;
//     if (usertype === 'student') {
//         try {
//             const res = await fetch(`${BASEAPIURL}/student/BuyCourse`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
//                 },
//                 body: JSON.stringify({
//                     studentToken,
//                     targetID,
//                     targetType,
//                     usertype,
//                     country: localStorage.getItem('country_name'),
//                 }),
//             });
//             const resJson = await res.json();
//             if (resJson.status === 1) {
//                 localStorage.setItem('OnCart', resJson.OnCart);
//                 if (Number(resJson.FinalCost) === 0) {
//                     if (targetType === 'MainCourse') {
//                         theURL = `${BASEURL}courseStudent/${resJson.slug}`;
//                     } else if (targetType === 'questionBank') {
//                         theURL = `${BASEURL}courseQBStudent/${resJson.slug}`;
//                     }
//                     window.location.href = theURL;
//                 } else {
//                     window.location.href = theURL;
//                 }
//             } else {
//                 alert(resJson.message);
//             }
//             returnValue = 1;
//         } catch (err) {
//             returnValue = 0;
//             alert('Please Try after Sometime, There is some problem.');
//             console.log(err);
//         }
//     } else {
//         localStorage.setItem('MsgBeforeLogin', 'Please Login to continue.');
//         localStorage.setItem('RedirectAfterLogin', window.location.href);
//         window.location.href = `${BASEURL}user/login`;
//     }
//     return returnValue;
// }
