import React, { useEffect }  from 'react';
import Routes from './Routes';

function App() {
   
    useEffect(()=>{
        const timeoutId = setTimeout(() => {
            
            localStorage.removeItem('resetpassmail');
          }, 90000);
          return () => clearTimeout(timeoutId);
      
    },[])
    return <Routes />;
}

export default App;
