import React, {Suspense, useState, useEffect } from 'react';
// import {useNavigate } from 'react-router-dom';
import { accessoriesListURL, amenitiesListURL, BASEURL, LoginRedirect, showRatingStar, ShowValueInFormat,UPLOADNODEURLCDN } from '../../../config';
import HighlyPopular from '../../Learn/HighlyPopularCoursesSkills';
import { useDispatch,useSelector } from 'react-redux';
import { getCarBookingDetail } from '../../../reduxFunction/CarDetailSlice';
import axios from 'axios';
// const HighlyPopular = React.lazy(() => import('../../HighlyPopularCoursesSkills'));
function CarDetails(singi) {

    const carDetail = useSelector((state) => state.CarDetailSlice);
    const CarMaster = useSelector((state) => state.CarMaster);
    const [Accessories, setAccessories] = useState([]);
    const [Amenities, setAmenities] = useState([]);

    const pickupDate = localStorage.getItem('pickupDate')
    const pickupTime = localStorage.getItem('pickupTime')
    const dropDate   = localStorage.getItem('dropDate')
    const dropTime   = localStorage.getItem('dropTime')

    const pickupDateTime = new Date(`${pickupDate}T${pickupTime}`);
    const dropDateTime = new Date(`${dropDate}T${dropTime}`);
    const timeDifference = dropDateTime - pickupDateTime;
    const temp = (timeDifference / (1000 * 60 * 60));
    console.log(temp);
    const dispatch = useDispatch();
    // const navut = useNavigate()
    const handleClick = (CarData, s) => {

    
        dispatch(getCarBookingDetail({ CarData, s, CarMaster, temp }))
        localStorage.setItem('RedirectAfterLogin', `${BASEURL}user/bookmyride`);
        LoginRedirect();
    }
    useEffect(()=>{
        accessoriesList();  
        amenitiesList();  
    },[])
    
    const accessoriesList = async()=>{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
            },
        };
        axios.get(accessoriesListURL, config).then((response)=>{

               if(response.data.status === 1){
                    //  console.log(response.data.data);
                    setAccessories(response.data.data);
               } else{
                    console.log('no data found');
               }
        });
    }
    const amenitiesList = async()=>{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
            },
        };
        axios.get(amenitiesListURL, config).then((response)=>{

               if(response.data.status === 1){
                    //  console.log(response.data.data);
                    setAmenities(response.data.data);
               } else{
                    console.log('no data found');
               }
        });
    }


   

    const [CourseURL, setCourseURL] = useState('');
    useEffect(() => {
        setCourseURL(`/course/${singi.slug}`);
        setCourseURL('/user/login'); 
    }, [singi]);

    return (
        <>
            <section
                className="corporate-learning"
                data-overlay="6"
                style={{
                    background: `url(${UPLOADNODEURLCDN}rentalCars.jpg)`,
                    padding: '30px 10px',
                    backgroundPosition: 'center',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="headBlock">
                                <h4 className="mt-5 mb-0">Car Details For Self Drive</h4>
                                <p className="mb-5">Book, Pay and Drive</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-center">
                                <img 
                                
                                src={carDetail.img_url === '' ? `${BASEURL}images/logo.png` : `${UPLOADNODEURLCDN}${carDetail.img_url}`}
                                alt=""
                                
                                
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact--info-area">
                                <div className='mb-3'>
                                    <h4 className="mb-0 ">
                                    {carDetail.brand_name} {carDetail.model_name} ( {carDetail.car_luggage_space} Seater) 
                                        <div className="pull-right">
                                            {showRatingStar(5)}
                                        </div>
                                        <div className='clearBoth'></div>
                                    </h4>
                                    <small className="d-block text-dark mb-1">
                                        <i className='far fa-file'></i>
                                        &nbsp;&nbsp;
                                        {Number(carDetail.car_transmission_type) === 1 ? 'Automatic' : 'Manual'} <span className="dot"></span> {carDetail.car_fuel_type} <span className="dot"></span> {carDetail.category_name}
                                    </small>
                                </div>
                                <p>
                                    { carDetail.short_description }
                                </p>
                            </div>
                            <div to={CourseURL}>

                                <div className="mt-2 mb-1 ml-0 mr-0 spec-1 row text-dark">
                                {carDetail.rates && JSON.parse(carDetail.rates).map((s) => (
                                    <div  className="col-md-4  rentalDiv" onClick={() => handleClick(carDetail, s)}>
                                        <div className="">
                                            <h6 className='text-success mb-2'>₹ {ShowValueInFormat((Number(carDetail.hours) * Number(s.rate_per_hour)).toFixed(0))}</h6>
                                            <small className='text-dark'>{ShowValueInFormat((Number(carDetail.hours) * Number(s.distance)).toFixed(0))}KM Driving Limit</small>
                                            <br />
                                            <small className='text-danger'>((Extra KM charge ₹{s.access_rate}))</small>
                                        </div>
                                    </div>
                                ))}  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='contact-section appie-counter-area'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">ACCESSORIES</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                    {Accessories && Accessories.map((s) => (
                        
                        <div className='col-lg-3'>
                            <div className='border p-2 m-2 rounded accesories'>
                                <span className='fs-6'><i className="fa fa-check-circle text-white mx-1 bg-success rounded-circle" /></span>{s.accessory_name}
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </section>

            <section className='contact-section '>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">AMENITIES</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                    {Amenities && Amenities.map((s) => (
                        
                        <div className='col-lg-3'>
                            <div className='border p-2 m-2 rounded accesories'>
                                <span className='fs-6'><i className="fa fa-check-circle text-white mx-1 bg-success rounded-circle" /></span>{s.amenity_name}
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </section>

            <section className="contact-section appie-counter-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Why Ride with Us?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-lg-4">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-car" />
                                </div>
                                <h3>Multiple Payment Options</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-map-marker-alt" />
                                </div>
                                <h3>Easy Cancellation</h3>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="appie-single-counter">
                                <div className="icon">
                                    <i className="fal fa-2x fa-road" />
                                </div>
                                <h3>Best Price Guarantee</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="appie-traffic-area ">
                <div className="">
                    <Suspense fallback={<div></div>}>
                        <HighlyPopular />
                    </Suspense>
                </div>
            </section>


        </>
    );
}

export default CarDetails;
