import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ShowValueInFormat, BASEURL, UPLOADNODEURLCDN, CustomerBookingRideURL, fetchPickupDropURL } from '../../../config'; // createOrderURL
import { useSelector, useDispatch } from 'react-redux';
import { getBookingMesaage } from '../../../reduxFunction/CarDetailSlice';
import useRazorpay from "react-razorpay";

function MyRide() {
  const [APIcall, setAPIcall] = useState(false);
  let logToken = localStorage.getItem('loggedToken');
  setInterval(() => {
    logToken = localStorage.getItem('loggedToken');
  }, 1000);
  const dispatch = useDispatch();
  const bookingDetail = useSelector((state) => state.CarDetailSlice);

  const navigate = useNavigate();
  const [msgClass, setMsgClass] = useState('alert-danger');
  const [message, setMessage] = useState('');

  // const [Razorpay] = useRazorpay();
  const [bpickupanddrop, setbpickupanddrop] = useState([]);
  const [FormsData, setFormsData] = useState({
    booking_type: "Manual",
    pickup_date: "",
    pickup_time: "",
    drop_date: "",
    drop_time: "",
    rent_amount: "",
    car_id: "",
    packagesid: "",
    rate_per_hour: "",
    access_rate: "",
    security_amount: bookingDetail.security_deposit,
    pickupDrop: 0,
    loggedToken: localStorage.getItem('loggedToken'),
  });

  useEffect(() => {
    if (bpickupanddrop && bpickupanddrop.length !== 0) {
      setFormsData(values => ({ ...values, pickupDrop: bpickupanddrop[0].rate }));
    }
  }, [bpickupanddrop]);

  const calculateSum = () => {

    if (bookingDetail.booking_id !== '') {
      const total = (Number(bookingDetail.hours) * Number(bookingDetail.rate_per_hour)).toFixed(0);
      return Number(total);
    }
    else {
      const total = (Number(bookingDetail.hours) * Number(bookingDetail.rate_per_hour)).toFixed(0);
      return Number(total) + Number(FormsData.security_amount) + Number(FormsData.pickupDrop);
    }
  };

  const handleFormData = (name, value) => {
    setFormsData({ ...FormsData, [name]: value });
  };

  useEffect(() => {
    const fetchPickupDrop = async () => {
      try {
        setMsgClass('alert-danger');
        const url = fetchPickupDropURL;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
          },
        };
        axios.post(url, { branch_id: bookingDetail.branch_id }, config).then((response) => {
          if (response) {

            setbpickupanddrop(response.data.pickupanddrop)
          }
          setMessage(response.data.message);

          setTimeout(() => {
            setMessage('');
          }, 2500);
        });
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };
    fetchPickupDrop();
  }, []);

  const handlePayment = async (e) => {
    e.preventDefault();

    if (logToken && logToken !== 0 && logToken !== '0') {
      const formData = new FormData();
      formData.append('pickup_date', bookingDetail.pickupDate);
      formData.append('pickup_time', bookingDetail.pickupTime);
      formData.append('drop_date', bookingDetail.dropDate);
      formData.append('drop_time', bookingDetail.dropTime);
      formData.append('car_id', bookingDetail.car_id);
      formData.append('booking_id', bookingDetail.booking_id);
      formData.append('packagesid', bookingDetail.packagesid);
      formData.append('rent_amount', Number(bookingDetail.hours) * Number(bookingDetail.rate_per_hour));
      formData.append('rate_per_hour', Number(bookingDetail.rate_per_hour));
      formData.append('access_rate', bookingDetail.access_rate);
      formData.append('security_amount', FormsData.security_amount);
      formData.append('pickupDrop', FormsData.pickupDrop);
      formData.append('loggedToken', FormsData.loggedToken);
      formData.append('booking_type', FormsData.booking_type);
      formData.append('branch_id', bookingDetail.branch_id);
      formData.append('total', calculateSum());

      if (bookingDetail.car_id === '' && bookingDetail.packagesid === '') {
        setMsgClass('alert-danger')
        setMessage('Oops! Please select a Car and Packages to Proceed.')
      } else if (FormsData.pickupDate === '') {
        setMsgClass('alert-danger')
        setMessage('Oops! Please Select Pickup Date.')
      } else if (FormsData.dropDate === '') {
        setMsgClass('alert-danger')
        setMessage('Oops! Please Select Return Date.')
      } else if (FormsData.pickupTime === '') {
        setMsgClass('alert-danger')
        setMessage('Oops! Please Select Pickup Time.')
      } else if (FormsData.dropTime === '') {
        setMsgClass('alert-danger')
        setMessage('Oops! Please Select Return Time.')
      }
      else {
        const currentTimestamp = Date.now();
        formData.append('razorpay_payment_id', `website_${currentTimestamp}`);
        formData.append('razorpay_order_id', `website_${currentTimestamp}`);
        formData.append('razorpay_signature', "website");
        formData.append('tranaction_status', 'success');

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
          },
        };
        setAPIcall(true);
        await axios.post(CustomerBookingRideURL, formData, config).then((responseBooking) => {

          if (responseBooking.data.status === 1) {
            setMsgClass('alert-success');
            setMessage(responseBooking.data.message);
            if (responseBooking.data.booking_id) {
              localStorage.setItem('new_booking_id', responseBooking.data.booking_id);
            }
            dispatch(getBookingMesaage({ 'status': 'success', 'message': responseBooking.data.message, 'doc_msg': 'Once your documents are uploaded and verified, you will receive a confirmation email, and your reservation will be complete. Please make sure to complete this process before the scheduled pickup time.' }));
            setTimeout(() => {
              navigate('/thankyou')
            }, 2000);

          } else {
            setMsgClass('alert-danger');
            setMessage(responseBooking.data.message);
          }
        })

        
        // axios.post(createOrderURL, formData, config).then((order) => {
        //   if (order.data.status === 1) {
        //     const options = {
        //       key: "rzp_test_zF2WN9CPbM9IaS",
        //       amount: order.data.data.amount,
        //       currency: "INR",
        //       name: "Book Pay Drive",
        //       description: "Test Transaction",
        //       image: "https://example.com/your_logo",
        //       order_id: order.data.data.order_id,
        //       handler: function (response) {

        //         formData.append('razorpay_payment_id', response.razorpay_payment_id);
        //         formData.append('razorpay_order_id', response.razorpay_order_id);
        //         formData.append('razorpay_signature', response.razorpay_signature);
        //         formData.append('tranaction_status', 'success');

        //         console.log('handler', response);
        //         const config = {
        //           headers: {
        //             'Content-Type': 'application/json',
        //             Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
        //           },
        //         };

        //         if (response.razorpay_payment_id !== '') {
        //           axios.post(CustomerBookingRideURL, formData, config).then((responseBooking) => {

        //             if (responseBooking.data.status === 1) {
        //               setMsgClass('alert-success');
        //               setMessage(responseBooking.data.message);
        //               dispatch(getBookingMesaage({ 'status': 'success', 'message': responseBooking.data.message, 'doc_msg': 'Once your documents are uploaded and verified, you will receive a confirmation email, and your reservation will be complete. Please make sure to complete this process before the scheduled pickup time.' }));
        //               setTimeout(() => {
        //                 navigate('/thankyou')
        //               }, 2000);

        //             } else {
        //               setMsgClass('alert-danger');
        //               setMessage(responseBooking.data.message);
        //             }
        //           });
        //         }
        //       },
        //       prefill: {
        //         name: "Piyush Garg",
        //         email: "youremail@example.com",
        //         contact: "9999999999",
        //       },
        //       notes: {
        //         address: "Razorpay Corporate Office",
        //       },
        //       theme: {
        //         color: "#3399cc",
        //       },
        //     };
        //     const rzp1 = new Razorpay(options);
        //     rzp1.on("payment.failed", function () {

        //       setMsgClass('alert-danger');
        //       setMessage('Error! Your Transaction is faild, Please try again.');
        //       dispatch(getBookingMesaage({ 'status': 'failed', 'message': 'Error! Your Transaction is faild, Please try again.' }));

        //     });
        //     rzp1.open();

        //     clearInterval(timeInterval)
        //   } else {
        //     setMsgClass('alert-danger');
        //     setMessage(order.data.message);
        //   }
        // })
      }
    }
    else {
      // alert(logToken);
      navigate('/user/login')
    }
  };

  return (
    <>
      <section
        className="corporate-learning"
        data-overlay="6"
        style={{
          background: `url(${UPLOADNODEURLCDN}rentalCars.jpg)`,
          padding: '30px 10px',
          backgroundPosition: 'center',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="headBlock">
                <h3 className="mt-5 mb-0">Check Out</h3>
                <p className="mb-5">Book, Pay and Drive</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact--info-area">
                <h3>Summary</h3>
                <p>We're always eager to hear from you!</p>

                <img
                  alt=""
                  src={bookingDetail.img_url === '' ? `${BASEURL}images/logo.png` : `${UPLOADNODEURLCDN}${bookingDetail.img_url}`}
                  className="img-responsive my-3"
                />
                <div className="single-info">

                  <p>
                    <i className="fal fa-map-marker-alt" />
                    <b>{bookingDetail.brand_name} {bookingDetail.model_name} ( {bookingDetail.car_luggage_space} Seater)</b>
                    <br />

                  </p>
                </div>
                <div className="single-info">
                  <h5>Phone</h5>
                  <p>
                    <i className="fal fa-mobile fs-6" />
                    <b>Mobile:</b>
                    <a href="tel:919617688882">96176 88882</a>
                  </p>
                  {/* <p>
                    <i className="fal fa-phone fs-6" />
                    <b> Landline:</b>
                    <a href="tel:914713577355">(323) 938-5798</a>
                  </p> */}
                </div>
                <div className="single-info">
                  <h5>Pickup Riding Schedule</h5>
                  <p>
                    <i class="fa fa-calendar fs-6" />
                    <b>DATE: </b>
                    <a href="tel:918714702941">{bookingDetail.pickupDate}</a>
                  </p>
                  <p>
                    <i class="fa fa-clock fs-6" />
                    <b>TIME: </b>
                    <a href="tel:914713577355">{bookingDetail.pickupTime}</a>
                  </p>
                </div>
                <div className="single-info">
                  <h5>Drop Off Riding Schedule</h5>
                  <p>
                    <i className="fal fa-calendar fs-6" />
                    <b>DATE: </b>
                    <a href="tel:918714702941">{bookingDetail.dropDate}</a>

                  </p>
                  <p>
                    <i className="fal fa-clock fs-6" />
                    <b>TIME: </b>
                    <a href="tel:914713577355">{bookingDetail.dropTime}</a>
                  </p>
                </div>
                <div className="single-info">
                  <h5>Email</h5>
                  <p>
                    <i className="fal fa-envelope" />
                    <a href="mailto:bookpaydrive01@gmail.com">bookpaydrive01@gmail.com</a>
                  </p>
                </div>

              </div>
            </div>
            <div className="col-md-8">
              <form onSubmit={handlePayment}>
                <div className="contact-form">
                  <h4>Checkout</h4>
                  <p>
                    Fill the form below so we can get to know you and your needs
                    better.
                  </p>
                  <div className="row">
                    <div className="col-md-6 ">
                      <label className='text-center'>With Fuel / Without Fuel?</label>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-12 rentalDiv" style={{ backgroundColor: '#ffe4b2 ' }}>
                        <div className="" >
                          <h6 className='text-success mb-2'>{bookingDetail.type}</h6>
                          <h6 className='text-success mb-2'>₹ {ShowValueInFormat((Number(bookingDetail.hours) * Number(bookingDetail.rate_per_hour)).toFixed(0))}</h6>
                          <small className='text-dark'>{ShowValueInFormat((Number(bookingDetail.hours) * Number(bookingDetail.distance)).toFixed(0))}KM Driving Limit</small>
                          <br />
                          <small className='text-danger'>(Extra KM charge ₹ {bookingDetail.access_rate})</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className='form-group py-3'>
                        <label className='text-center'>Where will you ride your car ?</label>
                        <div class="form-check">
                          <input class="form-check-input" required
                            type="radio"
                            name="security_amount"
                            id="local"
                            disabled={bookingDetail.booking_id === '' ? false : true}
                            value={bookingDetail.security_deposit}
                            checked={bookingDetail.security_deposit === FormsData.security_amount}
                            onChange={(e) => {
                              handleFormData(e.target.name, e.target.value);
                            }}
                          />
                          <label class="form-check-label" htmlFor="local">
                            Local( Within State )
                          </label>
                        </div>

                        <div class="form-check">
                          <input class="form-check-input"
                            type="radio"
                            id="outstation"
                            name="security_amount"
                            value={bookingDetail.outstation_security_deposit}
                            disabled={bookingDetail.booking_id === '' ? false : true}
                            //  value={20}
                            onChange={(e) => {
                              handleFormData(e.target.name, e.target.value);
                            }}
                          />
                          <label class="form-check-label" htmlFor="outstation">
                            Outstation( Other State )
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className='form-group py-3'>
                        <label className='text-center'>Pick up and Drop ?</label>
                        {bpickupanddrop && bpickupanddrop.map((s) => (
                          <div class="form-check">
                            <input class="form-check-input"
                              type="radio"
                              name="pickupDrop"
                              id={s.rate}
                              disabled={bookingDetail.booking_id === '' ? false : true}
                              value={s.rate}
                              checked={s.rate === FormsData.pickupDrop}
                              onChange={(e) => {
                                handleFormData(e.target.name, e.target.value);
                              }}
                            />
                            <label class="form-check-label" for={s.rate}>
                              {s.name}   ( {s.rate} )
                            </label>
                          </div>
                        ))}

                      </div>
                    </div>
                    {/* <div className="col-md-12">
                    <div className='form-group py-3'>
                      <label className='text-center'>Do you want to add some add-on amenity ?</label>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" checked />
                        <label class="form-check-label" for="flexRadioDefault6">
                          GOODIES(WATER BOTTLE,JUICES,SNACKS) (Rs.200)

                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                        <label class="form-check-label" for="flexRadioDefault7">
                          JBL SPEAKER WORTH 31999 (for raipur only) (Rs.400)
                        </label>
                      </div>
                    </div>
                  </div> */}
                    <div className="col-md-12 d-none">
                      <div className='form-group bg-dark p-5 text-center text-white'>
                        <div>
                          <h5 className='text-white'>Ongoing Offers</h5>
                          <small>There is no ongoing offer now</small>
                        </div>
                        <div className='py-4'>
                          <h6 className='text-white'>If you have the Coupon Code, Please Apply it below.</h6>
                          <div className=''>
                            <div className='col-md-8 row mx-auto'>
                              <div className="col-md-9">
                                <input type='text' className='form-control my-3 text-white' />
                              </div>
                              <div className="col-md-3">
                                <button type="button" className="btn btn-success mt-4">Apply</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-12'>
                      <table class="table userbooking_btmtbl">
                        <thead>

                        </thead>
                        <tbody>
                          <tr>
                            <td>Base fare ( Estimated Duration :12 Hour Fare Per Unit : Rs.130.81 /Hour )</td>
                            <td><i class="fas fa-rupee-sign"></i>{ShowValueInFormat((Number(bookingDetail.hours) * Number(bookingDetail.rate_per_hour)).toFixed(0))}</td>
                          </tr>


                          <tr>
                            <td>Security Deposit</td>
                            <td><i class="fas fa-rupee-sign"></i> {bookingDetail.booking_id === '' ? FormsData.security_amount : 0} </td>
                          </tr>
                          <tr>
                            <td>Pick up and Drop </td>
                            <td><i class="fas fa-rupee-sign"></i> {bookingDetail.booking_id === '' ? FormsData.pickupDrop : 0} </td>
                          </tr>
                          <tr>
                            <td>Discount</td>
                            <td><i class="fas fa-rupee-sign"></i> 0 </td>
                          </tr>
                          <tr>
                            <td>Total Payable Amount</td>
                            <td><i class="fas fa-rupee-sign"></i>{ShowValueInFormat(calculateSum())}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-12">
                      <div className="">
                        <input type="checkbox" id="tc" required />
                        <label for="tc">  <Link to="/information/termandconditions" target='_blank' style={{ marginLeft: '10PX' }}>  I Accept the Terms & Conditions.</Link></label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className={`message alert hide_empty_div mb-3 ${msgClass}`}>
                        {message ? <span>{message}</span> : ''}
                      </div>
                    </div>
                    <div className="col-md-12 text-center ">
                      {!APIcall && <input type="submit" name="submit" value="Proceed to Payment" className='mt-3' />}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MyRide;
