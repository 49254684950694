import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import logo from '../../assets/images/logo-learn.png';
import {
    // IMGBASEAPIURL,
    // CleanUrlWork,
    UPLOADNODEURL,
    // BASEAPIURL,
    LogOutAll,
    // GoogleAnylyticsID,
    // BASEURL,
    // LogOutAll,
    // CoursesMainCategory,
} from '../../config';

function Drawer({ drawer, action, type }) {
    const [MoreMenu, SetMoreMenu] = useState(false);
    const [UserType, setUserType] = useState(localStorage.getItem('usertype'));
    const [defaultProfilePicture] = useState('defaultProfilePicture.png');
    const [userData, Setuserdata] = useState({ name: '', photo: '' });

    useEffect(() => {
        setUserType(localStorage.getItem('usertype'));
        const temp = localStorage.getItem('userdata');
        // alert(temp);
        try {
            if (temp) {
                const resJson = JSON.parse(temp);
                Setuserdata(resJson);
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
        const interval = setInterval(() => {
            setUserType(localStorage.getItem('usertype'));
            // setUserName(localStorage.getItem('username'));
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <anew href="#" onClick={(e) => action(e)} id="canvas_close_btn">
                                        <i className="far fa-times"></i>
                                    </anew>
                                </div>
                                <div className="offcanvas-brand text-left mb-40" style={{ width: '100%' }}>
                                    {/* <img src={logo} alt="" /> */}
                                    {type && type === "instructor" ? (
                                        <>
                                            <h6>
                                                <Link
                                                    style={{
                                                        display: UserType === 'instructor' ? 'none' : 'block',
                                                    }}
                                                    to="/instructor-login"
                                                    className="docsta_color mb-3"
                                                >
                                                    Login as Instructor
                                                </Link>
                                                {/* {UserType} */}
                                                <Link
                                                    style={{
                                                        display: UserType === 'instructor' ? 'none' : 'block',
                                                    }}
                                                    to="/instructor-register"
                                                    className="docsta_color"
                                                >
                                                    Signup as Instructor
                                                </Link>
                                            </h6>
                                        </>
                                    ) : (
                                        <>
                                            <h6>
                                                <Link
                                                    style={{
                                                        display: UserType === 'student' ? 'none' : 'block',
                                                    }}
                                                    to="/user/login"
                                                    className="docsta_color mb-3"
                                                >
                                                    Login
                                                </Link>
                                                {/* {UserType} */}
                                                <Link
                                                    style={{
                                                        display: UserType === 'student' ? 'none' : 'block',
                                                    }}
                                                    to="/user/register"
                                                    className="docsta_color"
                                                >
                                                    Signup
                                                </Link>
                                            </h6>
                                        </>
                                    )}
                                    {/* {UserType === 'student'} */}
                                    <div
                                        className="ProfileMenuCont"
                                        style={{
                                            display: (type !== "instructor") && UserType && UserType === 'student' ? 'block' : 'none',
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <img
                                                src={`${UPLOADNODEURL}${userData.photo &&
                                                    userData.photo !== null &&
                                                    userData.photo !== ''
                                                    ? userData.photo
                                                    : defaultProfilePicture
                                                    }`}
                                                alt={userData.name}
                                                style={{
                                                    margin: '0px 7px',
                                                    width: '40px',
                                                    height: '40px',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    // maxWidth: '170px',
                                                    wordBreak: 'break-all',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                <b>{userData.name}</b>
                                                <br />
                                                {userData.email}
                                            </div>
                                        </div>
                                        <div className="ProfileMenu">
                                            <Link to="/user/page/profilesetting">
                                                <h6>{userData.name}</h6>
                                                {userData.email}
                                            </Link>
                                            <hr />
                                            <Link to="/user/dashboard">My Booking</Link>
                                            <Link to="/user/page/MyFavourite">My Transaction</Link>
                                            <Link to="/user/page/updateprofilesetting">
                                                Account Setting
                                            </Link>
                                            <Link to="/user/page/changePassword">
                                                Change Password
                                            </Link>
                                            <Link
                                                to="/user/dashboard"
                                                onClick={() => {
                                                    LogOutAll();
                                                }}
                                            >
                                                Logout
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div id="menu" className="text-left">
                                    <ul className="offcanvas_main_menu">
                                        <li>
                                            <Link to="/user/dashboard">My Dashboard</Link>
                                        </li>
                                        <li>
                                            <Link to="/cars">Rent a car</Link>
                                        </li>
                                        <li>
                                            <Link to="/about-us">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact-us">Contact Us</Link>
                                        </li>
                                        <li className="">
                                            <Link
                                                href="/"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    SetMoreMenu(!MoreMenu);
                                                }}
                                            >
                                                More &nbsp;
                                                <i
                                                    className={`fal fa-angle-${MoreMenu ? 'down' : 'right'
                                                        }`}
                                                />
                                            </Link>
                                            <ul
                                                className="sub-menu"
                                                style={{ display: MoreMenu ? '' : 'none' }}
                                            >
                                                {/* <li><Link to="/gallery">Gallery</Link></li> */}
                                                <li><Link to="/make-payment">Make Payment</Link></li>
                                                <li>
                                                    <Link to="/information/privacy">Privacy Policy</Link>
                                                </li>
                                                <li>
                                                    <Link to="/information/termandconditions">Term & Conditions</Link>
                                                </li> 
                                                <li>
                                                    <Link to="/information/RefundAndCancellation">Refund & Cancellation</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link
                                                to="/user/dashboard"
                                                onClick={() => {
                                                    LogOutAll();
                                                }}
                                            >
                                                Logout
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="offcanvas-social d-none">
                                    <ul>
                                        <li>
                                            <a
                                                className="you"
                                                href="https://www.youtube.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="fac"
                                                href="https://www.facebook.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="lin"
                                                href="https://www.linkedin.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="inst"
                                                href="https://www.instagram.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info d-none text-left">
                                    <ul>
                                        <li>
                                            <a href="mailto:support@yourdomian.com">
                                                <i className="fal fa-envelope" />{' '}
                                                support@yourdomian.com
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+91">
                                                <i className="fal fa-phone" /> +(0) 1234 56789
                                            </a>
                                        </li>
                                        <li>
                                            <a href="asd">
                                                <i className="fal fa-map-marker-alt" />
                                                sdfg
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
