import { createSlice } from '@reduxjs/toolkit';

let access_rate
let booking_id
let car_id
let distance
let extend_rate
let packagesid
let rate_per_hour
let type
let act_rate_per_hour
let dropDate
let dropTime
let pickupDate
let pickupTime
let hours
let img_url
let branch_name
let brand_name
let car_fuel_type
let car_luggage_space
let category_name
let model_name
let outstation_security_deposit
let security_deposit
let car_number
let short_description
let car_transmission_type
let branch_id
let rates ={}
// let message
// let doc_msg

let initialState = {
  access_rate: localStorage.getItem('access_rate'),
  booking_id: localStorage.getItem('booking_id'),
  car_id: localStorage.getItem('car_id'),
  distance: localStorage.getItem('distance'),
  extend_rate: localStorage.getItem('extend_rate'),
  packagesid: localStorage.getItem('packagesid'),
  rate_per_hour: localStorage.getItem('rate_per_hour'),
  type: localStorage.getItem('type'),
  act_rate_per_hour: localStorage.getItem('act_rate_per_hour'),
  dropDate: localStorage.getItem('dropDate'),
  dropTime: localStorage.getItem('dropTime'),
  pickupDate: localStorage.getItem('pickupDate'),
  pickupTime: localStorage.getItem('pickupTime'),
  hours: localStorage.getItem('hours'),
  img_url: localStorage.getItem('img_url'),
  branch_name: localStorage.getItem('branch_name'),
  brand_name: localStorage.getItem('brand_name'),
  car_fuel_type: localStorage.getItem('car_fuel_type'),
  car_luggage_space: localStorage.getItem('car_luggage_space'),
  category_name: localStorage.getItem('category_name'),
  model_name: localStorage.getItem('model_name'),
  outstation_security_deposit: localStorage.getItem('outstation_security_deposit'),
  security_deposit: localStorage.getItem('security_deposit'),
  car_number: localStorage.getItem('car_number'),
  short_description: localStorage.getItem('short_description'),
  car_transmission_type: localStorage.getItem('car_transmission_type'),
  branch_id: localStorage.getItem('branch_id'),
  rates: localStorage.getItem('rates'),
  message:"",
  doc_msg:"",

}

export const carDetails = createSlice({
  name: 'carDetailgg',
  initialState,
  reducers: {
    getCarBookingDetail: (state, action) => {

      localStorage.setItem('rates',action.payload.CarData.rates)
      localStorage.setItem('booking_id','')
      localStorage.setItem('access_rate',action.payload.s.access_rate)
      localStorage.setItem('car_id',action.payload.s.car_id)
      localStorage.setItem('distance',action.payload.s.distance)
      localStorage.setItem('extend_rate',action.payload.s.extend_rate)
      localStorage.setItem('packagesid',action.payload.s.packagesid)
      localStorage.setItem('type',action.payload.s.type)
      localStorage.setItem('act_rate_per_hour',action.payload.s.act_rate_per_hour)
      localStorage.setItem('rate_per_hour',action.payload.s.rate_per_hour)
      localStorage.setItem('dropDate',action.payload.CarMaster.dropDate)
      localStorage.setItem('dropTime',action.payload.CarMaster.dropTime)

      if(action.payload.CarMaster.hours === undefined)
      {
        localStorage.setItem('hours',action.payload.temp) 
      }
      else{

        localStorage.setItem('hours',action.payload.CarMaster.hours)
      }

      localStorage.setItem('pickupDate',action.payload.CarMaster.pickupDate)
      localStorage.setItem('pickupTime',action.payload.CarMaster.pickupTime)
      localStorage.setItem('img_url',action.payload.CarData.img_url)

      localStorage.setItem('branch_name',action.payload.CarData.branch_name)
      localStorage.setItem('brand_name',action.payload.CarData.brand_name)
      localStorage.setItem('car_fuel_type',action.payload.CarData.car_fuel_type)
      localStorage.setItem('car_luggage_space',action.payload.CarData.car_luggage_space)
      localStorage.setItem('category_name',action.payload.CarData.category_name)
      localStorage.setItem('model_name',action.payload.CarData.model_name)
      localStorage.setItem('outstation_security_deposit',action.payload.CarData.outstation_security_deposit)

      localStorage.setItem('security_deposit',action.payload.CarData.security_deposit)
      localStorage.setItem('car_number',action.payload.CarData.car_number)
      localStorage.setItem('short_description',action.payload.CarData.short_description)
      localStorage.setItem('car_transmission_type',action.payload.CarData.car_transmission_type)
      localStorage.setItem('branch_id',action.payload.CarData.branch_id)
     
    
      state.rates = rates
      state.access_rate = access_rate
      state.car_id = car_id
      state.distance = distance
      state.extend_rate = extend_rate
      state.packagesid = packagesid
      state.type = type
      state.act_rate_per_hour = act_rate_per_hour
      state.rate_per_hour = rate_per_hour
      state.dropDate = dropDate
      state.dropTime = dropTime
      if(hours > 10){
        state.hours = hours 
      }
      else{
      state.hours = 10  
      }
      state.pickupDate = pickupDate
      state.pickupTime = pickupTime
      state.img_url = img_url
      state.branch_name = branch_name
      state.brand_name = brand_name
      state.car_fuel_type = car_fuel_type
      state.car_luggage_space = car_luggage_space
      state.category_name = category_name
      state.model_name = model_name
      state.outstation_security_deposit = outstation_security_deposit
      state.security_deposit = security_deposit
      state.car_number = car_number
      state.short_description = short_description
      state.car_transmission_type = car_transmission_type
      state.branch_id = branch_id
    },

    getCarDetail: (state, action) => {

      localStorage.setItem('dropDate',action.payload.CarMaster.dropDate)
      localStorage.setItem('dropTime',action.payload.CarMaster.dropTime)
      localStorage.setItem('hours',action.payload.CarMaster.hours)
      localStorage.setItem('pickupDate',action.payload.CarMaster.pickupDate)
      localStorage.setItem('pickupTime',action.payload.CarMaster.pickupTime)
      localStorage.setItem('img_url',action.payload.CarData.img_url)

      localStorage.setItem('branch_name',action.payload.CarData.branch_name)
      localStorage.setItem('brand_name',action.payload.CarData.brand_name)
      localStorage.setItem('car_fuel_type',action.payload.CarData.car_fuel_type)
      localStorage.setItem('car_luggage_space',action.payload.CarData.car_luggage_space)
      localStorage.setItem('category_name',action.payload.CarData.category_name)
      localStorage.setItem('model_name',action.payload.CarData.model_name)
      localStorage.setItem('outstation_security_deposit',action.payload.CarData.outstation_security_deposit)

      localStorage.setItem('security_deposit',action.payload.CarData.security_deposit)
      localStorage.setItem('car_number',action.payload.CarData.car_number)
      localStorage.setItem('short_description',action.payload.CarData.short_description)
      localStorage.setItem('car_transmission_type',action.payload.CarData.car_transmission_type)
      localStorage.setItem('rates',JSON.stringify(action.payload.CarData.rates))

      state.dropDate = dropDate
      state.dropTime = dropTime
    
      if(hours > 10){
        state.hours = hours
      }
      else{
      state.hours = 10  
      }

      state.pickupDate =  pickupDate
      state.pickupTime =  pickupTime
      state.img_url = img_url
      state.branch_name = branch_name
      state.brand_name = brand_name
      state.car_fuel_type = car_fuel_type
      state.car_luggage_space = car_luggage_space
      state.category_name = category_name
      state.model_name = model_name
      state.outstation_security_deposit = outstation_security_deposit
      state.security_deposit = security_deposit
      state.car_number = car_number
      state.rates = rates
      state.short_description = short_description
      state.car_transmission_type = car_transmission_type
    },

    getExtendedCarDetail: (state, action) => {

      localStorage.setItem('rates',action.payload.carDetail.rates)
      localStorage.setItem('access_rate',action.payload.rate.access_rate)
      localStorage.setItem('car_id',action.payload.rate.car_id)
      localStorage.setItem('distance',action.payload.rate.distance)
      localStorage.setItem('extend_rate',action.payload.rate.extend_rate)
      localStorage.setItem('packagesid',action.payload.rate.packagesid)
      localStorage.setItem('type',action.payload.rate.type)
      localStorage.setItem('act_rate_per_hour',action.payload.rate.act_rate_per_hour)
      localStorage.setItem('rate_per_hour',action.payload.rate.rate_per_hour)
      localStorage.setItem('dropDate',action.payload.ExtendedCarDetail.dropDate)
      localStorage.setItem('dropTime',action.payload.ExtendedCarDetail.dropTime)

      localStorage.setItem('pickupDate',action.payload.ExtendedCarDetail.pickupDate)
      localStorage.setItem('pickupTime',action.payload.ExtendedCarDetail.pickupTime)
      localStorage.setItem('booking_id',action.payload.ExtendedCarDetail.booking_id)
      localStorage.setItem('img_url',action.payload.carDetail.img_url)
      localStorage.setItem('branch_name',action.payload.carDetail.branch_name)
      localStorage.setItem('brand_name',action.payload.carDetail.brand_name)
      localStorage.setItem('car_fuel_type',action.payload.carDetail.car_fuel_type)
      localStorage.setItem('car_luggage_space',action.payload.carDetail.car_luggage_space)
      localStorage.setItem('category_name',action.payload.carDetail.category_name)
      localStorage.setItem('model_name',action.payload.carDetail.model_name)
      localStorage.setItem('outstation_security_deposit',action.payload.carDetail.outstation_security_deposit)
      localStorage.setItem('security_deposit',action.payload.carDetail.security_deposit)
      localStorage.setItem('car_number',action.payload.carDetail.car_number)
      localStorage.setItem('short_description',action.payload.carDetail.short_description)
      localStorage.setItem('car_transmission_type',action.payload.carDetail.car_transmission_type)
      localStorage.setItem('branch_id',action.payload.carDetail.branch_id)
      localStorage.setItem('hours',action.payload.ExtendedCarDetail.hrs)


      state.rates = rates
      state.access_rate = access_rate
      state.car_id = car_id
      state.distance = distance
      state.extend_rate = extend_rate
      state.packagesid = packagesid
      state.type = type
      state.act_rate_per_hour = act_rate_per_hour
      state.rate_per_hour = rate_per_hour
      state.dropDate = dropDate
      state.dropTime = dropTime
      

      if(hours > 10)
      {
        state.hours = hours
        
      }
      else{
        state.hours = 10 
      }

      state.pickupDate = pickupDate
      state.pickupTime = pickupTime
      state.booking_id = booking_id
      state.img_url = img_url
      state.branch_name = branch_name
      state.brand_name = brand_name
      state.car_fuel_type = car_fuel_type
      state.car_luggage_space = car_luggage_space
      state.category_name = category_name
      state.model_name = model_name
      state.outstation_security_deposit = outstation_security_deposit
      state.security_deposit = security_deposit
      state.car_number = car_number
      state.short_description = short_description
      state.car_transmission_type = car_transmission_type
      state.branch_id = branch_id
    },


    getBookingMesaage: (state, action) =>{
      state.message = action.payload.message
      state.status = action.payload.status
      state.doc_msg = action.payload.doc_msg
    }
  },
})

// Action creators are generated for each case reducer function
export const { getCarDetail, getCarBookingDetail, getExtendedCarDetail,getBookingMesaage } = carDetails.actions

export default carDetails.reducer