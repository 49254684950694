import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IMGBASEAPIURL,BASEAPIURL, CustomerLoginURL, passwordResetMailSendURL,BASEURL, LoginRedirect } from '../../../../config';
import './login.css';
import LoginWithOTP from './loginWithOTP';
// import PhoneInput from 'react-phone-number-input';
import axios from 'axios';
// import AppFirebase from './firebase';

// for the Social Login
let SocialUsername = 'Google';
let SocialPassword = 'Pace2023#';
// for the Social Login

// { setisLogin }
function UserLogin() {

    // const navigate = useNavigate();

    const [OTPLogin, setOTPLogin] = useState(false);
    const [ShowOTPInp, setShowOTPInp] = useState(false);
    const [OTP, setOTP] = useState('');
    const [ForgotType] = useState('email');
    const [isAction, setisAction] = useState(false);
    const [lfVisibility, setlfVisibility] = useState('block');
    const [fpVisibility, setfpVisibility] = useState('none');
    const [msgClass, setmsgClass] = useState('alert-danger');
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [PasswordType, setPasswordType] = useState('password');
    const [message, setMessage] = useState('');
    const [MsgBeforeLogin] = useState(localStorage.getItem('MsgBeforeLogin'));
    localStorage.removeItem('MsgBeforeLogin');

    const [passwordResetMail, setPasswordResetMail] = useState({
        'email': ''
    });

    const UserSocialLogin = async (type, response) => {
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        // console.log(SocialUsername);
        // Create a base64-encoded token from your credentials
        const base64Token = btoa(`${SocialUsername}:${SocialPassword}`);
        try {
            const res = await fetch(`${BASEAPIURL}/student/UserSocialLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${base64Token}`,
                },
                body: JSON.stringify({ type, response }),
            });
            const resJson = await res.json();
            setisAction(false);
            if (res.status === 200) {
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    setmsgClass('alert-success');
                    localStorage.setItem('usertype', 'student');
                    localStorage.setItem(
                        'loggedToken',
                        resJson.loggedToken ? resJson.loggedToken : 0
                    );
                    localStorage.setItem(
                        'userdata',
                        resJson.data ? JSON.stringify(resJson.data) : JSON.stringify({})
                    );
                    setTimeout(() => {
                        // LoginRedirect();
                        // setisLogin(true);
                    }, 300);
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        try {
            const res = await fetch(CustomerLoginURL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: Username,
                    password: Password,
                    device: localStorage.getItem('DeviceDetails'),
                    loggedToken: localStorage.getItem('loggedToken'),
                }),
            });
            const resJson = await res.json();
            setisAction(false);
            if (res.status === 200) {
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    setmsgClass('alert-success');
                    localStorage.setItem('usertype', 'student');
                    localStorage.setItem('loggedToken', resJson.loggedToken ? resJson.loggedToken : 0);
                    localStorage.setItem('userdata', resJson.data ? JSON.stringify(resJson.data) : JSON.stringify({}));
                    setTimeout(() => {
                        localStorage.setItem('RedirectAfterLogin', `${BASEURL}cars`);
                        LoginRedirect();
                    
                    }, 300);
                }


                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    function FromPhoneInput(v) {
        setUsername(v);
        setShowOTPInp(false);
    }

    const handleSubmitOtp = async (e) => {
        e.preventDefault();
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        try {
            const res = await fetch(`${BASEAPIURL}/student/forgotPasswordOTPConfirm`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    mobile: Username,
                    password: Math.random(),
                    otp: OTP,
                }),
            });
            const resJson = await res.json();
            setisAction(false);
            if (res.status === 200) {
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    setTimeout(() => {
                        if (resJson.verifyLink && resJson.verifyLink !== '') {
                            window.location.href = resJson.verifyLink;
                        }
                    }, 1500);
                    setmsgClass('alert-success');
                    setOTP('');
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    // const handleSubmitFoggot = async (e) => {
    //     e.preventDefault();
    //     setisAction(true);
    //     setmsgClass('alert-warning');
    //     setMessage('Processing');
    //     let theURL = `${BASEAPIURL}/student/forgotPassword`;
    //     if (ForgotType === 'mobile') {
    //         theURL = `${BASEAPIURL}/student/forgotPasswordMobile`;
    //     }
    //     try {
    //         const res = await fetch(theURL, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({
    //                 email: Username,
    //                 password: Password,
    //             }),
    //         });
    //         const resJson = await res.json();
    //         setisAction(false);
    //         if (res.status === 200) {
    //             setmsgClass('alert-danger');
    //             if (resJson.status === 1) {
    //                 if (ForgotType === 'mobile') {
    //                     setShowOTPInp(true);
    //                 } else {
    //                     setUsername('');
    //                 }
    //                 setmsgClass('alert-success');
    //             }
    //             setMessage(resJson.message);
    //         } else {
    //             setMessage(resJson.message);
    //         }
    //     } catch (err) {
    //         setisAction(false);
    //         console.log(err);
    //     }
    // };


    const handleChangeResetPassword = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPasswordResetMail(values => ({ ...values, [name]: value }))
    }
    const handleSubmitFoggot = (e)=>{
      e.preventDefault();

      setmsgClass('alert-info');
      setMessage('Processing.....');
      const config = {
        headers: { 'Content-Type': 'application/json' },
      }
      const resetpassurl = `${BASEURL}user/change-password`;

      const formData = new FormData();
      formData.append('reset_pass_url', resetpassurl);
      formData.append('email', passwordResetMail.email);
      axios.post(passwordResetMailSendURL,formData,config).then((response)=>{
           
        if(response.data.status === 1){
            setmsgClass('alert-success');
            setMessage(response.data.message);
        }
        else{
            setmsgClass('alert-danger');
            setMessage(response.data.message);
        }
      });
    }

    return (
        <>
            <>
                {/* <AppFirebase /> */}
                {OTPLogin ? (
                    <LoginWithOTP setOTPLogin={setOTPLogin} UserSocialLogin={UserSocialLogin} />
                ) : (
                    ''
                )}
                <div className={`container pt-4 pb-4 ${OTPLogin ? 'd-none' : ''}`}>
                    <h4 className="text-center hide_empty_div text-dark">{MsgBeforeLogin}</h4>
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="row">
                                    <div className="col-md-6 pt-4 d-none d-sm-block text-center">
                                        <img
                                            src={`${IMGBASEAPIURL}students/pages/registration.jpg`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ maxWidth: '250px' }}
                                            className="mt-4 mb-4"
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <form
                                            className="m-4 p-4 fpform"
                                            style={{ display: fpVisibility }}
                                            // onSubmit={(e) => {if (ShowOTPInp) {
                                            //         handleSubmitOtp(e);
                                            //     } else {
                                            //         handleSubmitFoggot(e);
                                            //     }
                                            // }}
                                            onSubmit={handleSubmitFoggot}
                                        >

                                            {/* <div className='form-group'>
                                                <input
                                                    defaultChecked
                                                    type='email'
                                                    name="email"
                                                    value={passwordResetMail.email}
                                                    onChange={handleChangeResetPassword}
                                                />
                                                <label>&nbsp;Email ID </label>
                                                <div style={{ clear: 'both' }}></div>
                                            </div>


                                            <div
                                                className="mb-3 phoneNumberInpCont"
                                                style={{ position: 'relative', display: ForgotType === 'mobile' ? '' : 'none' }}
                                            >
                                                <PhoneInput
                                                    defaultCountry={getDefaultCountry()}
                                                    international
                                                    required={ForgotType === 'mobile'}
                                                    placeholder="Enter Mobile No."
                                                    onChange={FromPhoneInput}
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        left: '10px',
                                                        top: '14px',
                                                    }}
                                                >
                                                    <i className="fa fa-envelope"></i>
                                                </div>
                                            </div>
                                            {ShowOTPInp ? (
                                                <div
                                                    className="form-group"
                                                    style={{ position: 'relative', display: ForgotType === 'mobile' ? '' : 'none' }}
                                                >
                                                    <input
                                                        type="number"
                                                        name="otp"
                                                        value={OTP}
                                                        required={setShowOTPInp}
                                                        onChange={(e) => setOTP(e.target.value)}
                                                        placeholder="Enter OTP"
                                                        className="form-control form-control-lg"
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )} */}

                                            <div
                                                className="form-group"
                                                style={{ position: 'relative', display: ForgotType === 'email' ? '' : 'none' }}
                                            >
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={passwordResetMail.email}
                                                    required
                                                    onChange={handleChangeResetPassword}
                                                    placeholder="Enter Email ID"
                                                    className="form-control form-control-lg"
                                                />
                                            </div>

                                            <div
                                                className={`${msgClass} alert message hide_empty_div mb-3`}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <div style={{ display: isAction ? 'none' : '' }}>
                                                <button
                                                    type="submit"
                                                    className="btn btn btn-docsta-instructor"
                                                    style={{ width: '100%' }}
                                                >
                                                    {ShowOTPInp ? 'Confirm OTP' : 'Reset Password'}
                                                </button>
                                            </div>
                                            <div className="text-right mt-2">
                                                <anew
                                                    href="#"
                                                    className="text-dark"
                                                    onClick={() => {
                                                        setlfVisibility('block');
                                                        setfpVisibility('none');
                                                        setMessage('');
                                                    }}
                                                >
                                                    <b>Back to Login</b>
                                                </anew>
                                            </div>
                                        </form>
                                        <form
                                            className="mt-4 ml-4 mr-4 p-0 p-4"
                                            onSubmit={handleSubmit}
                                            style={{ display: lfVisibility }}
                                        >
                                            <h6 className="d-block mb-2 d-sm-none">Login</h6>
                                            <div
                                                className="form-group"
                                                style={{ position: 'relative' }}
                                            >
                                                <input
                                                    type="email"
                                                    name="email"
                                                    required
                                                    placeholder="Enter Email ID"
                                                    className="form-control form-control-lg"
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    value={Username}
                                                    style={{
                                                        paddingLeft: '35px',
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        left: '10px',
                                                        top: '14px',
                                                    }}
                                                >
                                                    <i className="fa fa-envelope"></i>
                                                </div>
                                            </div>
                                            <div
                                                className="form-group"
                                                style={{ position: 'relative' }}
                                            >
                                                <input
                                                    required
                                                    type={PasswordType}
                                                    name="password"
                                                    placeholder="Enter Password"
                                                    className="form-control form-control-lg"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    value={Password}
                                                    style={{
                                                        paddingLeft: '35px',
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        left: '10px',
                                                        top: '14px',
                                                    }}
                                                >
                                                    <i className="fa fa-lock"></i>
                                                </div>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        bottom: '14px',
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-eye"
                                                        onClick={() => setPasswordType('text')}
                                                        style={{
                                                            display:
                                                                PasswordType === 'text'
                                                                    ? 'none'
                                                                    : '',
                                                        }}
                                                    ></i>
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        onClick={() => setPasswordType('password')}
                                                        style={{
                                                            display:
                                                                PasswordType === 'password'
                                                                    ? 'none'
                                                                    : '',
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div
                                                className={`${msgClass} alert message hide_empty_div mb-3`}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <div style={{ display: isAction ? 'none' : '' }}>
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-docsta-instructor"
                                                >
                                                    Login
                                                </button>
                                            </div>
                                            <div className="text-right mt-2">
                                                <anew
                                                    href="#"
                                                    className="text-dark"
                                                    onClick={() => {
                                                        setlfVisibility('none');
                                                        setfpVisibility('block');
                                                    }}
                                                >
                                                    <b>Forgot Password?</b>
                                                </anew>
                                            </div>
                                        </form>
                                        <div className="p-0 d-none LoginSocialButtons">

                                            <div class="MobileLoginBtn">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setOTPLogin(true);
                                                    }}
                                                >
                                                    <i className="far fa-mobile"></i>
                                                    Login with OTP
                                                </button>
                                            </div>
                                        </div>
                                        <small
                                            className="mt-4 mb-4 d-block text-center"
                                            style={{ color: '#7b7b7b' }}
                                        >
                                            Don't have an Account?&nbsp;
                                            <Link
                                                to="/user/register"
                                                style={{ color: '#7f13ab' }}
                                            >
                                                Sign Up Here
                                            </Link>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
        </>
    );
}

export default UserLogin;
