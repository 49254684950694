import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BaseRentalURL } from '../config'

let IniBranch = localStorage.getItem('branch');
if (!IniBranch) {
    IniBranch = 'any';
}

let IniPickupDate = localStorage.getItem('pickupDate');
if (!IniPickupDate) {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1); // Set max date to 90 days from today
    IniPickupDate = maxDate.toISOString().split('T')[0];
    localStorage.setItem('pickupDate', IniPickupDate);
}

let IniDropDate = localStorage.getItem('dropDate');
if (!IniDropDate) {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 2); // Set max date to 90 days from today
    IniDropDate = maxDate.toISOString().split('T')[0];
    localStorage.setItem('dropDate', IniDropDate);
}

let IniPickupTime = localStorage.getItem('pickupTime');
if (!IniPickupTime) {
    IniPickupTime = '10:00';
    localStorage.setItem('pickupTime', IniPickupTime);
}

let IniDropTime = localStorage.getItem('dropTime');
if (!IniDropTime) {
    IniDropTime = '10:00';
    localStorage.setItem('dropTime', IniPickupTime);
}

const initialState = {
    branches: [],
    categories: [],
    branch: IniBranch,
    pickupDate: IniPickupDate,
    pickupTime: IniPickupTime,
    dropDate: IniDropDate,
    dropTime: IniDropTime,
}

// Define an async thunk to fetch FetchCarMaster START data
export const FetchCarMasterAPI = createAsyncThunk('FetchCarMasterAPI2', async (FilterFrom) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        //   const response = await fetch('your-api-endpoint'); // Replace with your actual API endpoint
        const response = await fetch(`${BaseRentalURL}InfoApi/FetchCarMaster`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ ...FilterFrom, status: 1 }),
        });
        // maincatid
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching customer data:', error);
        throw error;
    }
});
// Define an async thunk to fetch FetchCarMaster END data


export const CarMasterShow = createSlice({
    name: 'CarMasterShowa',
    initialState,
    reducers: {
        UpdateCarSearchData: (state, newData) => {
            Object.keys(newData.payload).forEach((key) => {
                state[key] = newData.payload[key]
            })
        },
    },
    extraReducers: (builder) => {
        builder
            // fetch item data will be start from here
                .addCase(FetchCarMasterAPI.pending, () => {
                    // console.log('loading')
                    // console.log(state)
                })
                .addCase(FetchCarMasterAPI.fulfilled, (state, action2) => {
                    // console.log('succeeded') 
                    if (action2.payload.branches && action2.payload.branches.length > 0) {
                        state.branches = action2.payload.branches;
                    }
                    if (action2.payload.categories && action2.payload.categories.length > 0) {
                        state.categories = action2.payload.categories;
                    }
                })
                .addCase(FetchCarMasterAPI.rejected, (state, action) => {
                    console.log('failed');
                    console.log(state);
                    console.error(action);
                })
            // fetch item data will be End from here
    },
});

// Action creators are generated for each case reducer function

export const { UpdateCarSearchData } = CarMasterShow.actions

export default CarMasterShow.reducer;
