import React from 'react';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Drawer from '../../../Mobile/DrawerInstitute';
import Header from '../../../Helper/HeaderInstitute';
import Footer from '../../../Helper/FooterInstitute';
import ResetPassword from './ResetPassword';

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <ResetPassword />
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
