import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LogOutAll } from '../../../../config';
import { UpdateCommonData } from '../../../../reduxFunction/commonSlice';
import { useDispatch, useSelector } from 'react-redux';

function ProfileMenu({ forpage, Profile }) {
    // const history = useHistory();
    const [ShowAlert, setShowAlert] = useState(false);
    // const [ShowSubCourseMenu] = useState(false);

    const [IsStudentAccoutMenu, setIsStudentAccoutMenu] = useState(Number(localStorage.getItem('screenwidth')) > 999);
    function StudentAccoutMenu(v) {
        dispatch(UpdateCommonData({ActProfileMenu: v}));
        if (Number(localStorage.getItem('screenwidth')) < 999) {
            setIsStudentAccoutMenu(!IsStudentAccoutMenu);
        }
    }

    const CommonData = useSelector((state) => state.commonData);
    const dispatch = useDispatch();

    return (
        <>
            <div className="ryPopUpBack" style={{ display: ShowAlert ? '' : 'none' }}></div>
            <div
                className="ryPopUp"
                style={{
                    display: ShowAlert ? '' : 'none',
                    position: 'fixed',
                    height: 'fit-content',
                }}
            >
                <Link
                    href="#"
                    className="ryPopUpAct"
                    onClick={() => {
                        setShowAlert(false);
                    }}
                >
                    X
                </Link>
                <div style={{ margin: '30px 50px' }}>
                    <h3 className="text-center">Are you sure, You want to Logout? </h3>
                    <div className="mt-4 mb-4"></div>
                    <div className="text-center">
                        <Link
                            to="#"
                            className="btn btn-outline d-inline-block mr-3 text-white"
                            onClick={() => {
                                setShowAlert(false);
                            }}
                            style={{
                                width: '150px',
                                margin: '20px auto',
                                border: '15px',
                                backgroundColor: '#939393',
                            }}
                        >
                            No
                        </Link>
                        <Link
                            to="#"
                            className="btn btn-docsta-instructor d-inline-block"
                            onClick={() => {
                                LogOutAll();
                            }}
                            style={{
                                width: '150px',
                                margin: '20px auto',
                                border: '15px',
                            }}
                        >
                            Yes
                        </Link>
                    </div>
                </div>
            </div>
            {!IsStudentAccoutMenu ? (
                <button
                    style={{ width: '100%' }}
                    className='btn btn-purple text-center d-block mt-3'
                    type="button"
                    onClick={() => {
                        StudentAccoutMenu();
                    }}
                >
                    My Booking
                </button>
            ) : ''}
            <div
                className="ry_instr_shadow pt-3 pb-3"
                forpage={forpage}
                style={{ display: IsStudentAccoutMenu ? '' : 'none' }}
            >
                <Link
                    to="/user/dashboard"
                    className={`ry_instr_menu ${CommonData.ActProfileMenu === 'Dashboard' ? 'active' : ''}`}
                    onClick={() => { StudentAccoutMenu('Dashboard'); }}
                >
                    <i className="fal fa-window" /> My Booking
                </Link>
                

                <Link
                    to="/user/page/profilesetting"
                    className={`ry_instr_menu ${CommonData.ActProfileMenu === 'profilesetting' ? 'active' : ''}`}
                    onClick={() => { StudentAccoutMenu('profilesetting'); }}
                >
                    <i className="fal fa-user" /> My Profile
                </Link>
                <Link
                    to="/user/page/MyFavourite"
                    className={`ry_instr_menu ${CommonData.ActProfileMenu === 'MyFavourite' ? 'active' : ''}`}
                    onClick={() => { StudentAccoutMenu('MyFavourite'); }}
                >
                    <i className="fal fa-heart" /> My Favourite
                </Link>
                <Link
                    to="/user/page/MyTransaction"
                    className={`ry_instr_menu ${CommonData.ActProfileMenu === 'MyTransaction' ? 'active' : ''}`}
                    onClick={() => { StudentAccoutMenu('MyTransaction'); }}
                >
                    <i className="fal fa-money-check-alt" />My Transaction
                </Link>
                <Link
                    to="/user/page/MyDocument"
                    className={`ry_instr_menu ${CommonData.ActProfileMenu === 'MyDocument' ? 'active' : ''}`}
                    onClick={() => { StudentAccoutMenu('MyDocument'); }}
                >
                    <i className="fal fa-hand-holding-usd" /> My Document
                </Link>

                <hr />

                <Link
                    to="/user/page/inviteFriends"
                    className={`ry_instr_menu ${CommonData.ActProfileMenu === 'inviteFriends' ? 'active' : ''}`}
                    onClick={() => { StudentAccoutMenu('inviteFriends'); }}
                >
                    <i className="fal  fa-user-plus" /> Invite Friends & Earn
                </Link>

                {/* <Link
                    to="/instructor"
                    className={`ry_instr_menu ${CommonData.ActProfileMenu === 'instructor' ? 'active' : ''}`}
                    onClick={() => { StudentAccoutMenu('instructor'); }}
                >
                    <i className="fal fa-chalkboard-teacher" /> Be an instructor
                </Link> */}
                <Link
                    to="/contact-us"
                    className={`ry_instr_menu ${CommonData.ActProfileMenu === 'contact-us' ? 'active' : ''}`}
                    onClick={() => { StudentAccoutMenu('Dashboard'); }}
                >
                    <i className="fal fa-address-card" /> Help & Support
                </Link>
                <Link
                    to="/user/page/shareApps"
                    className={`d-none ry_instr_menu ${CommonData.ActProfileMenu === 'user/page/shareApps' ? 'active' : ''}`}
                    onClick={() => { StudentAccoutMenu('user/page/shareApps'); }}
                >
                    <i className="fal fa-share-alt" /> Share the App
                </Link>
                <Link to="/about-us" target="_BLANK" className="ry_instr_menu">
                    <i className="fal fa-users" /> About Us
                </Link>
                <anew
                    className="ry_instr_menu"
                    onClick={() => {
                        setShowAlert(true);
                    }}
                >
                    <i className="fal fa-sign-out" /> Logout
                </anew>
            </div>
        </>
    );
}

export default ProfileMenu;
