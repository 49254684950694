import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASEURL, IMGBASEAPIURL, UPLOADNODEURLCDN, UserBookingURL, showRatingStar, fetchAvailableCarsURL, ShowValueInFormat, fetchBookingCancellationCharge, cancelBookingURL, fetchDashboardCounterURL, LoginRedirect } from '../../../../config';
// import { RemoveCourseFromCart } from '../../functions';
import ReviewCourse from '../../../Learn/CourseStudent/ReviewCourse';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// import { UpdateCarSearchData } from '../../../../reduxFunction/CarMasterSlice';
import axios from 'axios';
import { getBookingMesaage, getExtendedCarDetail } from '../../../../reduxFunction/CarDetailSlice';
import ReactPaginate from 'react-paginate';

function ProfileMenu({ forpage }) {

    // let SearchCarAPI = true;
    const todayDate = new Date();
    const formattedDate = todayDate.toISOString().split('T')[0];
    const dispatch = useDispatch();
    const navut = useNavigate()
    const FilterBy = { orderBy: 'auto' };
    const [Message, SetMessage] = useState({ status: 0, value: '' });
    const [msgClass, setMsgClass] = useState('alert-danger');
    const [message, setMessage] = useState('');
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const order_id = params.get('order_id');
    const status = params.get('status');
    const [CancellationCharge, setCancellationCharge] = useState([]);
    const [userData, Setuserdata] = useState({ name: '' })
    const [CourseMongoID] = useState(0);
    const [showReviewForm, setshowReviewForm] = useState(false);
    const [myBooking, setMyBooking] = useState([]);
    const [ExtendedCarDetail, setExtendedCarDetail] = useState([]);
    const [dashboardCounter, setDashboardCounter] = useState([]);
    const [dropDate, setDropDate] = useState('');
    const [dropTime, setDropTime] = useState('');
    const [carDetail, setCarDetail] = useState([]);
    const [hours, setHours] = useState();
    const [extendCar, setExtendCar] = useState({
        pickupDate: '',
        pickupTime: '',
        branch: '',
        branch_id: '',
        dropDate: '',
        dropTime: '',
    });
    const [cancelBooking, setCancelBooking] = useState([]);
    const [extendBooking, setExtendBooking] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const bookingsPerPage = 3;
    const pageCount = Math.ceil(myBooking && myBooking.length / bookingsPerPage);
    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
    };
    const displayedBookings = myBooking && myBooking.slice(pageNumber * bookingsPerPage, (pageNumber + 1) * bookingsPerPage);

    useEffect(() => {
        setDropDate(extendCar.dropDate);
        setDropTime(extendCar.dropTime);
        FetchAvailableCars(extendCar);

    }, [extendCar]);

    function FetchCoursesReview() {

    }

    const FetchAvailableCars = async (inputData) => {
        let SearchCarAPI = true;
        const extendData = new FormData();
        extendData.append('pickupDate', extendCar.dropDate);
        extendData.append('pickupTime', extendCar.dropTime);
        extendData.append('orderBy', 'auto');
        extendData.append('extend_car_id', extendCar.car_id);
        extendData.append('forCarID', extendCar.car_id);
        extendData.append('extendbooking', 1);
        extendData.append('booking_id', extendCar.id);

        extendData.append('branch', extendCar.branch_id);
        extendData.append('dropDate', inputData.dropDate === undefined ? dropDate : inputData.dropDate);
        extendData.append('dropTime', inputData.dropTime === undefined ? dropTime : inputData.dropTime);


        const pickupDateVal = extendData.get('pickupDate');
        const pickupTimeVal = extendData.get('pickupTime');
        const dropDateVal = extendData.get('dropDate');
        const dropTimeVal = extendData.get('dropTime');
        const pickupDateTime = new Date(`${pickupDateVal}T${pickupTimeVal}`);
        const dropDateTime = new Date(`${dropDateVal}T${dropTimeVal}`);
        const timeDifference = dropDateTime - pickupDateTime;
        const temp = (timeDifference / (1000 * 60 * 60));
        setHours(temp);
        extendData.append('hrs', temp);

        const extendDataObject = {};
        for (const [key, value] of extendData.entries()) {
            extendDataObject[key] = value;
        }
        setExtendedCarDetail(extendDataObject);

        try {

            if (pickupDateTime >= dropDateTime) {
                SearchCarAPI = false;
                setCarDetail([]);
                SetMessage({ status: 0, value: "Please ensure that the drop-off date and time are scheduled after the pickup date and time. Thank you for your cooperation!" });
            }
            else if (hours && hours < 5) {
                SearchCarAPI = false;
                SetMessage({ status: 0, value: `The minimum booking duration for a car is 6 hours. Unfortunately, your requested booking is for only ${hours} hours. Please adjust the booking duration to meet the minimum requirement.` });
                setCarDetail([]);
                console.log(Message.value);

            }
            else {
                if (SearchCarAPI) {

                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                        },
                    };
                    axios.post(fetchAvailableCarsURL, extendData, config).then((response) => {
                        if (response.data.data !== "") {
                            console.log(response.data.data);
                            setCarDetail(response && response.data.data[0]);
                            SetMessage({ status: 1, value: response.data.message });

                        } else {
                            SetMessage({ status: 0, value: response.data.message });
                            console.error(response.message);
                        }
                    });
                }
            }
        } catch (err) {

            console.error('FetchAvailableCars');
            console.error(err);
        }
    };

    const handleClick = (carDetail, rate) => {

        dispatch(getExtendedCarDetail({ carDetail, rate, ExtendedCarDetail }))
        localStorage.setItem('RedirectAfterLogin', `${BASEURL}user/bookmyride`);
        LoginRedirect();
    }

    const fetchUserBooking = async () => {
        try {
            const url = UserBookingURL;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
            };
            axios.post(url, config).then((response) => {
                if (response.data.booking !== "") {
                    // console.log('booking', response.data.booking)
                    setMyBooking(response.data.booking);
                }
                else {
                    SetMessage(response.data.message);
                }
            });
        } catch (error) {
            console.error('Error fetching user data:', error.message);
        }
    };

    useEffect(() => {
        fetchUserBooking();
        fetchDashboardCounter();
        const temp = localStorage.getItem('userdata');
        try {
            const response = JSON.parse(temp);
            Setuserdata(response);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }

    }, []);

    const [showExtend, setExtendShow] = useState(false);
    const [showCancel, setCancelShow] = useState(false);
    const handleExtendShow = (data) => {
        setExtendShow(true);
        setExtendBooking(data);
        setExtendCar(data);
    };
    const handleExtendClose = () => setExtendShow(false);
    const handleCancelShow = (data) => {
        fetchBookingCancellation();
        setCancelShow(true);
        setCancelBooking(data)
    }

    const handleCancelClose = () => setCancelShow(false);
    const handleCancelBooking = () => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
            },
        };
        axios.post(cancelBookingURL, cancelBooking, config).then((cancelRes) => {

            if (cancelRes.data.status === 1) {
                // console.log('can', cancelRes.data.message);
                setMsgClass('alert-success');
                setTimeout(() => {
                    setMessage(cancelRes.data.message);
                    dispatch(getBookingMesaage({ 'message': cancelRes.data.message, 'status': 'success', 'doc_msg': '' }));
                    navut('/thankyou');
                }, 2000);
            }
            else {
                console.log('can', cancelRes.data);
                // setMsgClass('alert-danger');
                setTimeout(() => {
                    setMessage(cancelRes.data.message);
                    dispatch(getBookingMesaage({ 'message': cancelRes.data.message, 'status': 'danger' }));
                    navut('/thankyou');
                }, 2000);
            }
        });
    }

    const fetchDashboardCounter = () => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
            },
        };
        axios.post(fetchDashboardCounterURL, config).then((response) => {
            setDashboardCounter(response.data.data);
        })
    }

    const fetchBookingCancellation = async () => {
        try {

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
            };
            axios.get(fetchBookingCancellationCharge, config).then((response) => {
                if (response.data.data !== "") {
                    console.log('cancellation charge', response.data.data)
                    setCancellationCharge(response.data.data)
                }
                else {
                    SetMessage(response.data.message);
                }
            });
        } catch (error) {
            console.error('Error fetching user data:', error.message);
        }
    };

    return (
        <>
            {order_id && order_id !== '' && status && Number(status) === 1 ? (
                <>
                    <p className="alert alert-success">Thanks for Booking</p>
                </>
            ) : (
                ''
            )}
            {order_id && order_id !== '' && status && Number(status) === 2 ? (
                <>
                    <p className="alert alert-danger">
                        Thanks for your interest, But the Payment was not Successfull.
                    </p>
                </>
            ) : (
                ''
            )}
            {/* for Giving the Review for the Course */}
            <div className="ryPopUpBack" style={{ display: showReviewForm ? '' : 'none' }}></div>
            <div
                className="ryPopUp"
                style={{
                    display: showReviewForm ? '' : 'none',
                    position: 'fixed',
                    height: 'fit-content',
                }}
            >
                <anew
                    href="#"
                    className="ryPopUpAct"
                    onClick={() => {
                        setshowReviewForm(false);
                    }}
                >
                    X
                </anew>
                <div style={{ margin: '30px 50px' }}>
                    {showReviewForm ? (
                        <>
                            <ReviewCourse
                                TheCourseID={CourseMongoID}
                                setshowReviewForm={setshowReviewForm}
                                userdata={userData}
                                FetchCoursesReview={FetchCoursesReview}
                            />
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {/* for Giving the Review for the Course */}
            <div className="ry_instr_shadow p-4" forpage={forpage}>
                <div className="row std_db_counted">
                    <div className="col-6 mb-3 mb-md-0 col-md-3">
                        <div className="instr_tot_cours_updt d-flex">
                            <div className="icn">
                                <i className="fa fa-2x fa-books"></i>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '8px',
                                }}
                            >
                                <span
                                    className="text-dark"
                                    style={{
                                        textAlign: 'initial',
                                        fontSize: '20px',
                                        fontWeight: '900',
                                    }}
                                >
                                    {dashboardCounter && dashboardCounter.current_booking}
                                </span>
                                <b className="text-dark">Current Bookings</b>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="instr_tot_std d-flex">
                            <div className="icn2">
                                <i className="fal fa-2x fa-badge-check"></i>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '8px',
                                }}
                            >
                                <span
                                    className="text-dark"
                                    style={{
                                        textAlign: 'initial',
                                        fontSize: '20px',
                                        fontWeight: '900',
                                    }}
                                >

                                    {dashboardCounter && dashboardCounter.complete_booking}
                                </span>
                                <b className="text-dark">
                                    Completed Booking
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-6  col-md-3">
                        <div className="instr_tot_webi d-flex">
                            <div className="icn3">
                                <i className="fal fa-2x fa-chalkboard-teacher"></i>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '8px',
                                }}
                            >
                                <span
                                    className="text-dark"
                                    style={{
                                        textAlign: 'initial',
                                        fontSize: '20px',
                                        fontWeight: '900',
                                    }}
                                >
                                    {dashboardCounter && dashboardCounter.cancel_booking}
                                </span>
                                <b className="text-dark">
                                    Cancelled Booking
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="instr_tot_blog d-flex">
                            <div className="icn4">
                                <i className="fa fa-2x fa-books"></i>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '8px',
                                    fontWeight: '900',
                                }}
                            >
                                <span
                                    className="text-dark"
                                    style={{
                                        textAlign: 'initial',
                                        fontSize: '20px',
                                    }}
                                >
                                    {dashboardCounter && dashboardCounter.transaction}
                                </span>
                                <b className="text-dark">
                                    Transaction
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ry_instr_shadow p-4 mt-4" forpage={forpage}>

                <div className="row">
                    <div className="stu_dash_course col-lg-12 mb-3">
                        <h5>
                            {userData && userData.name ? userData.name : ''}, Let's continue drive
                        </h5>
                    </div>
                </div>
                <div
                    className="col-md-12 std_header_on_page"
                    style={{
                        backgroundColor: '#f0eeee',
                        marginTop: '50px',
                        borderRadius: '5px',
                        padding: '20px',
                        textAlign: 'center',
                        display: myBooking && myBooking.length === 0 ? '' : 'none',
                    }}
                >
                    <p>No Car Booking yet.</p>
                    <br />
                    <Link
                        to="/cars"
                        className="btn btn btn-docsta-instructor"
                        style={{
                            width: 'max-content',
                            margin: '0px auto',
                        }}
                    >
                        Book Car now
                    </Link>
                </div>
                <div className="row">
                    <div className="stu_dash_course col-lg-12">
                        {displayedBookings && displayedBookings.map((s) => (
                            <div
                                key={s._id}
                                style={{
                                    border: '1px solid #f5f5f5',
                                    background: '#fff',
                                    marginBottom: '30px',
                                    padding: '10px',
                                }}
                            >
                                <div className="row">
                                    <div className="col-md-3">
                                        {/* <a href="javascript:void(0)" rel="noreferrer"> */}
                                        <img
                                            src={s.img_url === '' ? `${BASEURL}images/car-img1.jpg` : `${UPLOADNODEURLCDN}${s.img_url}`}

                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                height: '150px',
                                                width: '100%',
                                            }}
                                        />
                                        {/* </a> */}
                                    </div>
                                    <div className="col-md-7">
                                        <div>
                                            {/* <a href="javascript:void(0)" rel="noreferrer" > */}
                                            <h4> {s.brand_name} {s.model_name} ( {s.car_luggage_space} Seater) </h4>
                                            <p><span className='px-3'>{showRatingStar(5)} </span> {Number(s.car_transmission_type) === 1 ? 'Automatic' : 'Manual'}  <span className="dot"></span> {s.car_fuel_type} <span className="dot"></span> {s.category_name}</p>
                                            {/* </a> */}
                                            <br />
                                            <div className='container'>
                                                <small><span className='text-warning'>Pickup: </span>   {s.pickupDate} {s.pickupTime}</small><br />
                                                <small><span className='text-warning'>Dropof: </span>   {s.dropDate} {s.dropTime}</small>
                                            </div>

                                            <div>
                                                <Button
                                                    variant="primary"
                                                    className="btn mt-2 buynow-btn"
                                                    style={{
                                                        width: 'max-content',
                                                        borderRadius: '0px',
                                                        background: '#7f13ab',
                                                        color: '#f5f5f5',
                                                    }}
                                                    onClick={() => handleExtendShow(s)}
                                                >
                                                    Extend Booking
                                                </Button>

                                                <Button
                                                    className="btn mt-2 btn btn-outline-danger  mx-2"
                                                    style={{
                                                        width: 'max-content',
                                                        background: '#fff',
                                                        color: 'black',
                                                        borderRadius: '0px',
                                                    }}
                                                    onClick={() => handleCancelShow(s)}
                                                >
                                                    Cancel Booking
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        {s.booking_status === 1 ? (
                                            <Button
                                                className="btn mt-5 btn btn-warning"
                                                style={{
                                                    width: 'max-content',

                                                    color: 'black',
                                                    borderRadius: '0px',
                                                }}
                                            >
                                                Active
                                            </Button>
                                        ) : s.booking_status === 0 ? (
                                            <Button
                                                className="btn mt-5 btn btn-warning"
                                                style={{

                                                    width: 'max-content',
                                                    color: 'black',
                                                    borderRadius: '0px',
                                                }}
                                            >
                                                Closed
                                            </Button>
                                        ) : s.booking_close_flag === 1 ? (

                                            <Button
                                                className="btn mt-5 btn btn-warning"
                                                style={{
                                                    width: 'max-content',

                                                    color: 'black',
                                                    borderRadius: '0px',
                                                }}
                                            >
                                                Closed
                                            </Button>

                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <ReactPaginate

                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active-page'}
                            previousClassName={'custom-previous-class'}
                            nextClassName={'custom-next-class'}
                            pageClassName={'custom-page-class'}
                        />
                        <div className="d-none student_dashCard col-lg-12">
                            <div className="col-lg-4 stu_dash_card d-flex">
                                <div className="col-lg-4">
                                    <img
                                        src={`${IMGBASEAPIURL}instructor/course.png`}
                                        alt="Inc Profile"
                                        className="mr-2 ry-pull-left"
                                    />
                                </div>
                                <div className="col-lg-8">
                                    <h4>Schedule Time to Ride</h4>
                                    {/* <p>
                                        a Little each day adds up. Get <br />
                                        reminders from your calendar.
                                    </p> */}
                                </div>
                            </div>
                            {/* <div className="col-lg-4 stu_dash_card">world</div> */}
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" centered show={showExtend} onHide={handleExtendClose}>
                <Modal.Header closeButton>
                    <Modal.Title>EXTEND BOOKING ID:  {extendBooking.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {extendBooking && formattedDate > extendBooking.dropDate ? (

                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <p>You cannot extend the Booking before the drop time.</p>
                        </div>
                    ) : extendBooking.booking_close_flag === '2' ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <p>You cannot extend these Booking is cancelled by you.</p>
                        </div>
                    )
                        : (
                            <>
                                <div className='row'>
                                    <Alert className='bg-primart text-dark text-center'>
                                        * If you wish to extend your booking, please consider the following options:
                                    </Alert>

                                    <div className='col-md-6 col-12'>
                                        <label className='text-primary'>New Drop Date</label>
                                        <input
                                            className='form-control my-2'
                                            required
                                            min={todayDate}
                                            type="date"
                                            value={dropDate && dropDate}
                                            name="dropDate"
                                            onChange={(e) => {
                                                setDropDate(e.target.value);
                                                FetchAvailableCars({ ...FilterBy, [e.target.name]: e.target.value });
                                            }}
                                        />
                                    </div>

                                    <div className='col-md-6 col-12'>
                                        <label className='text-primary' >New Drop Time</label>
                                        <input
                                            className='form-control my-2'
                                            placeholder='New Drop Time'
                                            required
                                            type="time"
                                            value={dropTime}
                                            name="dropTime"
                                            onChange={(e) => {
                                                setDropTime(e.target.value);
                                                FetchAvailableCars({ ...FilterBy, [e.target.name]: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="mt-2 mb-1 ml-0 mr-0 spec-1 row text-dark">
                                        {carDetail && carDetail !== '' ?
                                            carDetail.rates &&
                                            carDetail.rates.map((s) => (
                                                <div className="col-md-4 rentalDiv" onClick={() => handleClick(carDetail, s)}>
                                                    <div>
                                                        <h6 className='text-success mb-2'>₹ {ShowValueInFormat((Number(hours) * Number(s.rate_per_hour)).toFixed(0))}</h6>
                                                        <small className='text-dark'>{ShowValueInFormat((Number(hours) * Number(s.distance)).toFixed(0))}KM Driving Limit</small>
                                                        <br />
                                                        <small className='text-danger'>((Extra KM charge ₹{s.access_rate}))</small>
                                                    </div>
                                                </div>
                                            ))
                                            : (
                                                <p>{Message.value}</p>
                                            )}
                                    </div>
                                </div>
                            </>
                        )}
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered show={showCancel} onHide={handleCancelClose}>
                <Modal.Header closeButton>
                    <Modal.Title>CANCEL BOOKING ID: {cancelBooking.id}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='row'>
                        <h4 class="text-info cancelhead"> *Cancellation Charges</h4>
                        <table class="table userbooking_btmtbl">
                            <thead>
                                <tr>
                                    <th>Hours before pickup time</th>
                                    <th>Deduction percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {CancellationCharge && CancellationCharge.map((s) => (
                                    <tr>
                                        <td>{s.min_hr_before_start_time}  - {s.max_hr_before_start_time} hours</td>
                                        <td>{s.deduction_percent} % </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div class="well well-text">
                            <b><p class="text-info">* Note : Deduction percentage will be applied on the base fare excluding the security deposit. Security deposit are 100% refundable.</p></b>
                        </div>
                        <div className="col-md-12">
                            <div className={`message alert hide_empty_div mb-3 ${msgClass}`}>
                                {message ? <span>{message}</span> : ''}
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    {cancelBooking.booking_close_flag && cancelBooking.booking_close_flag === '2' ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <p>Your booking is already Cancelled.</p>
                        </div>
                    ) : cancelBooking.pickupDate < formattedDate ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <p>You can not cancel the Booking after the Pickup time.</p>
                        </div>
                    ) : (

                        <Button type='button' variant="primary" onClick={() => handleCancelBooking()}>
                            Submit
                        </Button>

                    )
                    }
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default ProfileMenu;
