import React, { useState, useEffect, Suspense } from 'react';
import Parser from 'html-react-parser';
import { Link, useParams } from 'react-router-dom';
import { BASEAPIURL, UPLOADNODEURL, BASEURL, CleanUrlWork } from '../../../config';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import Sharethis from '../../Helper/Sharethis';
import './blog.css';

const HighlyPopular = React.lazy(() => import('../HighlyPopularCourses'));

function showdatetimein(datetime) {
    const tmp = new Date(datetime);
    return `${tmp.getDate()}/${tmp.getMonth()}/${tmp.getUTCFullYear()}`;
}

function BlogSingle() {
    const [ShareThisURL, setShareThisURL] = useState(BASEURL);
    const [drawer, drawerAction] = useToggle(false);
    const [Blogs, setBlogs] = useState({ description: '' });
    const { BlogID } = useParams();
    // const [BlogsCati, setBlogsCati] = useState([]);
    const [FeaturedBlogs, setFeaturedBlogs] = useState([]);
    const FetchTopBlogs = async (forHere, SortByHere, Limited, keywordHere) => {
        // Keyword SortBy
        try {
            const res = await fetch(`${BASEAPIURL}/blog/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    keyword: keywordHere,
                    sortby: SortByHere,
                    PageNumber: 1,
                    category: 0,
                    recordsPerPage: Limited,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (forHere === 'featured') {
                    setFeaturedBlogs(resJson.data);
                }
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };
    useEffect(() => {
        FetchTopBlogs('featured', 'featured', 7, '');
        const FetchBlog = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/blog/GetBlogBySlug`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        inc_id: BlogID,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200 && resJson.data) {
                    setBlogs(resJson.data);
                    setShareThisURL(`${BASEURL}blog/${resJson.inc_id}/${CleanUrlWork(resJson.tbl_blog_tittle)}`);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchBlog();
    }, [BlogID]);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />

            <div className="appie-page-title-area d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-page-title-item">
                                <h3 className="title">Blog</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blogpage-section" style={{ paddingTop: '30px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div
                                className="single-post-area"
                                style={{ border: '1px solid #f5f5f5', padding: '20px' }}
                            >
                                <h4 className="article-title">{Blogs.tbl_blog_tittle}</h4>
                                <div className="post-thumb">
                                    <img src={`${UPLOADNODEURL}${Blogs.tbl_blog_img}`} alt="" />
                                </div>
                                <div>
                                    {Blogs.tbl_description ? Parser(Blogs.tbl_description) : ''}
                                </div>
                                <div className="post-share">
                                    <Sharethis ShareThisURL={ShareThisURL} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-sidebar">
                                <aside className="widget widget-search">
                                    <div className="search-form">
                                        <input
                                            type="search"
                                            name="s"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                FetchTopBlogs(
                                                    'featured',
                                                    'featured',
                                                    5,
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        <button type="submit">
                                            <i className="fal fa-search"></i>
                                        </button>
                                    </div>
                                </aside>
                                <aside className="widget widget-trend-post">
                                    <h3 className="widget-title">Featured</h3>
                                    {FeaturedBlogs.length === 0 ? (
                                        <div className="alert alert-info">
                                            No Blog Found with Your Search.
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {FeaturedBlogs.map((sitem) => (
                                        <Link to={`${BASEURL}blog/${sitem.inc_id}/${CleanUrlWork(sitem.tbl_blog_tittle)}`} className="popular-post">
                                            <img src={`${UPLOADNODEURL}${sitem.tbl_blog_img}`} alt="" />
                                            <div className="textBox">
                                                <h5>{sitem.tbl_blog_tittle}</h5>
                                                <span>{showdatetimein(sitem.updted_date)}</span>
                                            </div>
                                        </Link>
                                    ))}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Suspense fallback={<div>Loading...</div>}>
                <HighlyPopular />
            </Suspense>

            <Footer />
            <BackToTop />
        </>
    );
}

export default BlogSingle;
