import React, { Suspense, useEffect } from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import CalendarBox from '../../cars/CalendarBox';
// import PopularCoursesBox from '../PopularCoursesBox';

const AllCoursesMain = React.lazy(() => import('./AllCoursesMain'));
const PopularCoursesBox = React.lazy(() => import('../../Institute/PopularCoursesBox'));
const AllCourseBanner = React.lazy(() => import('./AllCourseBanner'));

function AllCourses() {
    const queryParameters = new URLSearchParams(window.location.search);
    // const [GetCategory, setGetCategory] = useState(queryParameters.get('category'));
    // const [GetSubCategory, setGetSubCategory] = useState(queryParameters.get('subcategory'));
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        // const queryParameters2 = new URLSearchParams(window.location.search);
        // setGetCategory(queryParameters2.get('category'));
        // setGetSubCategory(queryParameters2.get('subcategory'));
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Suspense fallback={<div>Loading...</div>}>
                <AllCourseBanner GetCategory={queryParameters.get('category')} GetSubCategory={queryParameters.get('subcategory')} />
            </Suspense>
            {/* <AllCoursesMain className="pt-70" /> */}
            <Suspense fallback={<div>Loading...</div>}>
                <div className="">
                    <AllCoursesMain className="pt-3" />
                </div>
            </Suspense>
            <CalendarBox />
            <Suspense fallback={<div>Loading...</div>}>
                <PopularCoursesBox />
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default AllCourses;
