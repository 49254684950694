import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASEURL, CustomerPasswordChangeURL } from '../../../../config';

function ChangePasswordPage({ forpage }) {
    const [FormsData, setFormsData] = useState({
        password: '',
        newpassword: '',
        conpassword: '',
        loggedToken: localStorage.getItem('loggedToken'),
    });
    const handleFormData = (name, value) => {
        setFormsData({ ...FormsData, [name]: value });
    };
    const [IsAPIAction, setIsAPIAction] = useState(false);
    const [PasswordShow, setPasswordShow] = useState(false);
    const [MsgClass, setMsgClass] = useState('alert-danger');
    const [APIMessage, setMessage] = useState();

    async function ChangePassword() {
        try {
            setIsAPIAction(true);
            setMsgClass('alert-danger');
            const url = CustomerPasswordChangeURL;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
        
                    
                
            };
            axios.post(url, FormsData, config).then((response) => {
                if (response.data.status === 1) {
                    setMsgClass('alert-success');
                    setFormsData({
                        password: '',
                        newpassword: '',
                        conpassword: '',
                    });
                }
                setMessage(response.data.message);
                setIsAPIAction(false);
                setTimeout(() => {
                    setMessage('');
                }, 2500);
            });
        } catch (err) {
            console.log(err);
            setIsAPIAction(false);
            setMessage('Something went wrong, Please try again later.');
        }
    }

    useEffect(() => {
        // console.log();
    }, []);

    return (
        <>
            <div className="pb-3" forpage={forpage}>
                <div className="row">
                    <div className="col-md-7">
                        <div className="ry_instr_shadow p-3">
                            <h5 className="mb-3 text-center">Change Password</h5>
                            <form
                                id="Inst-Regi-Form"
                                encType="multipart/form-data"
                                className="p-1"
                                style={{ display: 'block' }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    ChangePassword();
                                }}
                            >
                                <div className="form-group mb-2">
                                    <label className="ry_label">Current Login Password</label>
                                    <input
                                        required
                                        type="password"
                                        name="password"
                                        value={FormsData.password}
                                        onChange={(e) => {
                                            handleFormData(e.target.name, e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Your Current Login Password"
                                    />
                                </div>
                                <div className="form-group mb-2" style={{ position: 'relative' }}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '30px',
                                        }}
                                    >
                                        <i
                                            className="fa fa-eye"
                                            onClick={() => setPasswordShow(true)}
                                            style={{
                                                display: PasswordShow ? 'none' : '',
                                            }}
                                        ></i>
                                        <i
                                            className="fa fa-eye-slash"
                                            onClick={() => setPasswordShow(false)}
                                            style={{
                                                display: PasswordShow ? '' : 'none',
                                            }}
                                        ></i>
                                    </div>
                                    <label className="ry_label">New Login Password</label>
                                    <input
                                        required
                                        type={PasswordShow ? 'text' : 'password'}
                                        name="newpassword"
                                        value={FormsData.newpassword}
                                        onChange={(e) => {
                                            handleFormData(e.target.name, e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Your Current Login Password"
                                    />
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            background:
                                                FormsData.newpassword.length >= 6
                                                    ? '#7F13AB '
                                                    : 'grey',
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            marginRight: '5px',
                                        }}
                                    ></div>
                                    6+ Character
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            background: /[A-Z]/.test(FormsData.newpassword)
                                                ? '#7F13AB '
                                                : 'grey',
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            marginLeft: '5px',
                                            marginRight: '5px',
                                        }}
                                    ></div>
                                    1 Uppercase
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            background: /[0-9]/.test(FormsData.newpassword)
                                                ? '#7F13AB '
                                                : 'grey',
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            marginLeft: '5px',
                                            marginRight: '5px',
                                        }}
                                    ></div>
                                    1 Number
                                </div>
                                <div className="form-group mb-2">
                                    <label className="ry_label">Confirm Login Password</label>
                                    <input
                                        required
                                        type="password"
                                        name="conpassword"
                                        value={FormsData.conpassword}
                                        onChange={(e) => {
                                            handleFormData(e.target.name, e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Your Current Login Password"
                                    />
                                </div>

                                <div className={`message alert hide_empty_div mb-3 ${MsgClass}`}>
                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                </div>
                                <div className={`mt-3 text-center ${IsAPIAction ? '' : 'd-none'}`}>
                                    <img
                                        style={{ maxWidth: '32px' }}
                                        src={`${BASEURL}loader.gif`}
                                        alt=""
                                    />
                                </div>
                                <div className={`row mt-3 ${IsAPIAction ? 'd-none' : ''}`}>
                                    <div className="col-md-6">
                                        <Link
                                            to="/user/page/profilesetting"
                                            className="btn d-block btn-outline-danger"
                                        >
                                            Cancel
                                        </Link>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit" className="btn btn-docsta-instructor">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePasswordPage;
