import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UpdateCommonData } from '../reduxFunction/commonSlice';
import { useDispatch, useSelector } from 'react-redux';

function Navigation() {
    const CommonData = useSelector((state) => state.commonData);
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch(UpdateCommonData({ActMainMenu: ''}));
        // countryName = localStorage.getItem('country_name');
    }, []);
    return (
        <>
            <ul
                style={{
                    maxWidth: '1024px',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                }}
            >
                <li
                    onClick={() => {
                        localStorage.setItem('NVRunning', '/');
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === '/' ? 'NavigationActive' : ''}
                        to="/"
                    >
                        <i className="fa fa-home"></i>
                    </Link>
                </li>
                
                <li
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'aboutUs'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'aboutUs' ? 'NavigationActive' : ''}
                        to="/about-us"
                    >
                        About Us
                    </Link>
                </li>
                <li
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'cars'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'cars' ? 'NavigationActive' : ''}
                        to="/cars"
                    >
                        Rent a Car
                    </Link>
                </li>
                <li
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'userDashboard'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'userDashboard' ? 'NavigationActive' : ''}
                        to="/user/dashboard"
                    >
                        My Dashboard
                    </Link>
                </li>
                <li
                    className=""
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: ''}));
                    }}
                >
                    <b>
                        More <i className="fal fa-angle-down" />
                    </b>
                    <ul className="sub-menu">
                        {/* <li><Link to="/gallery">Gallery</Link></li> */}
                        <li><Link to="/make-payment">Make Payment</Link></li>
                        <li><Link to="/information/termandconditions">Term & Conditions</Link></li>
                        <li><Link to="/information/privacy">Privacy Policy</Link></li>
                        <li><Link to="/information/RefundAndCancellation">Refund & Cancellation</Link></li>
                    </ul>
                </li>
                <li
                    onClick={() => {
                        dispatch(UpdateCommonData({ActMainMenu: 'contactUs'}));
                    }}
                >
                    <Link
                        className={CommonData.ActMainMenu === 'contactUs' ? 'NavigationActive' : ''}
                        to="/contact-us"
                    >
                        Contact Us
                    </Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
