import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
// import { , showdatetimein } from '../../../../config';
import { IMGBASEAPIURL, BaseRentalURL } from '../../../config';

function Faq() {
    const [FAQs, setFAQs] = useState([]);

    const FetchFaqs = async () => {
        try {
            const res = await fetch(`${BaseRentalURL}InfoApi/FetchInformationPage`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    pagename: 'FAQs',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (resJson.faqs && resJson.faqs.length > 0) {
                    setFAQs(resJson.faqs);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchFaqs();
    }, []);

    return (
        <>
            <section
                className="corporate-learning"
                data-overlay="6"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/contactus/about-us.jpg)`,
                    padding: '20px 10px',
                    backgroundPosition: 'center',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="headBlock">
                                <h3 className="mt-5 mb-0">FAQ's</h3>
                                <p className="mb-5 mt-2">Book, Pay and Drive</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faqBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {FAQs.map((singi, i) => (
                                <div
                                    className="contentBox"
                                    style={{ border: '1px solid #f5f5f5' }}
                                    key={singi.id}
                                >
                                    <div className="row m-0">
                                        <div className="col-md-1 m-0 hideonmobile">
                                            <span>{i + 1}</span>
                                        </div>
                                        <div className="col-md-11 m-0">
                                            <div className="m-0">
                                                <h5>
                                                    <b className="docsta_no hideondesktop">
                                                        {i + 1}
                                                    </b>
                                                    {singi.question}
                                                </h5>
                                                <p>{Parser(singi.answer)}</p>
                                            </div>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faq;
