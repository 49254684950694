import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UPLOADNODEURL, BASEURL, LogOutAll } from '../../config';
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../NavigationLearn';
import SearchFromTop from '../cars/searchFromTop';
import './HeaderInstitute.css';
import './rypopup.css';
import { UpdateCommonData } from '../../reduxFunction/commonSlice';
import { FetchCarMasterAPI } from '../../reduxFunction/CarMasterSlice';
import { useDispatch } from 'react-redux';

function Header({ action, type, pageName, HideBottomMenu }) {
    const logo = `${BASEURL}images/logo.png`
    // const { loginWithRedirect } = useAuth0();
    const dispatch = useDispatch();
    const [defaultProfilePicture] = useState('defaultProfilePicture.png');
    const [userData, Setuserdata] = useState({ name: '', photo: '' });
    const [UserType, setUserType] = useState(localStorage.getItem('usertype'));
    const [UserName, setUserName] = useState(localStorage.getItem('username'));

    useEffect(() => {
        dispatch(FetchCarMasterAPI({ status: 1 }));
        const temp = localStorage.getItem('userdata');
        try {
            if (temp) {
                const resJson = JSON.parse(temp);
                Setuserdata(resJson);
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
        StickyMenu();
        setUserType(localStorage.getItem('usertype'));
        setUserName(localStorage.getItem('username'));
    }, []);
    return (
        <>
            <span
                id="closeKeywordSearch"
                className="d-none"
                onClick={() => {
                    dispatch(UpdateCommonData({ showKeywordSearch: 'none' }));
                }}
            >
                Close Keyword Search
            </span>
            <div
                className={`${Number(HideBottomMenu) === 1 ? 'd-none' : 'd-block d-sm-none'}`}
                style={{
                    position: 'fixed',
                    bottom: '0px',
                    right: '0px',
                    left: '0px',
                    zIndex: 999,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        background: '#383838',
                        padding: '10px 0px',
                        justifyContent: 'space-around',
                        fontSize: '11px',
                    }}
                >
                    <Link to="/" className="text-center d-block text-white">
                        <b>
                            <i className="far fa-2x fa-home" style={{ fontSize: '1.5em' }}></i>
                            <br />
                            Home
                        </b>
                    </Link>
                    <Link to="/user/dashboard" className="text-center d-block text-white">
                        <b>
                            <i className="far fa-2x fa-books" style={{ fontSize: '1.5em' }}></i>
                            <br />
                            My Bookings
                        </b>
                    </Link>
                    <Link to="/cars" className="text-center d-block text-white">
                        <b>
                            <i className="far fa-2x fa-search" style={{ fontSize: '1.5em' }}></i>
                            <br />
                            Search
                        </b>
                    </Link>
                    <Link to="/user/page/profilesetting" className="text-center d-block text-white">
                        <b>
                            <i className="far fa-2x fa-user" style={{ fontSize: '1.5em' }}></i>
                            <br />
                            My Profile
                        </b>
                    </Link>
                </div>
            </div>
            <header className="appie-header-area appie-sticky">
                <div className="headSection">
                    <div
                        className={`container-fluid ${Number(localStorage.getItem('screenwidth')) > 767 ? 'p-0' : ''
                            }`}
                    >
                        <div className="header-nav-box">
                            <div className="row align-items-center">
                                <div className="d-lg-none d-block col-12">
                                    <div className="appie-logo-box text-center">
                                        <Link to="/">
                                            <img src={logo} alt="" />
                                        </Link>
                                        <Link
                                            to="/search-cars"
                                            className="toggle-btn ml-30 d-lg-none d-block"
                                            style={{ right: '40px' }}
                                        >
                                            <i className="fa fa-search" />
                                        </Link>
                                        <div
                                            onClick={(e) => action(e)}
                                            className="toggle-btn ml-30 canvas_open"
                                        >
                                            <i className="fa fa-bars" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 p-0 col-md-3 col-12">
                                    <div className="appie-search-box">
                                        <Link to="/">
                                            <img
                                                src={logo}
                                                alt=""
                                                style={{ maxWidth: '160px' }}
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-5 p-0 col-md-5 col-12">
                                    <div className="appie-search-box1">
                                        <div className="SearchAnything">
                                            <div className="input-box">
                                                <SearchFromTop />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="appie-btn-box">
                                        {type === 'instructor' ? (
                                            <>
                                                <Link to="/">
                                                    <span className="text-dark">Learner</span>
                                                </Link>
                                                <Link
                                                    className="login-btn"
                                                    to="/instructor-login"
                                                    style={{
                                                        lineHeight: 'initial',
                                                        padding: '5px 20px',
                                                        display:
                                                            UserType === 'instructor' ? '' : 'none',
                                                    }}
                                                >
                                                    My Account
                                                    <br />
                                                    <small>({UserName})</small>
                                                </Link>
                                                <Link
                                                    className="login-btn"
                                                    to="/instructor-login"
                                                    style={{
                                                        display:
                                                            UserType === 'instructor' ? 'none' : '',
                                                    }}
                                                >
                                                    Sign in
                                                </Link>
                                                <Link
                                                    className="signup-btn ml-1"
                                                    to="/instructor-register"
                                                    style={{
                                                        display:
                                                            UserType === 'instructor'
                                                                ? 'none'
                                                                : 'none',
                                                    }}
                                                >
                                                    Signup
                                                </Link>
                                            </>
                                        ) : (
                                            <>
                                                <Link
                                                    className="login-btn"
                                                    to="/user/login"
                                                    style={{
                                                        display:
                                                            UserType === 'student' ? 'none' : '',
                                                    }}
                                                >
                                                    Sign in
                                                </Link>
                                                <Link
                                                    className="login-btn ml-1"
                                                    to="/user/dashboard"
                                                    style={{
                                                        display:
                                                            UserType === 'student' ? '' : 'none',
                                                    }}
                                                >
                                                    My Account
                                                </Link>
                                            </>
                                        )}
                                       
                                       
                                        <div className="appie-icon-box ml-1">
                                           
                                           
                                            <Link
                                                className="appie-icon-box d-none"
                                                to="/user/page/MyFavourite"
                                            >
                                                <i className="fal fa-heart" />
                                            </Link>
                                           
                                            {type !== 'instructora' && UserType === 'student' ? (
                                                <div className="ProfileMenuCont">
                                                    <img
                                                        src={`${UPLOADNODEURL}${userData.user_photo &&
                                                            userData.user_photo !== null &&
                                                            userData.user_photo !== ''
                                                            ? userData.user_photo
                                                            : defaultProfilePicture
                                                            }`}
                                                        alt={userData.name}
                                                        className=""
                                                    />
                                                    <div className="ProfileMenu">
                                                        <Link to="/user/page/profilesetting">
                                                            <h6>{userData.name}</h6>
                                                            {userData.email}
                                                        </Link>
                                                        <hr />
                                                        <Link to="/user/dashboard">
                                                            My Booking
                                                        </Link>
                                                        <Link to="/user/page/MyFavourite">
                                                            My Transaction
                                                        </Link>
                                                        <Link to="/user/page/updateprofilesetting">
                                                            Account Setting
                                                        </Link>
                                                        <Link to="/user/page/changePassword">
                                                            Change Password
                                                        </Link>
                                                        <Link
                                                            to="/user/dashboard"
                                                            onClick={() => {
                                                                LogOutAll();
                                                            }}
                                                        >
                                                            Logout
                                                        </Link>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <span className="mr-3"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="navSection navSection-sjain"
                    style={{ display: pageName === 'courseDetail' ? 'none' : '' }}
                >
                    <div className="">
                        <div className="header-nav-box">
                            <div className="align-items-center">
                                <div className="">
                                    <div className="appie-header-main-menu">
                                        <Navigation />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
