import React, { useState, useEffect, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { BASEAPIURL, BASEURL, CleanUrlWork, UPLOADNODEURL } from '../../../config';
import './blog.css';

const Sharethis = React.lazy(() => import('../../Helper/Sharethis'));
// eslint-disable-next-line no-underscore-dangle

// const HighlyPopular = React.lazy(() => import('../HighlyPopularCourses'));
const HighlyPopular = React.lazy(() => import('../PopularCoursesBox'));
const BlogPreview = React.lazy(() => import('./BlogPreview'));

function showdatetimein(datetime) {
    const tmp = new Date(datetime);
    return `${tmp.getDate()}/${tmp.getMonth()}/${tmp.getUTCFullYear()}`;
}

function BlogMain() {
    // for share this
    const [ShareThisURL, setShareThisURL] = useState(BASEURL);
    const [showShare, setshowShare] = useState(false);
    function closeshowShare() {
        setshowShare(false);
    }
    // for share this

    const [TopBlogs, setTopBlogs] = useState([]);
    const [FeaturedBlogs, setFeaturedBlogs] = useState([]);
    const FetchTopBlogs = async (forHere, orderBy, Limited, keyword) => {
        // Keyword SortBy
        try {
            const res = await fetch(`${BASEAPIURL}/blog/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    keyword,
                    orderBy,
                    page: 1,
                    limit: Limited,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (forHere === 'featured') {
                    setFeaturedBlogs(resJson.data);
                } else {
                    setTopBlogs(resJson.data);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };

    useEffect(() => {
        FetchTopBlogs('featured', 'featured', 9, '');
        FetchTopBlogs('trending', 'trending', 6, '');
    }, []);

    return (
        <>
            {showShare && (
                <div>
                    <div className="mfp-bg mfp-ready"></div>
                    <div
                        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                        tabIndex="-1"
                        style={{ overflow: ' hidden auto' }}
                    >
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content" style={{ maxWidth: 'max-content' }}>
                                <div className="mfp-iframe-scaler-old">
                                    <button
                                        onClick={closeshowShare}
                                        title="Close (Esc)"
                                        type="button"
                                        className="mfp-close"
                                    >
                                        ×
                                    </button>
                                </div>
                                <div
                                    className="mfp-iframe p-4 text-center"
                                    style={{ background: '#fff' }}
                                >
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Sharethis ShareThisURL={ShareThisURL} />
                                    </Suspense>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </div>
            )}
            <div
                className="text-center pt-4 pb-4"
                style={{
                    background: '#d6e6ff',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <h2>Blog</h2>
                                <p className="mt-2">
                                    Awaken your mind with our most trending blogs
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="mt-5 blogpage-section1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                {TopBlogs.map((singi) => (
                                    <div key={singi.inc_id} className="col-md-6">
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <BlogPreview
                                                BlogsDetails={singi}
                                                setShareThisURL={setShareThisURL}
                                                setshowShare={setshowShare}
                                            />
                                        </Suspense>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-sidebar">
                                <aside className="widget widget-search">
                                    <div className="search-form">
                                        <input
                                            type="search"
                                            name="s"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                FetchTopBlogs('featured', 'featured', 5, e.target.value);
                                            }}
                                        />
                                        <button type="submit">
                                            <i className="fal fa-search"></i>
                                        </button>
                                    </div>
                                </aside>
                                <aside className="widget widget-trend-post">
                                    <h3 className="widget-title">Featured</h3>
                                    {FeaturedBlogs.length === 0 ? (
                                        <div className="alert alert-info">
                                            No Blog found with your Search.
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {FeaturedBlogs.map((sitem) => (
                                        <Link to={`${BASEURL}blog/${sitem.inc_id}/${CleanUrlWork(sitem.tbl_blog_tittle)}`} className="popular-post">
                                            <img src={`${UPLOADNODEURL}${sitem.tbl_blog_img}`} alt="" />
                                            <div className="textBox">
                                                <h5>{sitem.tbl_blog_tittle}</h5>
                                                <span>{showdatetimein(sitem.updted_date)}</span>
                                            </div>
                                        </Link>
                                    ))}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Suspense fallback={<div>Loading...</div>}>
                <HighlyPopular />
            </Suspense>
            <div className="m-2"></div>
        </>
    );
}

export default BlogMain;
