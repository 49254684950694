import React, { Suspense, useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/DrawerInstitute';
import Header from '../Helper/HeaderInstitute';
import Footer from '../Helper/FooterInstitute';

const SearchFrom = React.lazy(() => import('../Institute/HomeSlider'));

function AllCourses() {
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        // const queryParameters2 = new URLSearchParams(window.location.search);
        // setGetCategory(queryParameters2.get('category'));
        // setGetSubCategory(queryParameters2.get('subcategory'));
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Suspense fallback={<div>Loading...</div>}>
                <SearchFrom className="pt-3" />
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default AllCourses;
