import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    IMGBASEAPIURL,
    // BASEAPIURL,
    BASEURL,
    ContactMobile,
    // ContactEmailID,
    ContactWhatsapp,
} from '../../config';
import './FooterInstitute.css';

const today = new Date();
function Footer({ className }) {
    const [CatiData] = useState(['Bhilai', 'Durg', 'Raipur', 'Bilashpur', 'Ambikapur']);

    // const FetchCatiData = async () => {
    //     try {
    //         const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({
    //                 SortStatus: 1,
    //                 parent: 0,
    //                 featured: 0,
    //                 recordsPerPage: 12,
    //                 sortby: 'a2z',
    //             }),
    //         });
    //         const resJson = await res.json();
    //         if (res.status === 200) {
    //             if (Object.keys(resJson.data).length > 0) {
    //                 setCatiData(resJson.data);
    //             }
    //         } else {
    //             console.log(resJson.message);
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    useEffect(() => {
        // FetchCatiData();
    }, []);

    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img
                                            src={`${BASEURL}images/logoWhite.png`}
                                            alt=""
                                            style={{ maxWidth: '220px', borderRadius: '6px' }}
                                        />
                                    </Link>
                                </div>
                                {/* <h4 className="title mt-4">Social Media</h4>
                                <div className="social">
                                    <ul>
                                        <li>
                                            <a
                                                className="you"
                                                href="https://www.youtube.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="fac"
                                                href="https://www.facebook.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="lin"
                                                href="https://www.linkedin.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="inst"
                                                href="https://www.instagram.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                                <h4 className="title mt-4 d-none">Download Mobile App</h4>
                                <div className="social d-none">
                                    <ul>
                                        <li>
                                            <a
                                                className="you"
                                                href="https://play.google.com"
                                                target="_blank"
                                                title="Download Android App"
                                                rel="noopener noreferrer"
                                                style={{ lineHeight: '28px' }}
                                            >
                                                <img
                                                    src="https://play.google.com/favicon.ico"
                                                    alt=""
                                                    style={{ width: '19px' }}
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="you"
                                                href="https://apps.apple.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ lineHeight: '28px' }}
                                            >
                                                <img
                                                    src="https://www.apple.com/favicon.ico"
                                                    alt=""
                                                    style={{ width: '30px' }}
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-6 col-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-6 footer-navigation">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/faq">FAQ's</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact-us">Contact Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-3 col-6 footer-navigation">
                                    <ul>
                                        <li><Link to="/make-payment">Make Payment</Link></li>
                                        <li><Link to="/information/termandconditions">Terms of Service</Link></li>
                                        <li><Link to="/information/privacy">Privacy Policy</Link></li>
                                        <li><Link to="/information/RefundAndCancellation">Refund and Cancellation</Link></li>
                                        <li><Link to="/Blogs">Blogs</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="footer-navigation">
                                        <h4 className="title mt-30">Browse by City</h4>
                                        <div className="row">
                                            {CatiData.map((cti, i) => (
                                                <div
                                                    className="col-6"
                                                    key={i}
                                                    style={{
                                                        aaa: '8px',
                                                    }}
                                                >
                                                    <ul>
                                                        <li>
                                                            <Link
                                                                to={`/cars?cityid=${i}`}
                                                                className="p-s2"
                                                            >
                                                                Book Car on {cti}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 
                        <div className="col-12 d-done">
                            <div className="hLine">
                                <hr />
                            </div>
                        </div> */}

                    </div>

                    <div className="col-md-12" style={{ borderTop: '1px solid #383838', marginTop: '30px', }}></div>

                    <div className="row">
                        <div className="col-md-3">
                            <div
                                className="footer-copyright mt-0 d-flex"
                                style={{
                                    justifyContent: 'left',
                                    borderTop: '0px solid #383838',
                                }}
                            >
                                <div className="copyright-text">
                                    <p>Book Pay Drive &copy; {today.getFullYear()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div
                                className="footer-copyright mt-0 d-flex"
                                style={{
                                    justifyContent: 'center',
                                    borderTop: '0px solid #383838',
                                }}
                            >
                                <div className="copyright-text">
                                    <Link to="/information/termandconditions">Terms of Service</Link>
                                    {/* <Link to="/information/privacy">Copyright Policy</Link> */}
                                    <Link to="/information/RefundAndCancellation">Refund and Cancellation</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="footer-copyright mt-0 d-flex"
                                style={{
                                    justifyContent: 'right',
                                    borderTop: '0px solid #383838',
                                }}
                            >
                                <div className="sjain-text">
                                    <p>
                                        Design & Developed by
                                        <a
                                            href="//sjain.io/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={`${BASEURL}images/sjain-logo.png`}
                                                alt="Sjain Ventures"
                                            />
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="d-none">
                <a
                    target="_WHATAPP"
                    href={`https://api.whatsapp.com/send?phone=${ContactWhatsapp}&text=Hello,%20Sending%20you%20message%20from%20https://docstainstitute.com/`}
                    className="whatsapp_us"
                >
                    <img src={`${IMGBASEAPIURL}whatsappus.png`} alt="" />
                </a>
            </div>
            <div className="d-none">
                <a href={`tel:${ContactMobile}`} className="call_us">
                    <img src={`${IMGBASEAPIURL}callus.png?v=0.1`} alt="" />
                </a>
            </div>
        </>
    );
}

export default Footer;
