import React, { Suspense, useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/DrawerInstitute';
import Header from '../Helper/HeaderInstitute';
import Footer from '../Helper/FooterInstitute';
import { BASEURL, UPLOADNODEURLCDN } from '../../config';


function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        // document.title = 'About Us';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Suspense fallback={<div className="text-center">Loading...</div>}>
                <>
                    <section
                        className="corporate-learning"
                        data-overlay="6"
                        style={{
                            // backgroundImage: `url(${IMGBASEAPIURL}institute/about/banner.jpeg)`,
                            background: `url(${UPLOADNODEURLCDN}rentalCars.jpg)`,
                            padding: '20px 10px',
                            backgroundPosition: 'cover',
                            backgroundSize: '100%',
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="headBlock">
                                        <h3 className="mt-5 mb-0">Make Payment</h3>
                                        <p className="mb-5">You can use any of the below option</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section
                        className="Book Pay DriveBlock  pb-4"
                        style={{
                            textAlign: 'justify',
                            background: '#f5f5f5',
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="docBox mt-2">
                                        <h2 className="mb-3 m3-5">Scan QR Code</h2>
                                        <img src={`${BASEURL}assets/qrCode.jpg`} alt="" style={{ maxWidth: '100%' }} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="docBox mt-2">
                                        <h2 className="mb-3 m3-5">Online Transfer</h2>
                                        <table className='table table-hover'>
                                            <tbody>
                                                <tr>
                                                    <td>Account No.</td>
                                                    <td><b>86890100009311</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Bank Name</td>
                                                    <td><b>BOB</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Account Holder</td>
                                                    <td><b>Suryakant Verma</b></td>
                                                </tr>
                                                <tr>
                                                    <td>IFSC Code</td>
                                                    <td><b>BARB0DBMOWA</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocInstitute;
