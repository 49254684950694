import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { BASEURL } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCarSearchData } from '../../reduxFunction/CarMasterSlice';
function CarSearchForm() {
    const [Show, SeShow] = useState(false);
    const [Message, SetMessage] = useState({ status: 0, value: '' });
    const CarMaster = useSelector((state) => state.CarMaster);
    const dispatch = useDispatch();

    const TodayDate = new Date().toISOString().split('T')[0]; // Get today's date in yyyy-mm-dd format
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 180); // Set max date to 90 days from today
    const maxDateString = maxDate.toISOString().split('T')[0];

    useEffect(() => {
        // FetchSliders();
        SeShow(false);
    }, []);

    function CheckSubmittedDates2() {
        const pickupDateTime = new Date(`${CarMaster.pickupDate}T${CarMaster.pickupTime}`);
        const dropDateTime = new Date(`${CarMaster.dropDate}T${CarMaster.dropTime}`);
        const timeDifference = dropDateTime - pickupDateTime;
        const hours = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours
        if (pickupDateTime >= dropDateTime) {
            SetMessage({ status: 0, value: "Please ensure that the drop-off date and time are scheduled after the pickup date and time. Thank you for your cooperation!" });
        } else if (hours && hours > 5) {
            SetMessage({ status: 1, value: `Searching available cars for Minimum of ${hours.toFixed(0)} Hours.` });
            window.location.href = `${BASEURL}cars`;
        } else {
            SetMessage({ status: 0, value: `The minimum booking duration for a car is 6 hours. Unfortunately, your requested booking is for only ${hours.toFixed(0)} hours. Please adjust the booking duration to meet the minimum requirement.` });
        }
    }



    return (
        <>
            <div className="SearchFormTopCont d-none d-sm-block">
                <form
                    // action={`${BASEURL}cars`}
                    className="row"
                    onSubmit={(e) => {
                        e.preventDefault();
                        SetMessage({ status: 0, value: '' });
                        CheckSubmittedDates2();
                    }}
                >
                    <div className="col-lg-12 SearchFormTopContOnly" style={{ display: Show ? 'none' : '' }}>
                        <div className="form-group">
                            <select
                                className='form-control'
                                required
                                name="branch"
                                value={CarMaster.branch}
                                onClick={() => {
                                    SeShow(true);
                                }}
                                onChange={(e) => {
                                    SetMessage({ status: 0, value: '' });
                                    dispatch(UpdateCarSearchData({ [e.target.name]: e.target.value }));
                                    localStorage.setItem(e.target.name, e.target.value);
                                }}
                            >
                                <option value="">Select Your Branch</option>
                                {CarMaster.branches && CarMaster.branches.map((s) => (
                                    <option value={s.branch_id} key={s.branch_id}>{s.branch_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="SearchFormTopContExtra p-4 col-md-12" style={{ display: Show ? 'block' : '' }}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="d-block text-left">Branch</label>
                                    <select
                                        className='form-control'
                                        required
                                        name="branch"
                                        value={CarMaster.branch}
                                        onClick={() => {
                                            SeShow(true);
                                        }}
                                        onChange={(e) => {
                                            SetMessage({ status: 0, value: '' });
                                            dispatch(UpdateCarSearchData({ [e.target.name]: e.target.value }));
                                            localStorage.setItem(e.target.name, e.target.value);
                                        }}
                                    >
                                        <option value="">Select Your Branch</option>
                                        {CarMaster.branches && CarMaster.branches.map((s) => (
                                            <option value={s.branch_id} key={s.branch_id}>{s.branch_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mt-2">
                                    <label className="d-block text-left">Pickup Date</label>
                                    <input
                                        required
                                        type="date"
                                        name="pickupDate"
                                        min={TodayDate}
                                        max={maxDateString}
                                        value={CarMaster.pickupDate >= TodayDate ? CarMaster.pickupDate : ''}
                                        className="form-control mt-1"
                                        placeholder="Pickup Date"
                                        onClick={() => {
                                            SeShow(true);
                                        }}
                                        onChange={(e) => {
                                            dispatch(UpdateCarSearchData({ [e.target.name]: e.target.value }));
                                            SetMessage({ status: 0, value: '' });
                                            localStorage.setItem(e.target.name, e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mt-2">
                                    <label className="d-block text-left">Pickup Time</label>
                                    <select
                                        required
                                        name="pickupTime"
                                        className="form-control betastoptime"
                                        value={CarMaster.pickupTime}
                                        onChange={(e) => {
                                            SetMessage({ status: 0, value: '' });
                                            dispatch(UpdateCarSearchData({ [e.target.name]: e.target.value }));
                                            localStorage.setItem(e.target.name, e.target.value);
                                        }}
                                    >
                                        <option value="">Pickup Time</option>
                                        <option value="">Select Your Pickup Time</option>
                                        <option value="04:30"> 04:30 AM </option>
                                        <option value="05:00"> 05 AM </option>
                                        <option value="05:30"> 05:30 AM </option>
                                        <option value="06:00"> 06 AM </option>
                                        <option value="06:30"> 06:30 AM </option>
                                        <option value="07:00"> 07 AM </option>
                                        <option value="07:30"> 07:30 AM </option>
                                        <option value="08:00"> 08 AM </option>
                                        <option value="08:30"> 08:30 AM </option>
                                        <option value="09:00"> 09 AM </option>
                                        <option value="09:30"> 09:30 AM </option>
                                        <option value="10:00"> 10 AM </option>
                                        <option value="10:30"> 10:30 AM </option>
                                        <option value="11:00"> 11 AM </option>
                                        <option value="11:30"> 11:30 AM </option>
                                        <option value="12:00"> 12 PM </option>
                                        <option value="12:30"> 12:30 PM </option>
                                        <option value="13:00"> 01 PM </option>
                                        <option value="13:30"> 01:30 PM </option>
                                        <option value="14:00"> 02 PM </option>
                                        <option value="14:30"> 02:30 PM </option>
                                        <option value="15:00"> 03 PM </option>
                                        <option value="15:30"> 03:30 PM </option>
                                        <option value="16:00"> 04 PM </option>
                                        <option value="16:30"> 04:30 PM </option>
                                        <option value="17:00"> 05 PM </option>
                                        <option value="17:30"> 05:30 PM </option>
                                        <option value="18:00"> 06 PM </option>
                                        <option value="18:30"> 06:30 PM </option>
                                        <option value="19:00"> 07 PM </option>
                                        <option value="19:30"> 07:30 PM </option>
                                        <option value="20:00"> 08 PM </option>
                                        <option value="20:30"> 08:30 PM </option>
                                        <option value="21:00"> 09 PM </option>
                                        <option value="21:30"> 09:30 PM </option>
                                        <option value="22:00"> 10 PM </option>
                                        <option value="22:30"> 10:30 PM </option>
                                        <option value="23:00"> 11 PM </option>
                                        <option value="23:30"> 11:30 PM </option>
                                    </select>
                                    {/* <input
                                        required
                                        type="time"
                                        name="pickupTime"
                                        value={CarMaster.pickupTime}
                                        className="form-control mt-1"
                                        placeholder="Pickup Time"
                                        onClick={() => {
                                            SeShow(true);
                                        }}
                                        onChange={(e) => {
                                            SetMessage({ status: 0, value: '' });
                                            dispatch(UpdateCarSearchData({ [e.target.name]: e.target.value }));
                                            localStorage.setItem(e.target.name, e.target.value);
                                        }}
                                    /> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mt-2">
                                    <label className="d-block text-left">Drop Date</label>
                                    <input
                                        className="form-control mt-1"
                                        required
                                        type="date"
                                        name="dropDate"
                                        min={CarMaster.pickupDate}
                                        value={CarMaster.dropDate >= TodayDate ? CarMaster.dropDate : ''}
                                        placeholder="Drop Date"
                                        onClick={() => {
                                            SeShow(true);
                                        }}
                                        onChange={(e) => {
                                            SetMessage({ status: 0, value: '' });
                                            dispatch(UpdateCarSearchData({ [e.target.name]: e.target.value }));
                                            localStorage.setItem(e.target.name, e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mt-2">
                                    <label className="d-block text-left">Drop Time</label>
                                    <select
                                        required
                                        name="dropTime"
                                        className="form-control betastoptime"
                                        value={CarMaster.dropTime}
                                        onChange={(e) => {
                                            SetMessage({ status: 0, value: '' });
                                            dispatch(UpdateCarSearchData({ [e.target.name]: e.target.value }));
                                            localStorage.setItem(e.target.name, e.target.value);
                                        }}
                                    >
                                        <option value="">Drop Time</option>
                                        <option value="">Select Your Pickup Time</option>
                                        <option value="04:30"> 04:30 AM </option>
                                        <option value="05:00"> 05 AM </option>
                                        <option value="05:30"> 05:30 AM </option>
                                        <option value="06:00"> 06 AM </option>
                                        <option value="06:30"> 06:30 AM </option>
                                        <option value="07:00"> 07 AM </option>
                                        <option value="07:30"> 07:30 AM </option>
                                        <option value="08:00"> 08 AM </option>
                                        <option value="08:30"> 08:30 AM </option>
                                        <option value="09:00"> 09 AM </option>
                                        <option value="09:30"> 09:30 AM </option>
                                        <option value="10:00"> 10 AM </option>
                                        <option value="10:30"> 10:30 AM </option>
                                        <option value="11:00"> 11 AM </option>
                                        <option value="11:30"> 11:30 AM </option>
                                        <option value="12:00"> 12 PM </option>
                                        <option value="12:30"> 12:30 PM </option>
                                        <option value="13:00"> 01 PM </option>
                                        <option value="13:30"> 01:30 PM </option>
                                        <option value="14:00"> 02 PM </option>
                                        <option value="14:30"> 02:30 PM </option>
                                        <option value="15:00"> 03 PM </option>
                                        <option value="15:30"> 03:30 PM </option>
                                        <option value="16:00"> 04 PM </option>
                                        <option value="16:30"> 04:30 PM </option>
                                        <option value="17:00"> 05 PM </option>
                                        <option value="17:30"> 05:30 PM </option>
                                        <option value="18:00"> 06 PM </option>
                                        <option value="18:30"> 06:30 PM </option>
                                        <option value="19:00"> 07 PM </option>
                                        <option value="19:30"> 07:30 PM </option>
                                        <option value="20:00"> 08 PM </option>
                                        <option value="20:30"> 08:30 PM </option>
                                        <option value="21:00"> 09 PM </option>
                                        <option value="21:30"> 09:30 PM </option>
                                        <option value="22:00"> 10 PM </option>
                                        <option value="22:30"> 10:30 PM </option>
                                        <option value="23:00"> 11 PM </option>
                                        <option value="23:30"> 11:30 PM </option>
                                    </select>
                                    {/* <input
                                        required
                                        type="time"
                                        className="form-control mt-1"
                                        name="dropTime"
                                        value={CarMaster.dropTime}
                                        placeholder="Drop Time"
                                        onClick={() => {
                                            SeShow(true);
                                        }}
                                        onChange={(e) => {
                                            SetMessage({ status: 0, value: '' });
                                            dispatch(UpdateCarSearchData({ [e.target.name]: e.target.value }));
                                            localStorage.setItem(e.target.name, e.target.value);
                                        }}
                                    /> */}
                                </div>
                            </div>
                            {Message && Message.value !== "" ? (
                                <div className="col-lg-12">
                                    <div className={Message.status === 1 ? 'alert alert-success' : 'alert alert-warning'}>
                                        {Message.value}
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="col-lg-12 text-center">
                                <input type="submit" name="submit" value="Search Cars" className="mt-3 btn btn-lg btn-warning" />
                                &nbsp;&nbsp;&nbsp;
                                <input
                                    type="button"
                                    name="cancel"
                                    value="Cancel"
                                    className="mt-3 btn btn-lg btn-outline-danger"
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default CarSearchForm;
