import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/DrawerLearn';
import Header from '../Helper/HeaderLearn';
import Footer from '../Helper/FooterInstitute';
import HomeMain from './HomeMain';

function DocLearn() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HomeMain />
            <Footer />
            <BackToTop />
        </>
    );
}

export default DocLearn;
