import React, { useState } from 'react';
import { IMGBASEAPIURL, BASEAPIURL, getDefaultCountry, LoginRedirect } from '../../../../config';
import PhoneInput from 'react-phone-number-input';

function loginWithOTP({ setOTPLogin }) {
    // const history = useHistory();
    const [IsNewAccount, SetIsNewAccount] = useState(false);
    const [OTPSent, setOTPSent] = useState(false);
    const [isAction, setisAction] = useState(false);
    const [msgClass, setmsgClass] = useState('alert-danger');
    const [UserData, setUserData] = useState({ name: '', email: '', otp: '', mobile: ''});
    const [message, setMessage] = useState('');

    function ForPhoneInput(v) {
        setOTPSent(false);
        setUserData({ ...UserData, mobile: v });
    }

    const handleSubmitConfirm = async (e) => {
        e.preventDefault();
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        try {
            const res = await fetch(`${BASEAPIURL}/student/loginWithOTPConfirm`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(UserData),
            });
            const resJson = await res.json();
            setisAction(false);
            if (res.status === 200) {
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    console.log(resJson);
                    setmsgClass('alert-success');
                    localStorage.setItem('usertype', 'student');
                    localStorage.setItem('loggedToken', resJson.loggedToken ? resJson.loggedToken : 0);
                    localStorage.setItem('userdata', resJson.data ? JSON.stringify(resJson.data) : JSON.stringify({}) );
                    setTimeout(() => { LoginRedirect(); }, 300);
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        try {
            const res = await fetch(`${BASEAPIURL}/student/loginWithOTP`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    mobile: UserData.mobile,
                }),
            });
            const resJson = await res.json();
            setisAction(false);
            SetIsNewAccount(Number(resJson.IsNewAccount) === 1);
            if (res.status === 200) {
                if (resJson.data && resJson.data.length > 0) {
                    setUserData(resJson.data[0]);
                } else {
                    setUserData({name: '', email: '', otp: '', mobile: ''});
                }
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    setOTPSent(true);
                    setmsgClass('alert-success');
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    return (
        <>
            <>
                {/* <AppFirebase /> */}
                <div className="container pt-4 pb-4">
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="row">
                                    <div className="col-md-6 pt-4 d-none d-sm-block text-center">
                                        <img
                                            src={`${IMGBASEAPIURL}students/pages/registration.jpg`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ maxWidth: '250px' }}
                                            className="mt-4 mb-4"
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <form
                                            className="mt-4 ml-4 mr-4 p-0 p-4"
                                            onSubmit={(e) => {
                                                if (OTPSent) {
                                                    handleSubmitConfirm(e);
                                                } else {
                                                    handleSubmit(e);
                                                }
                                            }}
                                        >
                                            <h6 className="d-block mb-2 d-sm-none">Login</h6>
                                            <div
                                                className="mb-3 phoneNumberInpCont"
                                                style={{ position: 'relative' }}
                                            >
                                                <PhoneInput
                                                    defaultCountry={getDefaultCountry()}
                                                    required
                                                    international
                                                    value={UserData.mobile}
                                                    placeholder="Enter Mobile No."
                                                    onChange={ForPhoneInput}
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        left: '10px',
                                                        top: '14px',
                                                    }}
                                                >
                                                    <i className="fa fa-envelope"></i>
                                                </div>
                                            </div>

                                            {OTPSent ? (
                                                <>
                                                    <div className="form-group">
                                                        <input
                                                            type="number"
                                                            name="otp"
                                                            value={UserData.otp}
                                                            required
                                                            onChange={(e) => {
                                                                setUserData({ ...UserData, otp: e.target.value });
                                                            }}
                                                            placeholder="Enter OTP"
                                                            className="form-control form-control-lg"
                                                        />
                                                    </div>
                                                    <div className={`form-group ${IsNewAccount ? '' : 'd-none'}`}>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            value={UserData.name}
                                                            required
                                                            onChange={(e) => {
                                                                setUserData({ ...UserData, name: e.target.value });
                                                            }}
                                                            placeholder="Enter Your Name"
                                                            className="form-control form-control-lg"
                                                        />
                                                    </div>
                                                    
                                                    <div className={`form-group ${IsNewAccount ? '' : 'd-none'}`}>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            value={UserData.email}
                                                            required
                                                            onChange={(e) => {
                                                                setUserData({ ...UserData, email: e.target.value });
                                                            }}
                                                            placeholder="Enter Your Email"
                                                            className="form-control form-control-lg"
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                ""
                                            )}

                                            <div
                                                className={`${msgClass} alert message hide_empty_div mb-3`}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <div style={{ display: isAction ? 'none' : '' }}>
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-docsta-instructor"
                                                >
                                                    {OTPSent ? "Confirm OTP" : "Send OTP"}
                                                </button>
                                            </div>
                                            <div className="text-right mt-2">
                                                <anew
                                                    href="#"
                                                    className="text-dark"
                                                    onClick={() => {
                                                        setOTPLogin(false);                                                    }}
                                                >
                                                    <b>Back to Login Option</b>
                                                </anew>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
        </>
    );
}

export default loginWithOTP;
