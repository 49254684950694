import React from 'react';
// import ReactDOM from 'react-dom';

import { createRoot } from 'react-dom/client';

// import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
/* import './assets/css/bootstrap.min.css'; */
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom-animated.css';
import './assets/css/default.css';
import './assets/css/font-awesome.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/calendar.css';
import './assets/css/main.css';
import './assets/css/style.css';


import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store'

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
    <App />
</Provider>);
// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
