import React, { useEffect, useState, Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
// import Parser from 'html-react-parser';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterLearn';
import Drawer from '../../../Mobile/DrawerLearn';
import HeaderInstitute from '../../../Helper/HeaderInstitute';
import ProfileMenu from './ProfileMenu';
import Dashboard from './Dashboard';
import MyCourses from './MyCourses';
import ProfileSetting from './ProfileSetting';
import EmailNotificationSetting from './EmailNotificationSetting';
import ProfileSettingUpdate from './ProfileSettingUpdate';
import ChangePassword from './ChangePassword';
import MyBlog from './MyBlog';
import MyFavourite from './MyFavourite';
import MyTransaction from './MyTransaction';
import ShareAppsPage from './ShareApps';
import MyDocument from './MyDocument';
import InviteFriends from './inviteFriends';
import '../../student.css';
import LoginPage from '../Login';
import { UPLOADNODEURL, BASEAPIURL, BASEURL, CustomerLoginCheckURL } from '../../../../config';
// import { BuyThisCourseStd } from '../../functions';

const HighlyPopularCourses = React.lazy(() => import('../../../Learn/HighlyPopularCoursesSkills'));
// const HighlyPopularCoursesExam = React.lazy(() =>
//     import('../../../Learn/HighlyPopularCoursesExam')
// );

function InstructorDashboard() {
    // const navigate = useNavigate();
    const [defaultProfilePicture] = useState('defaultProfilePicture.png');
    const [isLoaded, setisLoaded] = useState(false);
    const [isLogin, setisLogin] = useState(false);
    const [Profile, setProfile] = useState({
        name: '',
        photo: defaultProfilePicture,
    });
    const { PageName } = useParams();
    const [drawer, drawerAction] = useToggle(false);

    // const [PurchasedCourse] = useState([]);
    const PurchasedCourseList = async (targetType) => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/PurchasedCourseList`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    targetType,
                    usertype: localStorage.getItem('usertype'),
                }),
            });
            const resJson = await res.json();
            if (resJson.data) {
                switch (targetType) {
                    case 'MainCourse':
                        // SetPurchasedCourse(resJson.data);
                        break;
                    default:
                        break;
                }
            }
        } catch (err) {
            console.log(err);
        }
    };
    // function showTotalDuration(AllData, value) {
    //     let send = 0;
    //     if (AllData && AllData.length > 0) {
    //         send = AllData.reduce((acc, current) => acc + current[value], 0);
    //     }
    //     return send;
    // }

    useEffect(() => {
        PurchasedCourseList('MainCourse');
        const FetchProfile = async () => {
            try {
                const res = await fetch(CustomerLoginCheckURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                    },
                    body: JSON.stringify({
                        loggedToken: localStorage.getItem('loggedToken'),
                        usertype: localStorage.getItem('usertype'),
                        device: localStorage.getItem('DeviceDetails'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    setisLoaded(true);
                    if (resJson.status === 1 && localStorage.getItem('usertype') === 'student') {
                        setisLogin(true);
                        setProfile(resJson.userdata ? resJson.userdata : {});
                    } else {
                        localStorage.removeItem('loggedToken');
                        const t = localStorage.getItem('usertype');
                        if (t && t === 'student') {
                            localStorage.setItem('usertype', '');
                        }
                        setisLogin(false);
                    }
                } else {
                    setisLoaded(true);
                    setisLogin(false);
                    console.log(resJson.message);
                }
            } catch (err) {
                setisLoaded(true);
                setisLogin(false);
                console.log(err);
            }
        };
        FetchProfile();
        setInterval(() => {
            FetchProfile();
        }, 3000);
        // const interval =  return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderInstitute
                action={drawerAction.toggle}
                type="student"
                HideBottomMenu={isLogin ? 0 : 1}
            />
            <div
                className="container text-center pt-4 pb-4"
                style={{ display: isLoaded ? 'none' : '' }}
            >
                <img
                    src={`${BASEURL}loader.gif`}
                    alt=""
                    className="pt-4 pb-4"
                    style={{ maxWidth: '75px' }}
                />
            </div>
            <div
                style={{
                    display: isLogin ? 'none' : '',
                    opacity: isLoaded ? 1 : 0,
                }}
            >
                <LoginPage setisLogin={setisLogin} />
            </div>
            <div
                style={{
                    display: isLogin ? '' : 'none',
                    opacity: isLoaded ? 1 : 0,
                }}
            >
                <div className="container pt-4 pb-4">
                    <div className="mb-4">
                        <h3>
                            <img
                                src={`${UPLOADNODEURL}${
                                    Profile.user_photo && Profile.user_photo !== null && Profile.user_photo !== ''
                                        ? Profile.user_photo
                                        : defaultProfilePicture
                                }`}
                                alt={Profile.name}
                                className="mx-3 d-none d-sm-block instr_profile_pics ry-pull-left"
                                style={{ float: 'left', marginRight: '20px' }}
                            />
                            <div style={{ display: 'inline-block' }} className="mr-3 pt-2">
                                {Profile.name}
                                <br />
                                <small className="text-dark" style={{ fontSize: '37%' }}>
                                    <>
                                        <Link to="/user/page/updateprofilesetting" className='fs-6'>
                                            <i className={`fa fa-${Number(Profile.email_verify) === 1 ? 'check' : 'times'}-circle ${Number(Profile.email_verify) === 1 ? 'bg-success' : 'bg-danger'} text-white mx-1 rounded-circle`} />
                                            {Profile.email ? Profile.email : 'N/A'}
                                        </Link>
                                        <Link to="/user/page/updateprofilesetting" className='fs-6'>
                                            <i className={`fa fa-${Number(Profile.otp_verification) === 1 ? 'check' : 'times'}-circle ${Number(Profile.otp_verification) === 1 ? 'bg-success' : 'bg-danger'} text-white mx-1 rounded-circle`} />
                                            {Profile.mobile ? Profile.mobile : 'N/A'}
                                        </Link>
                                        <Link to="/user/page/MyDocument" className='fs-6'>
                                         <i className={`fa fa-${Number(Profile.id_verification) === 1 ? 'check' : 'times'}-circle ${Number(Profile.id_verification) === 1 ? 'bg-success' : 'bg-danger'} text-white mx-1 rounded-circle`} /> Profile Documents
                                        </Link>

                                    </>
                                </small>
                            </div>
                            {/* <Link
                                to="/"
                                target="_BLANK"
                                className="btn btn-docsta-instructor d-none ry-pull-right"
                                style={{ width: 'auto', borderRadius: '10px' }}
                            >
                                Explore New Courses
                            </Link> */}
                            <div style={{ clear: 'both' }}></div>
                        </h3>
                    </div>
                        
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <ProfileMenu forpage={PageName} Profile={Profile} />
                        </div>

                        <div className="col-md-9">
                            {PageName === undefined ? <Dashboard forpage={PageName} /> : ''}
                            {PageName === 'index' ? <Dashboard forpage={PageName} /> : ''}
                            {PageName === 'myblog' ? <MyBlog forpage={PageName} /> : ''}
                            {PageName === 'mycourses' ? <MyCourses forpage={PageName} /> : ''}
                            {PageName === 'MyFavourite' ? <MyFavourite forpage={PageName} /> : ''}
                            {PageName === 'MyTransaction' ? <MyTransaction forpage={PageName} /> : ''}
                            {PageName === 'shareApps' ? <ShareAppsPage forpage={PageName} /> : ''}
                            {PageName === 'MyDocument' ? <MyDocument forpage={PageName} /> : ''}
                            {PageName === 'inviteFriends' ? <InviteFriends /> : ''}
                            {PageName === 'profilesetting' ? (
                                <ProfileSetting forpage={PageName} />
                            ) : (
                                ''
                            )}
                            {PageName === 'EmailNotificationSetting' ? (
                                <EmailNotificationSetting forpage={PageName} />
                            ) : (
                                ''
                            )}
                            {PageName === 'updateprofilesetting' ? (
                                <ProfileSettingUpdate forpage={PageName} />
                            ) : (
                                ''
                            )}
                            {PageName === 'changePassword' ? (
                                <ChangePassword forpage={PageName} />
                            ) : (
                                ''
                            )}
                        </div>

                        <div className="col-md-1"></div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    <div className="m-4"></div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <HighlyPopularCourses />
                    </Suspense>
                    <div className="m-4"></div>
                    <Suspense fallback={<div>Loading...</div>}>
                        {/* <HighlyPopularCoursesExam /> */}
                    </Suspense>
                </div>
            </div>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </div>
    );
}

export default InstructorDashboard;
