import React, { useState } from 'react';
import { Link, useParams, useNavigate} from 'react-router-dom';
// import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
// import Parser from 'html-react-parser';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterLearn';
import Drawer from '../../../Mobile/DrawerLearn';
// import Header from '../Helper/Header';
import HeaderInstructor from '../../../Helper/HeaderInstitute';
import { IMGBASEAPIURL, getDefaultCountry, CustomerSignupURL, BASEURL } from '../../../../config';

function UserRegister() {
   const navigate = useNavigate();
        const logToken = localStorage.getItem('loggedToken');
         console.log(logToken);
         if(logToken && logToken !== '0' ){
            navigate(-1)
         }
   
    const { regToken } = useParams();
    const [IsPasswordReset] = useState(regToken && regToken !== '' && regToken !== null);
    const [IsRegistred] = useState(false);
    // const [PasswordShow, setPasswordShow] = useState(false);
    // const [ConPasswordShow, setConPasswordShow] = useState(false);
    const [isAction, setisAction] = useState(false);
    const [StdData, setStdData] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
        reset_pass_url: `${BASEURL}user/change-password`,
    });
    // console.log(`regToken ${regToken}`);
    const HandleFormData = (name, value) => {
        setStdData({ ...StdData, [name]: value });
    };
    function setMobile(value) {
        setStdData({ ...StdData, mobile: value });
    }

    const [drawer, drawerAction] = useToggle(false);
    const [msgClass, setmsgClass] = useState('alert-danger');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        setMessage('Processing Request...');
        e.preventDefault();
        setisAction(true);
        setmsgClass('alert-danger');
        try {
            const res = await fetch(CustomerSignupURL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(StdData),
            });
            const resJson = await res.json();
            setisAction(false);
            if (res.status === 200) {
                setMessage(resJson.message);
                if (resJson.status === 1) {
                    setmsgClass('alert-success');
                    // setIsRegistred(true);
                    // setMessage('Signup successful. You can now log in.');
                    setTimeout(() => { 
                        navigate('/user/login');
                    }, 90000);
                }
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            console.log(err);
            setisAction(false);
        }
    };

    // const CreatePassword = async (e) => {
    //     setMessage('Processing Request...');
    //     e.preventDefault();
    //     setisAction(true);
    //     setmsgClass('alert-danger');
    //     try {
    //         const res = await fetch(`${BASEAPIURL}/student/CreatePassword`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 regToken,
    //             },
    //             body: JSON.stringify(StdData),
    //         });
    //         const resJson = await res.json();
    //         setisAction(false);
    //         if (res.status === 200) {
    //             setMessage(resJson.message);
    //             if (resJson.status === 1) {
    //                 setmsgClass('alert-success');
    //                 setIsRegistred(true);
    //                 setMessage('');
    //                 const RedirectAfterLogin = localStorage.getItem('RedirectAfterLogin');
    //                 if (
    //                     RedirectAfterLogin &&
    //                     RedirectAfterLogin !== null &&
    //                     RedirectAfterLogin !== ''
    //                 ) {
    //                     localStorage.removeItem('RedirectAfterLogin');
    //                     localStorage.removeItem('MsgBeforeLogin');
    //                     window.location.href = RedirectAfterLogin;
    //                 } else {
    //                     window.location.href = BASEURL;
    //                 }
    //             }
    //         } else {
    //             setMessage(resJson.message);
    //         }
    //     } catch (err) {
    //         console.log(err);
    //         setisAction(false);
    //     }
    // };

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderInstructor drawer={drawer} action={drawerAction.toggle} />
            <>
                <div className="container pt-4 pb-4">
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="row">
                                    <div className="col-md-6 d-none d-sm-block text-right">
                                        <img
                                            src={`${IMGBASEAPIURL}students/pages/registration.jpg`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ maxWidth: '360px', width: '100%' }}
                                            className="mt-4"
                                        />
                                    </div>
                                    <div
                                        className="col-md-6"
                                        style={{ display: IsPasswordReset ? '' : 'none' }}
                                    >
                                        <div
                                            className={Number(localStorage.getItem('screenwidth')) > 768 ? 'm-4 p-4' : 'p-4' }
                                            style={{ display: IsRegistred ? '' : 'none' }}
                                        >
                                            <h3 className="mt-4 text-center">Password Created.</h3>
                                            <p
                                                className="text-center mt-3 mb-3 p-3"
                                                style={{
                                                    border: '1 px solid #f5f5f5',
                                                    background: '#f9f9f9',
                                                    color: '#000',
                                                }}
                                            >
                                                You can now login now from clicking button below.
                                            </p>
                                            <Link
                                                to="/user/login"
                                                className="btn btn-docsta-instructor"
                                            >
                                                Login Now
                                            </Link>
                                        </div>
                                        {/* <form
                                            className={Number(localStorage.getItem('screenwidth')) > 768 ? 'm-4 p-4' : 'p-4' }
                                            onSubmit={CreatePassword}
                                            style={{ display: IsRegistred ? 'none' : '' }}
                                        >
                                            <h3>Create Your Password</h3>
                                            <small
                                                className="mb-3 d-block"
                                                style={{ color: '#7b7b7b' }}
                                            >
                                                Already Created the Password?&nbsp;
                                                <Link to="/user/login" style={{ color: '#7f13ab' }}>
                                                    Login Here
                                                </Link>
                                            </small>
                                            <div
                                                className="form-group"
                                                style={{ position: 'relative' }}
                                            >
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '9px',
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-eye"
                                                        onClick={() => setPasswordShow(true)}
                                                        style={{
                                                            display: PasswordShow ? 'none' : '',
                                                        }}
                                                    ></i>
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        onClick={() => setPasswordShow(false)}
                                                        style={{
                                                            display: PasswordShow ? '' : 'none',
                                                        }}
                                                    ></i>
                                                </div>
                                                <input
                                                    required
                                                    type={PasswordShow ? 'text' : 'password'}
                                                    name="password"
                                                    placeholder="Create Password"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        HandleFormData(
                                                            e.target.name,
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        background:
                                                            StdData.password.length >= 6
                                                                ? '#7F13AB '
                                                                : 'grey',
                                                        width: '10px',
                                                        height: '10px',
                                                        borderRadius: '50%',
                                                        marginRight: '5px',
                                                    }}
                                                ></div>
                                                6+ Character
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        background: /[A-Z]/.test(StdData.password)
                                                            ? '#7F13AB '
                                                            : 'grey',
                                                        width: '10px',
                                                        height: '10px',
                                                        borderRadius: '50%',
                                                        marginLeft: '5px',
                                                        marginRight: '5px',
                                                    }}
                                                ></div>
                                                1 Uppercase
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        background: /[0-9]/.test(StdData.password)
                                                            ? '#7F13AB '
                                                            : 'grey',
                                                        width: '10px',
                                                        height: '10px',
                                                        borderRadius: '50%',
                                                        marginLeft: '5px',
                                                        marginRight: '5px',
                                                    }}
                                                ></div>
                                                1 Number
                                            </div>
                                            <div
                                                className="form-group"
                                                style={{ position: 'relative' }}
                                            >
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '9px',
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-eye"
                                                        onClick={() => setConPasswordShow(true)}
                                                        style={{
                                                            display: ConPasswordShow ? 'none' : '',
                                                        }}
                                                    ></i>
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        onClick={() => setConPasswordShow(false)}
                                                        style={{
                                                            display: ConPasswordShow ? '' : 'none',
                                                        }}
                                                    ></i>
                                                </div>
                                                <input
                                                    required
                                                    type={ConPasswordShow ? 'text' : 'password'}
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        HandleFormData(
                                                            e.target.name,
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className={`message alert hide_empty_div mb-2 ${msgClass}`}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <div style={{ display: isAction ? 'none' : '' }}>
                                                <button
                                                    type="submit"
                                                    className="btn btn-docsta-instructor"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form> */}
                                    </div>
                                    <div
                                        className="col-md-6"
                                        style={{ display: IsPasswordReset ? 'none' : '' }}
                                    >
                                        <div
                                            className={Number(localStorage.getItem('screenwidth')) > 768 ? 'm-4 p-4' : 'p-4' }
                                            style={{ display: IsRegistred ? '' : 'none' }}
                                        >
                                            <h3 className="mt-4 text-center">
                                                You're almost there!
                                                <br />
                                                Please verify your Email.
                                            </h3>
                                            <p
                                                className="text-center mt-3 mb-3 p-3"
                                                style={{
                                                    border: '1 px solid #f5f5f5',
                                                    background: '#f9f9f9',
                                                    color: '#000',
                                                }}
                                            >
                                                To Confirm your email address, Please click the link
                                                in the email we sent you.
                                            </p>
                                        </div>
                                        <form
                                            className={Number(localStorage.getItem('screenwidth')) > 768 ? 'm-4 p-4' : 'p-4' }
                                            onSubmit={handleSubmit}
                                            style={{ display: IsRegistred ? 'none' : '' }}
                                        >
                                            <h3>Register</h3>
                                            <small
                                                className="mb-3 d-block"
                                                style={{ color: '#7b7b7b' }}
                                            >
                                                Already have an Account?&nbsp;
                                                <Link to="/user/login" style={{ color: '#7f13ab' }}>
                                                    Login Here
                                                </Link>
                                            </small>
                                            <div className="form-group">
                                                <label>
                                                    <b>Name</b>
                                                </label>
                                                <input
                                                    required
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter Your Name"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        HandleFormData(
                                                            e.target.name,
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    <b>Mobile Number</b>
                                                </label>
                                                <div className="phoneNumberInpCont">
                                                    <PhoneInput
                                                        defaultCountry={getDefaultCountry()}
                                                        required
                                                        international
                                                        value={StdData.mobile}
                                                        placeholder="Enter Mobile No."
                                                        onChange={setMobile}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    <b>Email ID</b>
                                                </label>
                                                <input
                                                    required
                                                    type="email"
                                                    name="email"
                                                    value={StdData.email}
                                                    placeholder="Enter Email ID"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        HandleFormData(
                                                            e.target.name,
                                                            e.target.value.toLowerCase()
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    <b>Password</b>
                                                </label>
                                                <input
                                                    required
                                                    type="password"
                                                    name="password"
                                                    value={StdData.password}
                                                    placeholder="Enter Password"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        HandleFormData(
                                                            e.target.name,
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>
                                                    <b>Confirm Password</b>
                                                </label>
                                                <input
                                                    required
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={StdData.confirmPassword}
                                                    placeholder="Enter Confirm Password"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        HandleFormData(
                                                            e.target.name,
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <p className="mb-1 d-flex">
                                                <input required type="checkbox" />
                                                <span>
                                                    &nbsp;
                                                    I Agree with&nbsp;
                                                    <Link to="/information/privacy" target="_BLANK">
                                                        Privacy Policy
                                                    </Link>
                                                    &nbsp;and&nbsp;
                                                    <Link
                                                        to="/information/termandconditions"
                                                        target="_BLANK"
                                                    >
                                                    T&C
                                                    </Link>
                                                    .
                                                </span>
                                            </p>
                                            <div
                                                className={`message alert hide_empty_div mb-3 ${msgClass}`}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <div style={{ display: isAction ? 'none' : '' }}>
                                                <button
                                                    type="submit"
                                                    className="btn btn-docsta-instructor"
                                                >
                                                    Send Confirmation &nbsp;
                                                    <i className="fa fa-paper-plane"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default UserRegister;
