import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
 
    const logToken = localStorage.getItem('loggedToken');

    // console.log('log',logToken);

     if(logToken && logToken !== 0 && logToken !== '0'){
        return <Outlet />
      } else {
        return <Navigate to={'/user/login'} />
        } 

  
}

export default PrivateRoute