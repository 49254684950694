import React, { useState, useEffect } from 'react';
import { BASEAPIURL, BASEURL, UPLOADNODEURLCDN } from '../../../../config';
 
function MyDealsPage() {
    const [isLoded, SetisLoded] = useState(false);
    const [Data, SetData] = useState({});
    const [Upload, SetUpload] = useState({ licenceproof: false, userphoto: false, idprooffront: false, idproofback: false });
    

    const uploadImage = async (e, forFile) => {
        e.preventDefault();
        const file = e.target.files[0];
        const formData = new FormData()
        formData.append('file', file);
        SetUpload({...Upload, [e.target.name]: true});

        formData.append('loggedToken', localStorage.getItem('loggedToken'));
        formData.append('device', localStorage.getItem('DeviceDetails'));
        formData.append('type', e.target.name);
        formData.append('forFile', forFile);
        
        try {
            const requestOptions = { method: 'POST', body: formData, redirect: 'follow' };
            const res = await fetch(`${BASEAPIURL}/CustomerRJ/UploadProfileDocuments`, requestOptions);
            const resJson = await res.json();
            if (res.status === 200 && resJson.status === 1) {
                // image uploaded 
            } else {
                alert(resJson.message)
            }
            SetUpload({...Upload, [e.target.name]: false});
        } catch (err) {
            console.log(err)
        }
    }
    
    const FetchProfileDocuments = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/CustomerRJ/FetchProfileDocuments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    loggedToken: localStorage.getItem('loggedToken'),
                    device: localStorage.getItem('DeviceDetails'),
                }),
            });
            const resJson = await res.json();
            if (resJson && resJson.data) {
                const temp = resJson.data;
                if (resJson.data && resJson.data.user_photo && resJson.data.user_photo !== '') {
                    temp.user_photo = `${UPLOADNODEURLCDN}${resJson.data.user_photo}`;
                }
                if (resJson.data && resJson.data.licence_img && resJson.data.licence_img !== '') {
                    temp.licence_img = `${UPLOADNODEURLCDN}${resJson.data.licence_img}`;
                }
                if (resJson.data && resJson.data.id_proof_img && resJson.data.id_proof_img !== '') {
                    temp.id_proof_img = `${UPLOADNODEURLCDN}${resJson.data.id_proof_img}`;
                }
                if (resJson.data && resJson.data.id_proof_back_img && resJson.data.id_proof_back_img !== '') {
                    temp.id_proof_back_img = `${UPLOADNODEURLCDN}${resJson.data.id_proof_back_img}`;
                }
                SetData(temp);
            }
            SetisLoded(true);            
        } catch (err) {
            console.error(err);
            SetisLoded(true);
        }
    };

    useEffect(() => {
        FetchProfileDocuments();
    }, []);

    return (
        <>
            <div className="ry_instr_shadow p-4 my-4" style={{ display: isLoded ? '' : 'none' }}>
                <div className='row'>
                    <div className='col-md-6 col-12'>
                        <h5 className="py-2">Licence Proof</h5>
                        <input
                            type='file'
                            name='licenceproof'
                            className='form-control my-2'
                            id="LicenceProofUploadInp"
                            accept="image/*"
                            onChange={(e) => {
                                SetData({...Data, licence_img: URL.createObjectURL(e.target.files[0])});
                                uploadImage(e, 'licence_img');
                            }}
                        />
                        <img
                            src={Data && Data.licence_img && Data.licence_img !== '' ? Data.licence_img : `${BASEURL}images/documents/drivingLicense.jpg`}
                            alt='' 
                            style={{ maxHeight: '180px', objectFit: 'contain' }}
                            onClick={() => {
                                document.getElementById('LicenceProofUploadInp').click();
                            }}
                        />
                        {Upload.licenceproof ? (
                            <div className='text-left'>
                                <img src={`${BASEURL}loader.gif`} style={{ maxWidth: '32px' }} alt="" />
                                <br />
                                Uploading Licence Proof...
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className='col-md-6 col-12'>
                        <h5 className="py-2">Your Photo</h5>
                        <input
                            type='file'
                            name='userphoto'
                            className='form-control my-2'
                            id="YourPhotoUploadInp"
                            accept="image/*"
                            onChange={(e) => {
                                SetData({...Data, user_photo: URL.createObjectURL(e.target.files[0])});
                                uploadImage(e, 'user_photo');
                            }}
                        />
                        <img
                            src={Data && Data.user_photo && Data.user_photo !== '' ? Data.user_photo : `${BASEURL}images/documents/userPhoto.png`}
                            alt='' 
                            style={{ maxHeight: '180px', objectFit: 'contain' }}
                            onClick={() => {
                                document.getElementById('YourPhotoUploadInp').click();
                            }}
                        />
                        {Upload.userphoto ? (
                            <div className='text-left'>
                                <img src={`${BASEURL}loader.gif`} style={{ maxWidth: '32px' }} alt="" />
                                <br />
                                Uploading Your Photo...
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>


            <div className="ry_instr_shadow p-4 my-4" style={{ display: isLoded ? '' : 'none' }}>
                <div className='row'>
                    <div className='col-md-6 col-12'>
                        <h5 className="py-2">ID Proof front view</h5>
                        <input
                            type='file'
                            name='idprooffront'
                            className='form-control my-2'
                            id="IDProoffrontUploadInp"
                            accept="image/*"
                            onChange={(e) => {
                                SetData({...Data, id_proof_img: URL.createObjectURL(e.target.files[0])});
                                uploadImage(e, 'id_proof_img');
                            }}
                        />
                        <img
                            src={Data && Data.id_proof_img && Data.id_proof_img !== '' ? Data.id_proof_img : `${BASEURL}images/documents/adharFront.png`}
                            alt='' 
                            style={{ maxHeight: '180px', objectFit: 'contain' }}
                            onClick={() => {
                                document.getElementById('IDProoffrontUploadInp').click();
                            }}
                        />
                        {Upload.idprooffront ? (
                            <div className='text-left'>
                                <img src={`${BASEURL}loader.gif`} style={{ maxWidth: '32px' }} alt="" />
                                <br />
                                Uploading ID Proof front view...
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className='col-md-6 col-12'>
                        <h5 className="py-2">ID Proof back view</h5>
                        <input
                            type='file'
                            name='idproofback'
                            className='form-control my-2'
                            id="IDProofbackUploadInp"
                            accept="image/*"
                            onChange={(e) => {
                                SetData({...Data, id_proof_back_img: URL.createObjectURL(e.target.files[0])});
                                uploadImage(e, 'id_proof_back_img');
                            }}
                        />
                        <img
                            src={Data && Data.id_proof_back_img && Data.id_proof_back_img !== '' ? Data.id_proof_back_img : `${BASEURL}images/documents/adharBack.jpg`}
                            alt='' 
                            style={{ maxHeight: '180px', objectFit: 'contain' }}
                            onClick={() => {
                                document.getElementById('IDProofbackUploadInp').click();
                            }}
                        />
                        {Upload.idproofback ? (
                            <div className='text-left'>
                                <img src={`${BASEURL}loader.gif`} style={{ maxWidth: '32px' }} alt="" />
                                <br />
                                Uploading ID Proof back view...
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyDealsPage;
