import React, { useState } from 'react';
import Parser from 'html-react-parser';
import { Rating } from 'react-simple-star-rating';
import { IMGBASEAPIURL, BASEAPIURL } from '../../../config';

function ReviewCourse({ TheCourseID, setshowReviewForm, userdata, FetchCoursesReview }) {
    const [ShowDescription, setShowDescription] = useState('none');
    const [rating, setRating] = useState(0);
    const [styleBTN, setstyleBTN] = useState({});
    const [styleMSG, setstyleMSG] = useState({ display: 'none' });
    const [Message, setMessage] = useState('');
    const handleRating = (rate) => {
        setRating(rate);
        setShowDescription('block');
        HandleForm('rating', rate);
    };
    const [ContactForm, setContactForm] = useState({
        studentName: userdata.name,
        TheCourseID,
        CourseType: 'MainCourse',
        rating,
        comment: '',
    });

    const HandleForm = (name, value) => {
        setContactForm({ ...ContactForm, [name]: value });
    };

    const AddCoursesReview = async (e) => {
        e.preventDefault();
        setstyleMSG({ display: 'block' });
        setstyleBTN({ display: 'none' });
        setMessage(
            Parser(
                `<img src="${IMGBASEAPIURL}loader.gif" style="max-width: 70px; margin: 0px auto; display: block;" />`
            )
        );
        try {
            const res = await fetch(`${BASEAPIURL}/student/AddCoursesReview`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify(ContactForm),
            });
            // setstyleBTN({ display: 'none' });
            const resJson = await res.json();
            // alert(res.status);
            if (resJson.status === 1) {
                setContactForm({
                    studentName: userdata.name,
                    TheCourseID,
                    rating,
                    comment: '',
                });
                FetchCoursesReview();
                setTimeout(() => {
                    setshowReviewForm(false);
                    setRating('');
                }, 2786);
                setMessage(
                    Parser(`<div class="alert alert-success mb-0 mt-3">${resJson.message}</div>`)
                );
            } else {
                setMessage(
                    Parser(`<div class="alert alert-danger mb-0 mt-3">${resJson.message}</div>`)
                );
            }
            // setstyleBTN({ display: '' });
        } catch (err) {
            setstyleBTN({ display: '' });
            setMessage(Parser(`<div class="alert alert-danger mb-0 mt-3">${err}</div>`));
        }
    };

    return (
        <div>
            {console.log(ContactForm.TheCourseID)}
            {console.log(ContactForm.studentName)}
            <div className="mfp-bg mfp-ready"></div>
            <div
                className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                tabIndex="-1"
                style={{ overflow: ' hidden auto' }}
            >
                <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                    <div
                        className="mfp-content"
                        style={{
                            maxWidth: '380px',
                            minWidth: '300px',
                            background: '#fff',
                        }}
                    >
                        <div className="mfp-iframe-scaler-old">
                            <button
                                onClick={() => {
                                    setshowReviewForm(false);
                                }}
                                title="Close (Esc)"
                                type="button"
                                className="mfp-close"
                                style={{
                                    background: '#dedede',
                                    color: '#fff',
                                    fontWeight: 600,
                                    top: '10px',
                                    right: '10px',
                                    lineHeight: 'revert',
                                    textAlign: 'center',
                                    borderRadius: '50%',
                                    padding: '0px',
                                    width: '20px',
                                    height: '20px',
                                    fontSize: '18px',
                                }}
                            >
                                ×
                            </button>
                        </div>
                        <div className="mfp-iframe p-4 text-center">
                            <div className="mfp-iframe pt-50 text-center">
                                <b>Kindly Rate this Course</b>
                                <p className="mt-2" style={{ color: '#c7bdbd' }}>
                                    Select Rating
                                </p>
                                <form
                                    method="post"
                                    className="row"
                                    onSubmit={(e) => {
                                        AddCoursesReview(e);
                                    }}
                                >
                                    <div className="col-md-12" style={{ margin: '5px auto' }}>
                                        <Rating onClick={handleRating} allowFraction />
                                    </div>
                                    <div style={{ display: ShowDescription }}>
                                        <div className="col-md-12 mt-3">
                                            <label>Please leave a review for this course</label>
                                            <textarea
                                                className="form-control"
                                                name="comment"
                                                rows={3}
                                                style={{
                                                    width: '380px',
                                                    maxWidth: '300px',
                                                    margin: '5px auto',
                                                }}
                                                value={ContactForm.comment}
                                                placeholder="Tell us about your own personal experience taking this course. Was it a good match for you?"
                                                onChange={(e) => {
                                                    HandleForm(e.target.name, e.target.value);
                                                }}
                                            ></textarea>
                                        </div>
                                        <div className="col-md-12" style={{ clear: 'both' }}>
                                            <div style={styleMSG}>{Message}</div>
                                            <input
                                                style={styleBTN}
                                                type="submit"
                                                className="main-btn buy m-3"
                                                value="Submit"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="mfp-preloader">Loading...</div>
                </div>
            </div>
        </div>
    );
}

export default ReviewCourse;
