import React, { } from 'react';
import { Link } from 'react-router-dom';
import { } from '../../../config';
import { useSelector } from 'react-redux';

// Import Link from React Router if you are using it

function Thankyou() {

	const result = useSelector((state) => state.CarDetailSlice);

	return (
		<>
			<section>
				<div className=" d-flex justify-content-center align-items-center">
					<div className="col-md-4 my-5">
						<div className="border border-3 border-primary"></div>
						<div className="card  bg-white box-shadow p-5">
							<div className="mb-4 text-center">
								{result && result.status === 'failed' ? (
									<img src="/assets/images/danger.png" width="150px" height="150px" alt='success img' />
								) : result.status === 'success' ? (
									<img src="/assets/images/success.png" width="150px" height="150px" alt='success img' />
								) : result.status === 'danger' ? (
									<img src="/assets/images/success.png" width="150px" height="150px" alt='success img' />
								) : (
									<img src="/assets/images/wrong.png" width="150px" height="150px" alt='success img' />
								)}
							</div>
							<div className="text-center">
								<h4>Thank You for visiting here.</h4>
								<p>{result.message}</p>
								{result.doc_msg !== '' && result.status === 'success'? (
									<div>
										<p className='text-primary'>{result.doc_msg}</p>
										{localStorage.getItem('new_booking_id') && (
											<h3 className='text-warning mt-3 mb-3'>
												Your Booking ID is {localStorage.getItem('new_booking_id')}.
											</h3>
										)}
										<Link to="/user/page/MyDocument">
											<button className="btn btn-outline-primary my-2">Upload Documents</button>
										</Link>
										<span>&nbsp; &nbsp;</span>
										<Link to="/make-payment">
											<button className="btn btn-outline-success my-2">Make Payment</button>
										</Link>
									</div>
								):result.doc_msg === '' && result.status === 'success'?(
									<Link to="/user/dashboard">
									<button className="btn btn-outline-primary my-2">Check Detail</button>
								</Link>

								):''}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Thankyou;
